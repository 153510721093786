import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { changeTenantWabaStatus, getTenantWaba, upsertTenantWaba } from "../../store/actions/tenantAction";
import { Dropdown } from 'flowbite-react';
import RightSidePanel from '../../components/RightSidePanel';
import MapTenantRateCard from '../partner/MapTenantRateCard';

function ViewWABAAccount({ setIsShow, tenant }) {
    const dispatch = useDispatch();
    const [WABAAccounts, setWABAAccounts] = useState([]);
    const [waba, setWaba] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [viewMapRateCard, setViewMapRateCard] = useState(false);

    useEffect(() => {
        onSearch();
    }, [])

    const onSearch = () => {
        dispatch(getTenantWaba({
            tenantId: tenant.id
        }, (a) => {
            setWABAAccounts(a?.data);
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        waba.tenantId = tenant.id;
        dispatch(upsertTenantWaba(waba, (a) => {
            setWaba({ ...waba, wabaNumber: "", bspId: "", id: null, active: null });
            setIsEdit(false);
            onSearch();
        }))
    }
    const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
    return (
        <div>
            {/* <form className="mt-3 max-h-[550px] overflow-auto px-3" onSubmit={handleSubmit}>
                <div className="grid grid-cols-12 gap-3 w-full mb-1">
                    <div className="col-span-5">
                        <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>Mobile Number</label>
                        </div>
                        <input type="number" placeholder="Enter Mobile Number" value={waba?.wabaNumber} data-maxlength={10} onInput={OnInput}
                            onChange={(e) => setWaba({ ...waba, wabaNumber: e.target.value })} className="border border-gray-300 rounded-md py-2 w-[100%]" required />
                    </div>
                    <div className="col-span-5">
                        <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>BSP</label>
                        </div>
                        <input type="text" placeholder="Enter BSP" value={waba?.bspId}
                            onChange={(e) => setWaba({ ...waba, bspId: e.target.value })} className="border border-gray-300 rounded-md py-2 w-[100%]" required />
                    </div>
                    <div className="col-span-2 mt-6">
                        <button type='submit' className="min-w-[76px] text-center px-[16px] h-10 py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                            {isEdit ? "Update" : "Add"}
                        </button>
                    </div>
                </div>
            </form> */}
            <div className="flex mt-1">
                <button className="border-b-2 text-[14px] font-medium border-theme pb-1 pt-3">
                    WABA Accounts
                </button>
            </div>
            <hr />
            <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack">
                    <tr>
                        <th scope="col" className="pb-1 font-[500]">
                            <div className="flex gap-2">
                                <div>Waba Number</div>
                                <img src={`${process.env.PUBLIC_URL}/images/downsolid.svg`} />
                            </div>
                        </th>
                        <th scope="col" className="pb-1 font-[500]">
                            <div className="flex gap-2">
                                <div> BSP</div>
                                <img src={`${process.env.PUBLIC_URL}/images/downsolid.svg`} />
                            </div>
                        </th>
                        <th scope="col" className="pb-1 font-[500]">
                            <div className="flex gap-2">
                                <div> Status</div>
                                <img src={`${process.env.PUBLIC_URL}/images/downsolid.svg`} />
                            </div>
                        </th>
                        <th scope="col" className="pb-1 font-[500]">
                            <div className="flex gap-2">
                                <div>Action</div>
                                <img src={`${process.env.PUBLIC_URL}/images/downsolid.svg`} />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {WABAAccounts?.map((t, j) => {
                        return (<tr key={j} className="bg-white border-b">
                            <td className={"py-3 text-[13px] font-normal"}>
                                {t?.wabaNumber ?? "---"}
                            </td>
                            <td className={"py-3 text-[13px] font-normal"}>
                                {t?.bspId ?? "---"}
                            </td>
                            <td className={"py-3 text-[13px] font-normal"}>
                                {t?.active ? <label className='bg-[#D4F8D3] p-2 rounded-lg'>Active</label> : <label className='bg-[#F8F4D3] p-2 rounded-lg'>Deactivate</label>}
                            </td>
                            <td className=" py-3 font-normal text-[13px]">
                                <Dropdown inline arrowIcon={false} inputMode as="button" className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                    label={<i className="fa-solid fa-ellipsis"></i>}>
                                    {t?.active ? <Dropdown.Item onClick={() => { dispatch(changeTenantWabaStatus({ id: t.id, tenantId: tenant.id, isActive: false }, onSearch)) }}>
                                        Deactivate
                                    </Dropdown.Item> : <Dropdown.Item onClick={() => { dispatch(changeTenantWabaStatus({ id: t?.id, tenantId: tenant.id, isActive: true }, onSearch)) }}>
                                        Activate
                                    </Dropdown.Item>}
                                    {/* <Dropdown.Item onClick={() => { setIsEdit(true); setWaba(t) }}>
                                        Edit
                                    </Dropdown.Item> */}
                                    <Dropdown.Item onClick={() => { setWaba(t); setViewMapRateCard(true); }}>
                                        Map Rate Card
                                    </Dropdown.Item>
                                </Dropdown>
                            </td>
                        </tr>
                        );
                    })}
                </tbody>
            </table>
            <RightSidePanel
                CustomClass="fixed md:w-[60%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
                isShow={viewMapRateCard}
                setIsShow={setViewMapRateCard}
                ModalTitle="Map Rate Card"
                Component={
                    <MapTenantRateCard setIsShow={setViewMapRateCard} tenant={tenant} waba={waba} onAdd={() => { }} />
                }
            />
        </div>
    )
}

export default ViewWABAAccount