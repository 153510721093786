import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TenantSidebar } from "../../utils/TenantSidebar";
import Dashboard from "../../pages/tenant/Dashboard";
import Integration from "../../pages/tenant/Integration";
import Plan from "../../pages/tenant/Plan";
import { getInfo, getWaba, saveWaba } from "../../utils/types";
import { getTenantDetails, searchTenantWaba } from "../../store/actions/tenantAction";
import { FiZap, FiChevronDown, FiChevronUp } from "react-icons/fi";
import ROUTES from '../../utils/routesConstants';
import ManageUsers from '../../pages/tenant/ManageUsers';
import Contacts from '../../pages/tenant/Contacts';
import APIDocs from '../../pages/tenant/ApiDocs';
import Campaigns from '../../pages/tenant/Campaigns';
import LiveChat from '../../pages/tenant/LiveChat';
import ManageCategories from '../../pages/tenant/ManageCategories';
import SelectWaba from './SelectWaba';
import PopUp from '../../components/PopUp';
import Templates from '../../pages/tenant/Templates';
import Chatbot from '../../pages/tenant/Chatbot';
import Settings from '../../pages/tenant/Settings';
import Reports from '../../pages/tenant/Reports';
import AllConversations from '../../pages/tenant/AllConversations';
import AllMessages from '../../pages/tenant/AllMessages';

function TenantLeftPanel({ tabName, subtabName }) {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarComp, setSidebarComp] = useState(null);
  const [sidebarData, setSidebarData] = useState([]);
  const [tenantDetail, setTenantDetail] = useState();
  const [showSelectWaba, setShowSelectWaba] = useState(false);
  const tenantInfo = getInfo();
  const selectedWaba = getWaba();
  const componentMap = {
    "dashboard": { component: <Dashboard />, props: null, roles: ["TENANT_ADMIN"] },
    "plan-management": { component: <Plan />, props: null, roles: ["TENANT_ADMIN"] },
    "manage-users": { component: <ManageUsers />, props: null, roles: ["TENANT_ADMIN"] },
    "integrated": { component: <Integration />, props: null, roles: ["TENANT_ADMIN"] },
    "contacts": { component: <Contacts />, props: null, roles: ["TENANT_ADMIN"] },
    "api-doc": { component: <APIDocs />, props: null, roles: ["TENANT_ADMIN"] },
    "campaigns": { component: <Campaigns />, props: null, roles: ["TENANT_ADMIN"] },
    "live-chat": { component: <LiveChat />, props: null, roles: ["TENANT_ADMIN"] },
    "manage-categories": { component: <ManageCategories />, props: null, roles: ["TENANT_ADMIN"] },
    "templates": { component: <Templates />, props: null, roles: ["TENANT_ADMIN"] },
    "settings": { component: <Settings />, props: null, roles: ["TENANT_ADMIN"] },
    "chatbot": { component: <Chatbot />, props: null, roles: ["TENANT_ADMIN"] },
    "reports": { component: <Reports />, props: null, roles: ["TENANT_ADMIN"] },
    "allConversations": { component: <AllConversations />, props: null, roles: ["TENANT_ADMIN"] },
    "allMessages": { component: <AllMessages />, props: null, roles: ["TENANT_ADMIN"] },
  };
  
  useEffect(() => {
    setSidebarData(sidebarData);
  }, [TenantSidebar]);

  useEffect(() => {
    //If there is subTabName then use the subTab key
    let tab;
    if (subtabName) tab = componentMap[subtabName];
    else tab = componentMap[tabName];
    if (!tabName && !subtabName) {
      tab = componentMap["dashboard"];
    } else if (!tab || tab.roles?.filter(f => tenantInfo?.roleDetails?.some(s => s.name === f)).length == 0) {
      navigate(ROUTES.UN_AUTH);
    }
    setSidebarComp(tab);
    if (!selectedWaba) {
      dispatch(searchTenantWaba(
        {
          tenantId: tenantInfo.tenantId,
          criteria: { active: true },
          count: true,
          size: 1,
        },
        (d) => {
          if (Array.isArray(d?.data)) {
            saveWaba(d.data[0]);
          }
        }
      ));
    }
  }, [tabName, subtabName]);

  useEffect(() => {
    if (tenantInfo) {
      const finalSideBarData = TenantSidebar?.SidebarTitle.filter(
        (item) => tenantInfo?.roleDetails?.some(s => s.name === item.role)
      );
      setSidebarData(finalSideBarData ?? []);
      dispatch(getTenantDetails({ tenantId: tenantInfo.tenantId }, (d) => {
        setTenantDetail(d);
      }));
    }
  }, []);

  const openSubtab = (mainIndex, tabIndex) => {
    setSidebarData((prevSidebarData) => {
      const newSidebarData = [...prevSidebarData];
      const tab = newSidebarData[mainIndex].tabName[tabIndex];
      tab.active = !tab.active;
      return newSidebarData;
    });
  };

  const setOpenMainTab = (index) => {
    setSidebarData((prevSidebarData) => {
      const newSidebarData = [...prevSidebarData];
      const tab = newSidebarData[index];
      tab.active = !tab.active;
      return newSidebarData;
    });
  };

  return (
    <div className="flex overflow-hidden h-screen">
      <div className="fixed w-[18%] min-h-screen bg-[#F7F8F9] z-[999998] border-r-[1px] border-[#e9ebf0] overflow-y-auto overflow-x-hidden flex-grow">
        <div className="py-[8.3px] border-b border-[#e9ebf0]">
          <div className="flex px-4 gap-2 items-center">
            <img className="w-[37.5px] h-[37.5px] rounded-full p-1 box-border bg-white" src={tenantDetail?.logo ?? ""}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.PUBLIC_URL}/images/default.png`;
              }} />
            <div className={`text-[15px] font-medium md:w-[100%] text-black`}>
              {tenantDetail?.name}
              <p className="text-[10px] text-midBlack">
                {tenantInfo?.tenantId === "public" ? "" : tenantInfo?.tenantId}
              </p>
            </div>
            <div>
              <label className='cursor-pointer' onClick={() => setShowSelectWaba(true)}><i class="fa-solid fa-arrows-up-down text-gray-500 hover:text-theme"></i></label>
            </div>
            <div>
              <img className="w-[24.5px] h-[13px] ml-1 text-[13px]" src={`${process.env.PUBLIC_URL}/images/sidebar.png`} />
            </div>
          </div>
        </div>
        <div className="overflow-auto h-[670px] aside">
          {sidebarData.map((item, i) => {
            return (
              <>
                <div className={`flex justify-between rounded-md ${item?.active ? "" : ""}`}
                  onClick={() => setOpenMainTab(i)}
                  key={i}>
                  <div className={`text-[13px] font-medium py-2 ml-5 ${item?.active ? "text-theme" : "text-[#656f7d]"} `}>
                    {item?.value}
                  </div>
                </div>

                <ul className="flex flex-col space-y-1 pt-0 w-full pl-1 pr-2">
                  {item?.tabName.map((tab, j) => {
                    return (
                      <>
                        {tab?.subtab?.length > 0 ? (
                          <li className="w-full px-1">
                            <div
                              className={
                                "text-black h-[34px] px-2 justify-between relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] hover:text-theme border-l-4 border-transparent rounded pr-2 font-[400] cursor-pointer"
                              }
                              onClick={() => openSubtab(i, j)}
                            >
                              <div className="flex items-center">
                                <label className={"inline-flex justify-center items-center " + (tab?.subtab?.some(s => s.link === location.pathname) ? "text-theme" : "")}>
                                  {tab?.icon}
                                </label>
                                <label className={"text-[14px] " + (tab?.subtab?.some(s => s.link === location.pathname) ? "text-theme" : "")}>
                                  {tab?.title}
                                </label>
                              </div>
                              {tab?.active ? (
                                <FiChevronUp className='text-theme' />
                              ) : (
                                <FiChevronDown className='text-theme' />
                              )}
                            </div>
                          </li>
                        ) : (
                          <li className="w-full px-1">
                            <Link
                              to={tab?.link}
                              className={
                                (location.pathname === tab?.link
                                  ? "text-theme bg-[#FFFFFF]"
                                  : "text-black") +
                                " h-[34px] px-2 relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] border-l-4 border-transparent hover:text-theme rounded font-[400] whitespace-nowrap"
                              }
                            >
                              {tab?.icon}
                              <label className="text-[14px]">
                                {tab?.title}
                              </label>
                            </Link>
                          </li>
                        )}
                        {tab?.subtab?.length > 0 && tab?.active ? (
                          <ul className="top-[44px] w-full text-sm pl-2 pr-1">
                            {tab?.subtab?.map((subtab, i) => {
                              return (
                                <li>
                                  <Link
                                    to={subtab?.link}
                                    className={
                                      (location.pathname === subtab?.link
                                        ? "text-theme font-medium bg-[#FFFFFF]"
                                        : "text-black") +
                                      " h-[26px] my-1 px-4 text-wrap relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] border-l-4 border-transparent hover:text-theme rounded font-[400]"
                                    }
                                  >
                                    <label className="inline-flex justify-center items-center ml-3">
                                      {subtab?.icon ?? <FiZap className="mr-2" />}
                                    </label>
                                    <label className="ml-2 text-[13px] whitespace-nowrap">
                                      {subtab?.title}
                                    </label>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </ul>
              </>
            );
          })}
        </div>
      </div>
      <div className=" absolute right-0 w-[82%] mt-[7rem]">
        {sidebarComp &&
          React.cloneElement(sidebarComp?.component, sidebarComp?.props)}
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            Choose Waba
          </span>
        }
        isShowModal={showSelectWaba}
        setIsShowModal={setShowSelectWaba}
        ModalBody={
          <SelectWaba setIsShow={setShowSelectWaba} />
        }
        size="md"
      />
    </div>
  )
}

export default TenantLeftPanel