import React from 'react'
import { useState } from 'react';

function TestMessage({ setMobileNumbers }) {
    const [mobile, setMobile] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();
        setMobileNumbers(mobile?.split(","));
    }
    return (
        <form onSubmit={handleSubmit}>
            <label className='text-[14px] text-gray-500'>You have 20/20 test messages left.</label>
            <input type="text" placeholder='Enter WhatsApp numbar with country code e.g. +1-1476785712' required className="w-full bg-white text-gray-700 rounded-md border-gray-300 pr-10 rtl:pl-10 placeholder-gray-400 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                onChange={(e) => { setMobile(e.target.value) }} />
            <div className='flex justify-center'>
                <button type='submit' className="min-w-[76px] mt-2 text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                    Send
                </button>
            </div>
        </form>
    )
}

export default TestMessage