import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getLoginHistory } from '../../store/actions/tenantAction';
import { Pagination } from '@mui/material';
import moment from 'moment';

function LoginHistory({ setIsShow, info }) {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [history, setHistory] = useState([]);
    const itemParPage = 5;

    useEffect(() => {
        onSearch();
    }, [currentPage])

    const onSearch = () => {
        dispatch(getLoginHistory(
            {
                tenantId: info.tenantId,
                uid: info.uid,
                count: true,
                size: itemParPage,
                offset: (currentPage - 1) * itemParPage,
                orderBy: [{ property: "id", direction: "DESC" }]
            },
            (d) => {
                setHistory(d?.data ?? []);
                setTotalPages(Math.ceil(d?.count / itemParPage));
            }
        ));
    };

    return (<div>
        <div className="h-full">
            <div className="px-5 mt-2 bg-white">
                <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                    <thead className="text-[14px] text-midBlack bg-gray-100">
                        <tr>
                            <th scope="col" className="font-[500] py-3 px-2">
                                UID
                            </th>
                            <th scope="col" className="font-[500] py-3 px-2">
                                Login Date
                            </th>
                            <th scope="col" className="font-[500] py-3 pl-6">
                                Login IP
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {history?.map((p, i) => {
                            return (
                                <tr className="bg-white border-b">
                                    <td className={"py-3 text-[13px] font-normal"}>
                                        {p?.uid ?? "------"}
                                    </td>
                                    <td className="py-3 font-normal text-[13px]">
                                        {moment(p?.loginAt).format("YYYY-MM-DD hh:mm A")}
                                    </td>
                                    <td className="py-3 font-normal text-[13px]">
                                        {p?.loginIp}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {totalPages > 1 && (
                    <div className="flex overflow-x-auto sm:justify-center bottom-0 mb-[5rem], mt-2">
                        <Pagination
                            page={currentPage}
                            count={totalPages}
                            onChange={(event, page) => setCurrentPage(page)}
                            shape="rounded"
                            color="secondary"
                        />
                    </div>
                )}
            </div>
            <div className="flex justify-end gap-2 mt-4">
                <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer"
                    onClick={() => setIsShow(false)}>
                    Close
                </button>
            </div>
        </div>
    </div>)
}

export default LoginHistory