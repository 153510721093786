import { Dropdown } from 'flowbite-react';
import { Pagination } from "@mui/material";
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CreateBroadcast from '../../layouts/tenant/CreateBroadcast';
import RightSidePanel from '../../components/RightSidePanel';
import { campaignQuery, getWalletByWaba } from '../../store/actions/tenantAction';
import { getInfo, getWaba } from '../../utils/types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

function Campaigns() {
    const info = getInfo();
    const selectedWaba = getWaba();
    const dispatch = useDispatch();
    let location = useLocation();
    const [broadcasts, setBroadcasts] = useState([]);
    const [selectedBroadcast, setSelectedBroadcast] = useState({});
    const [wallet, setWallet] = useState({});
    const [showCreateBroadcast, setShowCreateBroadcast] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [filter, setFilter] = useState({ criteria: {} });
    const tenantReducer = useSelector((state) => state.tenantReducer);
    const itemParPage = 10;

    useEffect(() => {
        if (location?.state?.isCreate) {
            setShowCreateBroadcast(true);
        }
        dispatch(getWalletByWaba({ tenantWabaId: selectedWaba.id }, info.tenantId, w => {
            setWallet(w);
        }))
    }, []);

    useEffect(() => {
        onSearch();
    }, [currentPage]);

    const onSearch = () => {
        dispatch(campaignQuery(
            {
                ...filter,
                tenantId: info.tenantId,
                count: true,
                size: itemParPage,
                offset: (currentPage - 1) * itemParPage,
            },
            (d) => {
                setBroadcasts(d?.data)
                setTotalPages(Math.ceil(d?.count / itemParPage));
            }
        ));
    }

    return (<div>
        <div className="fixed top-0 w-[82%] bg-white z-30 ">
            <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-theme'>
                        <path d="M2.5 9.96647L17.5 5.7998V15.7998L2.5 12.4665V9.96647Z" stroke="#D84E55" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.66677 14.7996C9.57922 15.117 9.43002 15.414 9.22769 15.6737C9.02535 15.9334 8.77385 16.1507 8.48754 16.3132C8.20122 16.4757 7.8857 16.5802 7.559 16.6208C7.23229 16.6614 6.90079 16.6372 6.58343 16.5496C6.26607 16.4621 5.96906 16.3129 5.70936 16.1106C5.44966 15.9082 5.23236 15.6567 5.06985 15.3704C4.90735 15.0841 4.80283 14.7686 4.76227 14.4419C4.7217 14.1152 4.74588 13.7837 4.83343 13.4663"
                            stroke="#D84E55" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <div className="text-[14px] font-medium">Broadcast</div>
                </div>
            </div>
            <div className="bg-white text-black font-medium px-5 min-h-[55.4px] box-border border-b">
                <div className="flex justify-between py-2">
                    <div className='flex'>
                        <input type="text" placeholder="Enter Details" className="text-gray-500 py-[4px] px-[12px] text-[14px] h-9 font-normal border-gray-300 w-full min-w-[350px] rounded"
                            onChange={(e) => {
                                const fl = []
                                fl.push({ property: "campaignName", operator: "CONTAINS", value: e.target.value })
                                setFilter({ ...filter, filter: fl });
                            }} />
                        <button type="button" className="justify-center ml-3 items-center flex px-5 h-9 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                            onClick={onSearch}> Search
                        </button>
                    </div>
                    <div className='flex'>
                        <div className='text-[13px] text-end pr-2'>
                            <label><span className='font-bold pr-1'>{wallet?.currencyCode ?? tenantReducer.tenantDetails?.country?.currencyCode} {wallet?.availableWallet ?? 0}</span> credits available</label>
                            <br />
                            <label><span className='font-bold pr-1'>{wallet?.currencyCode ?? tenantReducer.tenantDetails?.country?.currencyCode} {wallet?.usedWallet ?? 0}</span> credits in use</label>
                        </div>
                        <div className='border-l border-1 border-gray-400' />
                        <button type="button" className="justify-center ml-3 items-center flex px-4 h-9 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                            onClick={() => { setSelectedBroadcast({}); setShowCreateBroadcast(true) }}>
                            <i className="fa-solid fa-plus mr-2"></i>
                            Create Broadcast5
                        </button>
                    </div>
                </div>
            </div>
        </div>
        {broadcasts && broadcasts.length > 0 ? (
            <div className="h-full">
                <div className="px-5 mt-2 bg-white">
                    <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                        <thead className="text-[14px] text-midBlack bg-gray-100">
                            <tr>
                                <th scope="col" className="font-[500] py-3 pl-2">
                                    <div>Broadcast Name</div>
                                </th>
                                <th scope="col" className="font-[500]">
                                    <div> Status</div>
                                </th>
                                <th scope="col" className="font-[500]">
                                    <div> Channel</div>
                                </th>
                                <th scope="col" className="font-[500]">
                                    <div>Broadcast time</div>
                                </th>
                                <th scope="col" className="font-[500]">
                                    <div>Schedule Type</div>
                                </th>
                                <th scope="col" className="font-[500]">
                                    <div>Delivered</div>
                                </th>
                                <th scope="col" className="font-[500]">
                                    <div>Broadcast Type</div>
                                </th>
                                <th scope="col" className="font-[500]">
                                    <div>Action</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {broadcasts.map((p, i) => {
                                return (
                                    <tr className="bg-white border-b">
                                        <td className={"py-3 text-[13px] font-normal"}>
                                            {p?.campaignName ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {p?.status ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {`${selectedWaba?.whatsappDisplayName} +${selectedWaba?.phoneCode} ${selectedWaba?.phone}`}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {moment(p?.scheduledTime).format("YYYY-MM-DD hh:mm A") ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {p?.scheduleType ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {p?.delivered ?? "100%"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {p?.broadcastType ?? "------"}
                                        </td>
                                        <td className=" py-3 font-normal text-[13px]">
                                            <Dropdown inline arrowIcon={false} inputMode as="button" className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                                label={<i className="fa-solid fa-ellipsis"></i>}>
                                                <Dropdown.Item className='hover:text-theme' onClick={() => { setSelectedBroadcast({ ...p, isEdit: true, confirmPassword: p.password }); setShowCreateBroadcast(true); }}>
                                                    <i className="fa-regular fa-pen-to-square pr-2"></i> Edit
                                                </Dropdown.Item>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {totalPages > 1 && (
                        <div className="flex overflow-x-auto sm:justify-center bottom-0 mb-[5rem], mt-2">
                            <Pagination page={currentPage} count={totalPages} onChange={(event, page) => setCurrentPage(page)} shape="rounded" color="secondary" />
                        </div>
                    )}
                </div>
            </div>
        ) : (
            <>
                <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/noList.png`}
                        alt="No List"
                        className="w-[486px] h-[278px] "
                    />
                    <p className="my-[24px] ">
                        The space is empty . create broadcast to get started.
                    </p>
                    <div className="flex gap-2.5 justify-center text-base font-medium">
                        <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                            <div className="flex gap-2" onClick={() => setShowCreateBroadcast(true)}>
                                <div className="">
                                    <span className="mr-2">
                                        <i className="fa-solid fa-plus"></i>
                                    </span>
                                    Create Broadcast
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
        <RightSidePanel
            CustomClass="fixed md:w-[70%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
            isShow={showCreateBroadcast}
            setIsShow={setShowCreateBroadcast}
            ModalTitle={"Create Broadcast"}
            Component={
                <CreateBroadcast setIsShow={setShowCreateBroadcast} onAdd={onSearch} />
            }
        />
    </div>)
}

export default Campaigns

