import React from 'react'
import WhatsAppMsgTemplates from '../../layouts/tenant/WhatsAppMsgTemplates'

function Templates() {
    return (
        <div>
            <div className="fixed top-0 w-[82%] bg-white z-30 ">
                <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                    <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                        <i className="fa-regular text-[14px] fa-solid fa-user-plus text-theme"></i>
                        <div className="text-[14px] font-medium">Manage Template</div>
                    </div>
                    <div className="flex gap-2">
                        {/* <div
                            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                            onClick={() => { }}>Add User
                        </div> */}
                    </div>
                </div>
                <div className="bg-white text-black font-medium px-5 min-h-[50.4px] box-border border-b flex justify-between items-end">
                    <div className="flex">
                        <button className="border-b-[2px] text-[16px] text-black px-1 pb-2 border-theme afterline">
                            Templates
                        </button>
                    </div>
                </div>
            </div>
            <div className='px-4'>
                <WhatsAppMsgTemplates isPopup={false} />
            </div>
        </div>
    )
}

export default Templates