import React, { useState } from 'react'

function EditWaba({ waba, setIsShow }) {
    const [selectedWaba, setSelectedWaba] = useState(waba ?? {});

    const onSubmit = (e) => {
        e.preventDefault();
        setIsShow(false);
    }

    return (<form onSubmit={onSubmit} >
        <div className='h-[73vh]'>
            <div className='mt-2 text-[14px]'>
                <label className='font-semibold'>Connect your WhatsApp Phone Number</label>
                <div className='w-[50%] rounded-md mt-3 text-[16px] p-2 bg-gray-100'>
                    +{waba?.phoneCode}{waba?.phone}
                </div>
            </div>
            <div className='mt-4 text-[14px]'>
                <label className='font-semibold'>WhatsApp Business Display Name</label>
                <div className='border border-gray-400 flex w-[50%] rounded-md mt-3'>
                    <div className='w-full'>
                        <input type="text" required placeholder="Enter Business Name" value={selectedWaba?.whatsappDisplayName}
                            onChange={(e) => { setSelectedWaba({ ...selectedWaba, whatsappDisplayName: e.target.value }) }} className="border-0 h-9 border-gray-300 rounded-md py-2 w-full" />
                    </div>
                </div>
            </div>
            <div className='mt-4 text-[14px]'>
                <label className='font-semibold'>Waba Id</label>
                <div className='flex bg-gray-100 w-[50%] rounded-md mt-3'>
                    <div className='w-full text-[16px] p-2'>
                        {selectedWaba?.wabaNumber}
                    </div>
                </div>
            </div>
        </div>
        <div className='mt-4 text-[14px] pb-4 bettom-0 mr-3'>
            <div className='flex justify-end'>
                <button type='button' className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                    onClick={() => setIsShow(false)}>
                    Close
                </button>
                <button type='submit' className="min-w-[76px] ml-4 text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                    Save
                </button>
            </div>
        </div>
    </form>)
}

export default EditWaba