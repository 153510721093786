import React, { useState } from 'react'
import FileUploader from '../../components/FileUploader';

function SetTemplateVariableValue({ setTemplate, template, carouselTemplate, setCarouselTemplate }) {
    return (
        <div>
            {template?.header?.format && template?.header?.format !== "" && <>
                {template?.header?.format === "TEXT" ? template?.header?.headerVariables?.length > 0 && <>
                    <label className='text-[14px]'>Header</label>
                    <div className='border border-gray-300'>
                        <div className='grid grid-cols-4 gap-2 p-2 text-center'>
                            <label className='text-[14px]'>Template Variables</label>
                            <label className='text-[14px] col-span-3'>Values</label>
                            {template?.header?.headerVariables?.map((h, i) => <>
                                <label className='text-[14px] py-2'>{`{{${i + 1}}}`}</label>
                                <input type="text" value={h?.text} required className="w-full col-span-3 bg-white text-gray-700 rounded-md border-gray-300 pr-10 rtl:pl-10 placeholder-gray-400 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                    onChange={(e) => {
                                        template.header.headerVariables[i].text = e.target.value;
                                        setTemplate({ ...template });
                                    }} />
                            </>)}
                        </div></div></> : <>
                    <label className='text-[14px]'>Header</label>
                    <div className='border border-gray-300'>
                        <FileUploader selectedFileUrl={template.header?.fileUrl} isFbUploder={true} type={template?.header?.format} filePrefix="template_media" onUploaded={(file) => { }} />
                    </div></>}
            </>}
            {template?.bodyVariables?.length > 0 && <>
                <label className='text-[14px]'>Body</label>
                <div className='border border-gray-300 grid grid-cols-4 gap-2 p-2 text-center'>
                    <label className='text-[14px]'>Template Variables</label>
                    <label className='text-[14px] col-span-3'>Values</label>
                    {template?.bodyVariables?.map((b, i) => <>
                        <label className='text-[14px] py-2'>{`{{${i + 1}}}`}</label>
                        <input type="text" value={b?.text} required className="w-full col-span-3 bg-white text-gray-700 rounded-md border-gray-300 pr-10 rtl:pl-10 placeholder-gray-400 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                            onChange={(e) => { template.bodyVariables[i].text = e.target.value; setTemplate({ ...template }); }} />
                    </>)}
                </div>
            </>}
            {template?.buttons?.length > 0 && template?.buttons?.some(f => f.type === "URL" && f.variableValues?.length > 0) && <>
                <label className='text-[14px]'>URL Buttons</label>
                <div className='border border-gray-300 grid grid-cols-3 gap-2 p-2 text-center'>
                    <label className='text-[14px] col-span-2'>URL</label>
                    <label className='text-[14px]'>Value</label>
                    {template?.buttons?.filter(f => f.type === "URL").map((b, i) => b.variableValues?.map((v, j) => <>
                        <label className='text-[14px] py-2 col-span-2'>{`${b.url}`}</label>
                        <input type="text" value={v} required className="w-full bg-white text-gray-700 rounded-md border-gray-300 pr-10 rtl:pl-10 placeholder-gray-400 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                            onChange={(e) => { b.variableValues = [e.target.value]; setTemplate({ ...template }); }} />
                    </>))}
                </div>
            </>}
            {carouselTemplate?.length > 0 && <div>
                {carouselTemplate.map((c, i) => <>
                    {carouselTemplate[i].format && <>
                        <label className='text-[15px] text-semibold'>Carousel {i + 1}</label>
                        <div>
                            <FileUploader selectedFileUrl={carouselTemplate[i].fileUrl} isFbUploder={true} type={carouselTemplate[i].format} filePrefix="template_media" onUploaded={(file) => { }} />
                        </div></>}
                    {c?.buttons?.some(f => f.type === "URL" && f.variableValues?.length > 0) && <>
                        <label className='text-[14px]'>URL Buttons</label>
                        <div className='border border-gray-300 grid grid-cols-3 gap-2 p-2 text-center'>
                            <label className='text-[14px] col-span-2'>URL</label>
                            <label className='text-[14px]'>Value</label>
                            {c?.buttons?.filter(f => f.type === "URL").map((b, i) => b.variableValues?.map((v, j) => <>
                                <label className='text-[14px] py-2 col-span-2'>{`${b.url}`}</label>
                                <input type="text" value={v} required className="w-full bg-white text-gray-700 rounded-md border-gray-300 pr-10 rtl:pl-10 placeholder-gray-400 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                    onChange={(e) => { b.variableValues = [e.target.value]; setCarouselTemplate([...carouselTemplate]); }} />
                            </>))}
                        </div>
                    </>}
                </>)}
            </div>}
        </div>
    )
}

export default SetTemplateVariableValue