import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { categoryQuery, getTenantContacts } from '../../store/actions/tenantAction';
import { getInfo } from '../../utils/types';
import { Pagination } from '@mui/material';

function ChooseContact({ setIsShow, onAdd }) {
    const info = getInfo();
    let dispatch = useDispatch();
    const [Contacts, setContacts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState({ criteria: { tenantId: info.tenantId, active: true } });
    const itemParPage = 5;

    useEffect(() => {
        ContactSearch();
    }, [currentPage])

    useEffect(() => {
        dispatch(categoryQuery(
            {
                tenantId: info.tenantId,
                criteria: { active: true },
                count: true,
                size: 100,
            },
            (d) => {
                setCategories(d?.data ?? []);
            }
        ));
    }, [])

    const ContactSearch = () => {
        dispatch(getTenantContacts(
            {
                ...filter,
                tenantId: info.tenantId,
                count: true,
                size: itemParPage,
                offset: (currentPage - 1) * itemParPage,
            },
            (d) => {
                setContacts(d?.data)
                setTotalPages(Math.ceil(d?.count / itemParPage));
            }
        ));
    };

    return (
        <div>
            <div className="grid grid-cols-5 gap-4">
                <div className={`flex flex-col col-span-2`}>
                    <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                        <label>Contact Details</label>
                        <i className="fa-solid fa-circle-info px-2 text-xs"></i>
                    </div>
                    <input type="text" placeholder="Enter Contact Details" className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                        onChange={(e) => {
                            const fl = []
                            if (!isNaN(e.target.value)) {
                                fl.push({ property: "phone", operator: "CONTAINS", value: e.target.value });
                            }
                            else if (e.target.value?.includes('@')) {
                                fl.push({ property: "email", operator: "CONTAINS", value: e.target.value });
                            }
                            else { fl.push({ property: "name", operator: "CONTAINS", value: e.target.value }) }
                            setFilter({ ...filter, filter: fl })
                        }} />
                </div>
                <div className={`flex flex-col col-span-2`}>
                    <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                        <label>Status</label>
                        <i className="fa-solid fa-circle-info px-2 text-xs"></i>
                    </div>
                    <select className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                        onChange={(e) => { e.target.value ? setFilter({ ...filter, criteria: { ...filter.criteria, category: [e.target.value] } }) : delete filter.criteria.category }}>
                        <option value="" selected>Select Category</option>
                        {categories.map((m, i) => <option key={i} value={m.categoryName}>{m.categoryName}</option>)}
                    </select>
                </div>
                <div className={`flex flex-col`}>
                    <button type="button" className="justify-center mt-7 items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                        onClick={ContactSearch}> Search
                    </button>
                </div>
            </div>
            {Contacts && Contacts.length > 0 &&
                <div className="h-full">
                    <div className="px-5 mt-2 bg-white">
                        <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                            <thead className="text-[14px] text-midBlack">
                                <tr>
                                    <th scope="col" className="pb-1 font-[500]">
                                        <div className="flex gap-2">
                                            <div>Name</div>
                                        </div>
                                    </th>
                                    <th scope="col" className="pb-1 font-[500]">
                                        <div className="flex gap-2">
                                            <div> Email</div>
                                        </div>
                                    </th>
                                    <th scope="col" className="pb-1 font-[500]">
                                        <div className="flex gap-2">
                                            <div> Phone</div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Contacts.map((p, i) => {
                                    return (
                                        <tr className="bg-white border-b">
                                            <td className={"py-3 text-[13px] font-normal"}>
                                                {p?.name ?? "------"}
                                            </td>
                                            <td className="py-3 font-normal text-[13px]">
                                                {p?.email ?? "------"}
                                            </td>
                                            <td className="py-3 font-normal text-[13px]">
                                                {`+${p?.phoneCode} ${p?.phone}`}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {totalPages > 1 && (
                            <div className="flex overflow-x-auto sm:justify-center bottom-0 mb-[5rem], mt-2">
                                <Pagination
                                    page={currentPage}
                                    count={totalPages}
                                    onChange={(event, page) => setCurrentPage(page)}
                                    shape="rounded"
                                    color="secondary"
                                />
                            </div>
                        )}
                    </div>
                </div>
            }
            <div className="flex justify-end gap-2 mt-4">
                <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer"
                    onClick={() => {
                        dispatch(getTenantContacts(
                            {
                                ...filter,
                                tenantId: info.tenantId,
                                count: true,
                                size: 100000
                            },
                            (d) => {
                                onAdd(d?.data);
                            }
                        ));
                        setIsShow(false);
                    }}>
                    Add
                </button>
            </div>
        </div>
    )
}

export default ChooseContact