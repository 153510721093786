import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from "../../utils/types";
import { embeddedSignup } from "../../store/actions/tenantAction";
import MobileInput from '../../components/MobileInput';
import moment from 'moment';

function WhatsAppIntegration({ setIsShow, onIntegrated }) {
    let dispatch = useDispatch();
    const [steps, setSteps] = useState(2);
    const [waba, setWaba] = useState({});
    const globalReducer = useSelector((state) => state.globalReducer);
    const tenantReducer = useSelector((state) => state.tenantReducer);

    const fbAsyncInit = function (appId) {
        window.FB.init({
            appId: appId,
            autoLogAppEvents: true,
            cookie: true,
            xfbml: true,
            version: 'v20.0',
            //redirect_uri: "https://localhost:3000/tenant/integrated"
        });
    };

    const ContinueWithFacebookClick = () => {
        if (!tenantReducer.tenantDetails?.bsp?.appId) {
            return;
        }
        //fbAsyncInit(tenantReducer.tenantDetails?.bsp?.appId);
        fbAsyncInit("398616886125412");
        window.FB.login(response => {
            if (response.authResponse) {
                const { accessToken, expiresIn, userID } = response.authResponse;
                let expires = moment();
                expires.add((parseInt(expiresIn) / 3600), 'hours');
                dispatch(embeddedSignup({
                    accessToken: accessToken, tokenExpires: expires.format("YYYY-MM-DDTHH:mm:ss"), metaUserId: userID,
                    tokenType: "bearer", bspId: tenantReducer.tenantDetails?.bsp?.id, tenantId: tenantReducer.tenantDetails?.id
                }, r => {
                    setIsShow(false);
                    onIntegrated(r);
                }))
            } else {
                // Handle errors or cancellations
            }
        }, {
		  config_id: '1414583992759497', // configuration ID obtained in the previous step goes here
		  response_type: 'code',     // must be set to 'code' for System User access token
		  override_default_response_type: true,
		  extras: {
					feature: 	"whatsapp_embedded_signup",		 
					// Display original flow to user
					version: 2,
					setup: {
						"solutionID": "996638862229708"				 
					}
				}
	});
    }

    const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
    return (
        <div className='max-h-[550px] overflow-auto'>
            <form className='pt-2 pr-4 pl-4' onSubmit={(e) => { e.preventDefault(); setSteps((steps + 1) > 2 ? 2 : steps + 1) }}>
                {/* <div className='text-[14px] text-gray-400'>Step {steps}/2</div> */}
                {/* <div className={steps === 1 ? "" : "hidden"}>
                    <div className='mt-2 text-[14px]'>
                        <label className='font-semibold'>Connect your WhatsApp Phone Number</label>
                        <div className='text-gray-500'>Based on Meta's guidelines, any business applying for a WhatsApp Business API must register with a phone number that is NOT linked with your existing WhatsApp Business account or any other personal WhatsApp account. Please take 2 minutes to delink your number from your associated WhatsApp accounts before proceeding or use a different number here for registration.
                            <a className='hover:underline hover:text-theme text-theme cursor-pointer'> Know more</a>
                        </div>
                        <div className='w-[50%] rounded-md mt-3'>
                            <MobileInput className={"py-1 h-10 text-[16px]"} required={true} countryList={globalReducer.country} phoneCode={waba?.phoneCode} phone={waba?.phone} setPhoneCode={(code) => setWaba({ ...waba, phoneCode: code })} setPhone={(phone) => setWaba({ ...waba, phone: phone })} />
                        </div>
                    </div>
                    <div className='mt-4 text-[14px]'>
                        <label className='font-semibold'>Choose your WhatsApp Business Display Name</label>
                        <div className='text-gray-500'>This is what your customers will see displayed on your WhatsApp business account. This name can be different from your registered business name. However, it must follows these
                            <a className='hover:underline hover:text-theme text-theme cursor-pointer'> WhatsApp's Guidelines.</a></div>
                        <div className='border border-gray-400 flex w-[50%] rounded-md mt-3'>
                            <div className='w-full'>
                                <input type="text" placeholder="Enter Business Name"
                                    onChange={(e) => { setWaba({ ...waba, businessName: e.target.value }) }} className="border-0 h-9 border-gray-300 rounded-md py-2 w-full" />
                            </div>
                        </div>
                    </div>
                    <div className='mt-4 text-[14px]'>
                        <label className='font-semibold'>Your business website URL<span className='text-gray-400'>(Optional)</span></label>
                        <div className='text-gray-500'>This is what your customers will see displayed on your WhatsApp business account. This name can be different from your registered business name. However, it must follows these WhatsApp's Guidelines.</div>
                        <div className='border border-gray-400 flex w-[50%] rounded-md mt-3'>
                            <div className='w-full'>
                                <input type="text" placeholder="Enter website URL"
                                    onChange={(e) => { setWaba({ ...waba, businessWebsiteUrl: e.target.value }) }} className="border-0 h-9 border-gray-300 rounded-md py-2 w-full" />
                            </div>
                        </div>
                    </div>
                    <div className='mt-4 text-[14px]'>
                        <label className='font-semibold'>Select your business category</label>
                        <div className='text-gray-500'>Select the category that best describes your business. This is shown to consumers when they view your profile. If you cannot find your business category, you can select "others"</div>
                        <div className='border border-gray-400 flex w-[50%] rounded-md mt-3'>
                            <select required className="border-0 w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
                                onChange={(e) => { setWaba({ ...waba, businessCategory: e.target.value }) }}>
                                <option value="Education">Education</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                </div> */}
                <div className={steps === 2 ? "" : "hidden"}>
                    <div className='mt-4 text-[14px] min-h-[400px]'>
                        <label className='font-semibold text-[16px]'>Connect Whatsapp Number</label>
                        <div className='text-gray-500'>
                            <p className='mt-2 text-[13px]'>
                                Connect your facebook business manager and valid phone number to whatsapp business api and start using whatsapp from iOmnihub.
                            </p>
                            <ul className='mt-2 text-[13px] list-disc ml-7'>
                                <li>
                                    If you're already using the number in WhatsApp, you will need to disconnect it from WhatsApp first.
                                </li>
                                <li className='mt-2'>
                                    If you don't want to lose any data, use our plugin to backup your WhatsApp chat histories.
                                </li>
                            </ul>
                        </div>
                        <div className='flex max-w-[200px] bg-theme rounded-md mt-3'>
                            <div className='pl-1 pt-1.5'><i className="fa-brands fa-facebook text-[25px]"></i></div>
                            <button type='button' className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer"
                                onClick={ContinueWithFacebookClick}>
                                Connect Whatsapp
                            </button>
                        </div>
                    </div>
                </div>
                <div className='mt-4 text-[14px] pb-4 bettom-0'>
                    <div className='flex justify-end'>
                        <button type='button' className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                            onClick={() => setIsShow(false)}>
                            Close
                        </button>
                        {/* <button type='button' className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                            onClick={() => setSteps((steps - 1) > 0 ? steps - 1 : 1)}>
                            Previous Step
                        </button>
                        <button type='submit' className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                            Next Step
                        </button> */}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default WhatsAppIntegration
