import React, { useEffect, useState } from 'react'
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useDispatch } from 'react-redux';
import { messageCount, messageCountChart } from '../../store/actions/partnerAction';
import { getInfo } from '../../utils/types';
import moment from 'moment';

function Dashboard() {
    let dispatch = useDispatch();
    const info = getInfo();
    const [totalMessage, setTotalMessage] = useState({});
    const [months, setMonths] = useState([]);
    const [tickPlacement, setTickPlacement] = useState('middle');
    const [statistics, setStatistics] = useState([]);
    const [tickLabelPlacement, setTickLabelPlacement] = useState('middle');
    const [chartData, setChartData] = useState([]);

    const chartSetting = {
        yAxis: [{ label: 'Statistics' }],
        series: [{ dataKey: 'count', label: 'Today’s Statistics', valueFormatter }],
        height: 350,
        sx: { [`& .${axisClasses.directionY} .${axisClasses.label}`]: { transform: 'translateX(-10px)' } },
    };

    useEffect(() => {
        let messageDate = moment();
        messageDate.subtract(1, 'month');
        dispatch(messageCount({ partnerId: info.partnerId, sendDate: messageDate.format("YYYY-MM-DD") }, (a) => {
            setTotalMessage(a);
            setStatistics([{ count: a.submitted, status: 'Submitted' }, { count: a.accepted, status: 'Sent' }, { count: a.delivered, status: 'Delivered', }, { count: a.failed, status: 'Failed' }]);
        }));
        const month = new Date().getMonth();
        const monthDates = [];
        for (var i = 6 - 1; i >= 0; i--) {
            const fromDate = new Date(new Date().getFullYear() + '-' + (month - i) + '-01');
            const toDate = new Date(new Date().getFullYear() + '-' + (month - (i - 1)) + '-01');
            monthDates.push({ text: moment(fromDate).format("MMMM"), fromDate: fromDate, toDate: toDate });
            if (i === 0) {
                monthDates.push({ text: moment(new Date()).format("MMMM"), fromDate: toDate, toDate: new Date() });
            }
        }
        setMonths(monthDates);
        dispatch(messageCountChart({ partnerId: info.partnerId, dates: monthDates }, (a) => {
            setChartData(a)
        }));
    }, []);

    function valueFormatter(value) {
        return `${value} message`;
    }
    return (<div>
        <div className='bg-gray-100 h-full mt-[-110px] px-4 py-4'>
            <div className='grid grid-cols-4 gap-4 text-[14px]'>
                <div className='bg-white p-3 rounded-lg shadow-lg'>
                    <label className='text-gray-400'>Total Submitted</label>
                    <div className='flex justify-between'>
                        <div className='text-[18px] pt-0.5'>{totalMessage?.submitted ?? 0}</div>
                        <div>
                            <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                                <i class="fa-solid fa-check text-gray-500"></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex text-[#008743]'>
                        <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                        <label className='ml-1'>Today Status</label>
                    </div>
                </div>
                <div className='bg-white p-3 rounded-lg shadow-lg'>
                    <label className='text-gray-400'>Total Sent</label>
                    <div className='flex justify-between'>
                        <div className='text-[18px] pt-0.5'>{totalMessage?.accepted ?? 0}</div>
                        <div>
                            <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                                <i class="fa-solid fa-check-double text-gray-500"></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex text-[#008743]'>
                        <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                        <label className='ml-1'>Today Status</label>
                    </div>
                </div>
                <div className='bg-white p-3 rounded-lg shadow-lg'>
                    <label className='text-gray-400'>Total Delivered</label>
                    <div className='flex justify-between'>
                        <div className='text-[18px] pt-0.5'>{totalMessage?.delivered ?? 0}</div>
                        <div>
                            <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                                <i class="fa-solid fa-check-double text-[#1291D0]"></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex text-[#008743]'>
                        <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                        <label className='ml-1'>Today Status</label>
                    </div>
                </div>
                <div className='bg-white p-3 rounded-lg shadow-lg'>
                    <label className='text-gray-400'>Total Failed</label>
                    <div className='flex justify-between'>
                        <div className='text-[18px] pt-0.5'>{totalMessage?.failed ?? 0}</div>
                        <div>
                            <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                                <i class="fa-solid fa-xmark text-[#D92D20]"></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex text-[#D92D20]'>
                        <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                        <label className='ml-1'>Today Status</label>
                    </div>
                </div>
                <div className='bg-white p-3 rounded-lg shadow-lg'>
                    <label className='text-gray-400'>Total Submitted</label>
                    <div className='flex justify-between'>
                        <div className='text-[18px] pt-0.5'>{totalMessage?.submittedByDate ?? 0}</div>
                        <div>
                            <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                                <i class="fa-solid fa-check text-gray-500"></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex text-[#008743]'>
                        <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                        <label className='ml-1'>Last 30 Days Status</label>
                    </div>
                </div>
                <div className='bg-white p-3 rounded-lg shadow-lg'>
                    <label className='text-gray-400'>Total Sent</label>
                    <div className='flex justify-between'>
                        <div className='text-[18px] pt-0.5'>{totalMessage?.acceptedByDate ?? 0}</div>
                        <div>
                            <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                                <i class="fa-solid fa-check-double text-gray-500"></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex text-[#008743]'>
                        <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                        <label className='ml-1'>Last 30 Days Status</label>
                    </div>
                </div>
                <div className='bg-white p-3 rounded-lg shadow-lg'>
                    <label className='text-gray-400'>Total Delivered</label>
                    <div className='flex justify-between'>
                        <div className='text-[18px] pt-0.5'>{totalMessage?.deliveredCount ?? 0}</div>
                        <div>
                            <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                                <i class="fa-solid fa-check-double text-[#1291D0]"></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex text-[#008743]'>
                        <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                        <label className='ml-1'>Last 30 Days Status</label>
                    </div>
                </div>
                <div className='bg-white p-3 rounded-lg shadow-lg'>
                    <label className='text-gray-400'>Total Failed</label>
                    <div className='flex justify-between'>
                        <div className='text-[18px] pt-0.5'>{totalMessage?.failedByDate ?? 0}</div>
                        <div>
                            <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                                <i class="fa-solid fa-xmark text-[#D92D20]"></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex text-[#D92D20]'>
                        <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                        <label className='ml-1'>Last 30 Days Status</label>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-12 mt-5'>
                <div className='col-span-8'>
                    {months.length > 0 &&
                        <LineChart
                            height={350}
                            series={[
                                { data: chartData.map(m => m.submitted), label: 'Submitted', area: true, stack: 'total', showMark: false },
                                { data: chartData.map(m => m.accepted), label: 'Sent', area: true, stack: 'total', showMark: false },
                                { data: chartData.map(m => m.delivered), label: 'Delivered', area: true, stack: 'total', showMark: false },
                                { data: chartData.map(m => m.failed), label: 'Failed', area: true, stack: 'total', showMark: false },
                            ]}
                            xAxis={[{ scaleType: 'point', data: months.map(m => m.text) }]}
                            sx={{
                                [`& .${lineElementClasses.root}`]: {
                                    display: 'none',
                                },
                            }}
                        />}
                </div>
                <div className='col-span-4'>
                    <BarChart
                        dataset={statistics}
                        xAxis={[
                            { scaleType: 'band', dataKey: 'status', tickPlacement, tickLabelPlacement },
                        ]}
                        {...chartSetting}
                    />
                </div>
            </div>
        </div>
    </div>)
}

export default Dashboard
