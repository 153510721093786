import { HttpUtil } from "../../utils/http-utils";
import {
  SHOW_SNACKBAR,
  IS_LOADING,
} from "../../utils/types";
const getApiLoaderDispatch = (res, dispatch) => {
  dispatch({ type: IS_LOADING, payload: { type: IS_LOADING, payload: res } });
};

function postAction(dispatch, url, isApiLoader, request, header, action) {
  if (isApiLoader) {
    getApiLoaderDispatch(isApiLoader, dispatch);
  }
  return HttpUtil.makePOST(url, request, header)
    .then((res) => {
      if (res?.status === 200) {
        action(res?.data);
      } else {
        dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
      }
      if (isApiLoader) {
        getApiLoaderDispatch(false, dispatch);
      }
    })
    .catch(() => {
      getApiLoaderDispatch(false, dispatch);
      dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
    });
}

function postParamsAction(dispatch, url, isApiLoader, request, Params, header, action) {
  if (isApiLoader) {
    getApiLoaderDispatch(isApiLoader, dispatch);
  }
  return HttpUtil.makePOSTwithParams(url, request, Params, header)
    .then((res) => {
      if (res?.status === 200) {
        action(res?.data);
      } else {
        dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
      }
      if (isApiLoader) {
        getApiLoaderDispatch(false, dispatch);
      }
    })
    .catch(() => {
      getApiLoaderDispatch(false, dispatch);
      dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
    });
}

function getAction(dispatch, url, isApiLoader, request, header, action) {
  if (isApiLoader) {
    getApiLoaderDispatch(isApiLoader, dispatch);
  }
  return HttpUtil.makeGET(url, request, header)
    .then((res) => {
      if (res?.status === 200) {
        action(res?.data);
      } else {
        dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
      }
      if (isApiLoader) {
        getApiLoaderDispatch(false, dispatch);
      }
    })
    .catch(() => {
      getApiLoaderDispatch(false, dispatch);
      dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
    });
}

//loging
export const getUUid = (request, action) => (dispatch) => getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/oauth2/authorize`, true, request, {}, action);
export const sendOTP = (request, params, action) => (dispatch) => postParamsAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/oauth2/otp`, true, request, params, {}, action);
export const verifyUser = (request, params, loginType, action) => (dispatch) => postParamsAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/oauth2/login/${loginType}`, true, request, params, {}, action);
export const generateToken = (request, params, action) => (dispatch) => postParamsAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/oauth2/token`, true, request, params, {}, action);

// Signup and Onboarding
