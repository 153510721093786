import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getPartnerDetails, changeUserStatus } from "../../store/actions/adminAction";
import { Dropdown } from 'flowbite-react';
import PopUp from '../../components/PopUp';
import AddPartnerUser from '../AddPartnerUser';

function PartnerDetails({ partnerId }) {
    const dispatch = useDispatch();
    const [Partner, setPartner] = useState({});
    const [selectedUser, setSelectedUser] = useState({});
    const [editUser, setEditUser] = useState(false);

    useEffect(() => {
        onSearch();
    }, [])

    const onSearch = () => {
        dispatch(getPartnerDetails({ criteria: { id: partnerId } }, (a) => {
            setPartner(a);
        }));
    }

    return (
        <div>
            <div className="flex">
                <button className="border-b-2 text-[14px] font-medium border-theme pb-1 pt-3">
                    Partner Details
                </button>
            </div>
            <hr />

            <div className="mt-2">
                <div className="flex flex-col text-xs font-medium text-black bg-white rounded max-w-[438px]">
                    <div className="flex gap-5 pr-5 ">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-regular fa-user text-theme"></i>
                            <div> Name:</div>
                        </div>
                        <div className="flex-auto">
                            {Partner?.name ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-globe mt-1 text-theme"></i>
                            <div> Country:</div>
                        </div>
                        <div className="flex-auto">
                            {Partner?.country?.name ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-regular fa-flag mt-1 text-theme"></i>
                            <div> Status:</div>
                        </div>
                        <div className="flex-auto">
                            {Partner?.active ? "Active" : "Not Active"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-brands fa-font-awesome mt-1 text-theme"></i>
                            <div> Logo:</div>
                        </div>
                        <div className="flex-auto">
                            <img
                                alt=""
                                className="w-[37.5px] h-[37.5px] rounded p-1 box-border bg-white"
                                src={Partner?.logo}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `${process.env.PUBLIC_URL}/images/default.png`;
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2 whitespace-nowrap justify-center items-center ">
                        <div className="flex gap-1">
                            <i className="fa-solid fa-location-dot mt-1 text-theme"></i>
                            <div>Domain:</div>
                        </div>
                        <div className="flex-auto">{Partner?.domain ?? "------"}</div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2 whitespace-nowrap justify-center items-center ">
                        <div className="flex gap-1">
                            <i className="fa-solid fa-location-dot mt-1 text-theme"></i>
                            <div>Website:</div>
                        </div>
                        <div className="flex-auto">{Partner?.website ?? "------"}</div>
                    </div>
                    <div className="flex gap-5 self-start pr-5 mt-2 whitespace-nowrap justify-center items-center ">
                        <div className="flex flex-1 gap-1">
                            <i className="fa-solid fa-pen-to-square mt-1 text-theme"></i>
                            <div>Brand Color:</div>
                        </div>
                        <div className="my-auto">{Partner?.brandColor1}, {Partner?.brandColor2}</div>
                    </div>
                    <div className="flex gap-5 self-start pr-5 mt-2 whitespace-nowrap justify-center items-center ">
                        <div className="flex flex-1 gap-1">
                            <i className="fa-solid fa-address-book mt-1 text-theme"></i>
                            <div>Contact Details:</div>
                        </div>
                        <div className="my-auto">{Partner?.user?.name}, {Partner?.user?.email}, +{Partner?.country?.phoneCode} {Partner?.user?.phone}</div>
                    </div>
                </div>
            </div>
            <div className="flex mt-4">
                <button className="border-b-2 text-[14px] font-medium border-theme pb-1 pt-3">
                    User Details
                </button>
            </div>
            <hr />
            <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                {Partner?.partnerUsers?.map((t, j) => {
                    return (<tr key={j} className="bg-white border-b">
                        <td className={"py-3 text-[13px] font-normal"}>
                            {t?.name ?? "---"}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                            {t?.email ?? "---"}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                            +{t?.phoneCode} {t?.phone ?? "---"}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                            {t?.roles?.join(",")}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                            {t?.active ? <label className='bg-[#D4F8D3] p-2 rounded-lg'>Active</label> : <label className='bg-[#F8F4D3] p-2 rounded-lg'>Not Active</label>}
                        </td>
                        <td className="py-3 font-normal text-[13px]">
                            <Dropdown inline arrowIcon={false} inputMode as="button"
                                className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                label={<i className="fa-solid fa-ellipsis"></i>}>
                                {t?.active ? <Dropdown.Item onClick={() => { dispatch(changeUserStatus({ id: t?.uid, isActive: false }, a => onSearch())) }}>
                                    Deactivate
                                </Dropdown.Item> : <Dropdown.Item onClick={() => { dispatch(changeUserStatus({ id: t?.uid, isActive: true }, a => onSearch())) }}>
                                    Activate
                                </Dropdown.Item>}
                                <Dropdown.Item onClick={() => { setSelectedUser({ ...t, confirmPassword: t.password }); setEditUser(true); }}>
                                    Edit
                                </Dropdown.Item>
                            </Dropdown>
                        </td>
                        <td className='w-4'></td>
                    </tr>
                    );
                })}
            </table>
            <PopUp
                ModalHeader={
                    <span className="font-medium text-[18px] text-black">
                        Update User
                    </span>
                }
                isShowModal={editUser}
                setIsShowModal={setEditUser}
                ModalBody={
                    <AddPartnerUser setIsShow={setEditUser} onAdd={onSearch} defaultUser={selectedUser} isEdit={true} partnerId={partnerId} />
                }
                size="2xl"
            />
        </div>
    )
}

export default PartnerDetails