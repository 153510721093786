import React, { useEffect, useState } from 'react'
import { getInfo, getWaba, saveWaba } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { getAllTenantWaba } from '../../store/actions/tenantAction';

function SelectWaba({ setIsShow }) {
    const info = getInfo();
    let dispatch = useDispatch();
    const [Wabas, setWabas] = useState([]);
    const [selectedWaba, setSelectedWaba] = useState(getWaba());

    useEffect(() => {
        dispatch(getAllTenantWaba(
            (d) => {
                setWabas(d ?? []);
                if (Array.isArray(d)) {
                    if (selectedWaba) {
                        saveWaba(d.find(f => f.id === selectedWaba.id));
                    } else {
                        saveWaba(d[0]);
                    }
                }
            }
        ));
    }, [])

    const onSelect = (w) => {
        saveWaba(w);
        setIsShow(false);
        setSelectedWaba(w);
    }

    return (
        <div>
            {Wabas?.map((w, i) =>
                <div className="flex px-4 gap-2 items-center hover:bg-gray-100 p-3" onClick={() => onSelect(w)}>
                    <img className="w-[37.5px] h-[37.5px] rounded-full p-1 box-border bg-white" src={info?.logo ?? ""}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `${process.env.PUBLIC_URL}/images/default.png`;
                        }} />
                    <div className={`text-[16px] font-medium md:w-[100%] text-black`}>
                        {w?.wabaNumber}
                        <p className="text-[14px] text-midBlack">
                            {`connected with +${w.phoneCode} ${w.phone}`}
                        </p>
                    </div>
                    {selectedWaba?.id === w.id &&
                        <div>
                            <i class="fa-solid fa-check text-theme"></i>
                        </div>}
                </div>
            )}
        </div>
    )
}

export default SelectWaba