import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { assignToTenantPlan, searchPLans } from '../../store/actions/partnerAction';
import { getTenantPlans, tenantPlanUpsert } from '../../store/actions/tenantAction';
import moment from 'moment';
import SelectCountry from '../../components/Dropdowns/SelectCountry';
import { Autocomplete, TextField } from '@mui/material';
import { allIntegrationList } from '../../utils/helper';

function MapTenantPlan({ setIsShow, onAdd, tenant = {}, isEdit = false }) {
    let dispatch = useDispatch();
    const [allPlan, setAllPlan] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [selectedPlanCode, setSelectedPlanCode] = useState();
    const [tenantPlan, setTenantPlan] = useState({});
    const globalReducer = useSelector((state) => state.globalReducer);

    useEffect(() => {
        dispatch(searchPLans({}, a => {
            setAllPlan(a.data)
        }));
        dispatch(getTenantPlans({ tenantId: tenant.id }, a => {
            if (a.data && a.data?.length > 0) {
                setTenantPlan(a.data[0]);
                setSelectedPlanCode(a.data[0].planCode);
            }
        }));
    }, []);

    const assignPlan = () => {
        tenantPlan.tenantId = tenant.id;
        tenantPlan.planCode = selectedPlan.code;
        tenantPlan.planName = selectedPlan.name;
        tenantPlan.fromDate = new Date();
        const date = new Date();
        date.setMonth(new Date().getMonth() + selectedPlan.durationMonth);
        tenantPlan.toDate = date;
        tenantPlan.planRate = selectedPlan.rate;
        tenantPlan.billingFrequency = selectedPlan.billingFrequency;
        tenantPlan.countryCode = selectedPlan.countryCode;
        tenantPlan.currency = selectedPlan.currency;
        tenantPlan.permissions = selectedPlan.permissions;
        tenantPlan.numContacts = selectedPlan.numContacts;
        tenantPlan.numLiveChatAgent = selectedPlan.numLiveChatAgent;
        tenantPlan.numSessions = selectedPlan.numSessions;
        tenantPlan.numUsers = selectedPlan.numUsers;
        tenantPlan.numWabaNumbers = selectedPlan.numWabaNumbers;
        tenantPlan.messageParMinute = selectedPlan.messageParMinute;
        tenantPlan.availableIntegrations = selectedPlan.availableIntegrations;
        setTenantPlan({ ...tenantPlan });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(tenantPlanUpsert(tenantPlan, (a) => {
            // dispatch(getTenantPlans({ tenantId: tenant.id }, t => {
            //     if (a.data && a.data?.length > 0)
            //         setTenantPlan(t.data[0])
            // }));
            onAdd(a);
            setIsShow(false);
        }))
    }
    const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
    return (
        <form className="mt-3 max-h-[550px] overflow-auto px-3" onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-3 w-full">
                <div className="col-span-10">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Plans</label>
                    </div>
                    <select value={selectedPlanCode} name='roles' className="border w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
                        onChange={(e) => {
                            setSelectedPlan(allPlan.find(f => f.code === e.target.value));
                            setSelectedPlanCode(e.target.value);
                        }}>
                        <option value="" selected disabled>
                            Select Plan...
                        </option>
                        {allPlan?.map((p, i) => <option key={i} value={p.code}>{p.name}</option>)}
                    </select>
                </div>
                <div className="col-span-2">
                    <button type='button' className="mt-7 px-[16px] py-[10px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                        onClick={assignPlan}>
                        Assign
                    </button>
                </div>
                <div className='col-span-12'>
                    <label className='text-[13px]'>Active Plans</label>
                    <div className="grid grid-cols-12 gap-3 w-full">
                        <div className="col-span-4">
                            <div className="text-midBlack text-[13px] font-normal mb-2">
                                <label>Plan Name</label>
                            </div>
                            <input type="text" readOnly placeholder="Enter Plan Name" value={tenantPlan?.planName}
                                onChange={(e) => setTenantPlan({ ...tenantPlan, planName: e.target.value })}
                                className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]" required />
                        </div>
                        <div className="col-span-4">
                            <div className="text-midBlack text-[13px] font-normal mb-2">
                                <label>FromDate</label>
                            </div>
                            <input type="date" value={moment(tenantPlan?.fromDate).format("YYYY-MM-DD")}
                                onChange={(e) => setTenantPlan({ ...tenantPlan, fromDate: new Date(e.target.value) })}
                                className="border border-gray-300 rounded-md py-1 w-[100%] h-9 text-[13px]" required />
                        </div>
                        <div className="col-span-4">
                            <div className="text-midBlack text-[13px] font-normal mb-2">
                                <label>ToDate</label>
                            </div>
                            <input type="date" value={moment(tenantPlan?.toDate).format("YYYY-MM-DD")}
                                onChange={(e) => setTenantPlan({ ...tenantPlan, toDate: new Date(e.target.value) })}
                                className="border border-gray-300 rounded-md py-1 w-[100%] h-9 text-[13px]" required />
                        </div>
                        <div className="col-span-4">
                            <div className="text-midBlack text-[13px] font-normal mb-2">
                                <label>Amount</label>
                            </div>
                            <div className='flex border border-gray-300 rounded-md text-[14px]'>
                                <label className='border-r border-gray-300 min-w-[40px] bg-gray-100 text-[14px] p-2'>{tenantPlan?.currency}</label>
                                <input type="number" placeholder="Enter Amount"
                                    value={tenantPlan?.planRate} data-maxlength={8} onInput={OnInput}
                                    onChange={(e) => setTenantPlan({ ...tenantPlan, planRate: e.target.value })}
                                    className="border-0 rounded-md py-2 w-[100%] text-[13px]" required />
                            </div>
                        </div>
                        <div className="flex flex-col col-span-4">
                            <div className="text-midBlack text-[13px] font-normal mb-2">
                                <label>Billing Frequency</label>
                            </div>
                            <select className="border border-gray-300 rounded-md py-2 px-4 cursor-pointer text-[13px] h-10" value={tenantPlan?.billingFrequency}
                                onChange={(e) => { setTenantPlan({ ...tenantPlan, billingFrequency: e.target.value }); }}>
                                <option value="MONTHLY" selected>Monthly</option>
                                <option value="QUARTERLY">Quarterly</option>
                                <option value="YEARLY">Yearly</option>
                            </select>
                        </div>
                        <div className="flex flex-col col-span-4">
                            <div className="text-midBlack text-[13px] font-normal mb-2">
                                <label>Country Name</label>
                            </div>
                            <SelectCountry required={true} selectedCode={tenantPlan?.countryCode} countryList={globalReducer.country} onSelectCountry={(country) => {
                                if (country) {
                                    tenantPlan.countryCode = country.code;
                                    tenantPlan.currency = country.currencyCode;
                                } else {
                                    delete tenantPlan.countryCode;
                                    delete tenantPlan.currency;
                                }
                                setTenantPlan({ ...tenantPlan });
                            }} />
                        </div>
                        <div className="col-span-12 h-9 text-[13px] mt-3">
                            <div className='flex h-9 justify-between'>
                                <label className="inline-flex items-center cursor-pointer">
                                    <input type="checkbox" onChange={(e) => {
                                        if (e.target.checked === true) {
                                            if (!tenantPlan?.permissions?.some(f => f == "API")) {
                                                tenantPlan.permissions?.push("API");
                                                setTenantPlan({ ...tenantPlan });
                                            }
                                        } else {
                                            setTenantPlan({ ...tenantPlan, permissions: [...tenantPlan?.permissions?.filter(f => f != "API")] });
                                        }
                                    }} checked={tenantPlan?.permissions?.some(f => f == "API")} class="sr-only peer" />
                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-theme"></div>
                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">API</span>
                                </label>
                                <div className='mt-[-18px] w-[49%]'>
                                    {tenantPlan?.permissions?.some(f => f == "API") && <>
                                        <label className='text-midBlack text-[13px] font-normal'>Messages/Minute</label>
                                        <input type="number" placeholder="Messages/Minute"
                                            value={tenantPlan?.messageParMinute} data-maxlength={8} onInput={OnInput}
                                            onChange={(e) => setTenantPlan({ ...tenantPlan, messageParMinute: e.target.value })}
                                            className="border border-gray-300 rounded-md py-1 h-9 w-full text-[13px]" required />
                                    </>}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 text-[13px] mt-2">
                            <div className='flex h-9 justify-between'>
                                <label className="inline-flex items-center cursor-pointer">
                                    <input type="checkbox" onChange={(e) => {
                                        if (e.target.checked === true) {
                                            if (!tenantPlan?.permissions?.some(f => f == "CAMPAIGNS")) {
                                                tenantPlan.permissions?.push("CAMPAIGNS");
                                                setTenantPlan({ ...tenantPlan });
                                            }
                                        } else {
                                            setTenantPlan({ ...tenantPlan, permissions: [...tenantPlan?.permissions?.filter(f => f != "CAMPAIGNS")] });
                                        }
                                    }} checked={tenantPlan?.permissions?.some(f => f == "CAMPAIGNS")} class="sr-only peer" />
                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-theme"></div>
                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Campaigns</span>
                                </label>
                                <div className='mt-[-18px] w-[49%]'>
                                    {tenantPlan?.permissions?.some(f => f == "CAMPAIGNS") && <>
                                        <label className='text-midBlack text-[13px] font-normal'>Number of Contacts</label>
                                        <input type="number" placeholder="Enter Number of Contacts"
                                            value={tenantPlan?.numContacts} data-maxlength={8} onInput={OnInput}
                                            onChange={(e) => setTenantPlan({ ...tenantPlan, numContacts: e.target.value })}
                                            className="border border-gray-300 rounded-md py-1 h-9 w-full text-[13px]" required />
                                    </>}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 text-[13px] mt-2">
                            <div className='flex justify-between h-9'>
                                <label className="inline-flex items-center cursor-pointer">
                                    <input type="checkbox" onChange={(e) => {
                                        if (e.target.checked === true) {
                                            if (!tenantPlan?.permissions?.some(f => f == "LIVE_CHAT")) {
                                                tenantPlan.permissions?.push("LIVE_CHAT");
                                                setTenantPlan({ ...tenantPlan });
                                            }
                                        } else {
                                            setTenantPlan({ ...tenantPlan, permissions: [...tenantPlan?.permissions?.filter(f => f != "LIVE_CHAT")] });
                                        }
                                    }} checked={tenantPlan?.permissions?.some(f => f == "LIVE_CHAT")} class="sr-only peer" />
                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-theme"></div>
                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Live Chat</span>
                                </label>
                                <div className='mt-[-18px] w-[49%]'>
                                    {tenantPlan?.permissions?.some(f => f == "LIVE_CHAT") && <>
                                        <label className='text-midBlack text-[13px] font-normal'>Number of Live Chat Agent</label>
                                        <input type="number" placeholder="Enter Number of Live Chat Agent"
                                            value={tenantPlan?.numLiveChatAgent} data-maxlength={6} onInput={OnInput}
                                            onChange={(e) => setTenantPlan({ ...tenantPlan, numLiveChatAgent: e.target.value })}
                                            className="border border-gray-300 rounded-md py-1 h-9 w-full text-[13px]" required />
                                    </>}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 text-[13px]">
                            <div className='flex h-9'>
                                <label className="inline-flex items-center cursor-pointer">
                                    <input type="checkbox" onChange={(e) => {
                                        if (e.target.checked === true) {
                                            if (!tenantPlan?.permissions?.some(f => f == "FINANCE_ACCESS")) {
                                                tenantPlan.permissions?.push("FINANCE_ACCESS");
                                                setTenantPlan({ ...tenantPlan });
                                            }
                                        } else {
                                            setTenantPlan({ ...tenantPlan, permissions: [...tenantPlan?.permissions?.filter(f => f != "FINANCE_ACCESS")] });
                                        }
                                    }} checked={tenantPlan?.permissions?.some(f => f == "FINANCE_ACCESS")} class="sr-only peer" />
                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-theme"></div>
                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Finance & Report</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-span-12 text-[13px]">
                            <div className='flex justify-between  h-9'>
                                <label className="inline-flex items-center cursor-pointer">
                                    <input type="checkbox" onChange={(e) => {
                                        if (e.target.checked === true) {
                                            if (!tenantPlan?.permissions?.some(f => f == "CHATBOT")) {
                                                tenantPlan.permissions?.push("CHATBOT");
                                                setTenantPlan({ ...tenantPlan });
                                            }
                                        } else {
                                            setTenantPlan({ ...tenantPlan, permissions: [...tenantPlan?.permissions?.filter(f => f != "CHATBOT")] });
                                        }
                                    }} checked={tenantPlan?.permissions?.some(f => f == "CHATBOT")} class="sr-only peer" />
                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-theme"></div>
                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Chatbot</span>
                                </label>
                                <div className='mt-[-18px] w-[49%]'>
                                    {tenantPlan?.permissions?.some(f => f == "CHATBOT") && <>
                                        <label className='text-midBlack text-[13px] font-normal'>Number of Sessions</label>
                                        <input type="number" placeholder="Enter Number of Sessions"
                                            value={tenantPlan?.numSessions} data-maxlength={5} onInput={OnInput}
                                            onChange={(e) => setTenantPlan({ ...tenantPlan, numSessions: e.target.value })}
                                            className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]" required />
                                    </>}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-6">
                            <div className="text-midBlack text-[13px] font-normal mb-2">
                                <label>Users</label>
                            </div>
                            <input type="number" placeholder="Enter Users"
                                value={tenantPlan?.numUsers} data-maxlength={5} onInput={OnInput}
                                onChange={(e) => setTenantPlan({ ...tenantPlan, numUsers: e.target.value })}
                                className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]" required />
                        </div>
                        <div className="col-span-6">
                            <div className="text-midBlack text-[13px] font-normal mb-2">
                                <label>Number of WABA Account</label>
                            </div>
                            <input type="number" placeholder="Enter WABA"
                                value={tenantPlan?.numWabaNumbers} data-maxlength={8} onInput={OnInput}
                                onChange={(e) => setTenantPlan({ ...tenantPlan, numWabaNumbers: e.target.value })}
                                className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]" required />
                        </div>
                        <div className="col-span-12">
                            <div className="text-midBlack text-[13px] font-normal">
                                <label>Available Integrations</label>
                            </div>
                            <Autocomplete multiple={true} aria-required={true} value={allIntegrationList?.filter(m => tenantPlan.availableIntegrations?.some(s => s === m.type))} options={allIntegrationList} isOptionEqualToValue={(option, value) => option?.type === value?.type}
                                getOptionLabel={(option) => option?.type ?? ""}
                                onChange={(e, value) => { setTenantPlan({ ...tenantPlan, availableIntegrations: value?.map(c => c.type) ?? [] }) }}
                                sx={{
                                    '& .MuiAutocomplete-inputRoot': {
                                        padding: '0px',
                                    }
                                }}
                                renderInput={(params) => (<TextField {...params} placeholder="Select Category" className="custom-input"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            padding: "0px",
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'none',
                                                border: '0px',
                                            },
                                            fontSize: "14px",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            backgroundColor: '#FFFFFF',
                                            "& fieldset": {
                                                borderColor: '#ccc'
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#888',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#1976d2',
                                            }
                                        }
                                    }}
                                />)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-2 mt-4 mb-5">
                <button type='button'
                    className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                    onClick={() => setIsShow(false)}>
                    Cancel
                </button>
                <button type='submit'
                    className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                    Save
                </button>
            </div>
        </form>
    )
}

export default MapTenantPlan