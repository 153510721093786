export const whatsAppDoc = (tenantId, apikKey) => {
    return {
        "openapi": "3.0.1",
        "servers": [{ "url": process.env.REACT_APP_PRODUCT_APIURL }],
        "tags": [
            {
                "name": "API",
                "description": "WhatsApp integration journey"
            }
        ],
        "paths": {
            "/oauth2/token?client_id=iomnihub-tenant": {
                "post": {
                    "tags": [
                        "API"
                    ],
                    "summary": "get token",
                    "description": "Generate token for api access",
                    "operationId": "get-token",
                    "parameters": [
                        {
                            "name": "x-tenant-id",
                            "in": "header",
                            "description": "x-tenant-id id",
                            "required": true,
                            "value": tenantId,
                            "schema": {
                                "type": "string"
                            }
                        },
                        {
                            "name": "x-api-key",
                            "in": "header",
                            "description": "x-api-key Key",
                            "required": true,
                            "value": apikKey,
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "responses": {
                        "200": {
                            "description": "successful",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "object",
                                        "properties": {
                                            "access_token": {
                                                "type": "string"
                                            },
                                            "refresh_token": {
                                                "type": "string"
                                            },
                                            "token_type": {
                                                "type": "string"
                                            },
                                            "expires_in": {
                                                "type": "int64"
                                            }
                                        },
                                        "example": {
                                            "access_token": "eyJhbGciOiJSUzI1NiJ9.ufu",
                                            "refresh_token": "eyJhbGciOiJSUzI1NiJ9.eyJh",
                                            "token_type": "bearer",
                                            "expires_in": 72000,
                                        }
                                    }
                                }
                            }
                        },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "/tenant/contact/upsert": {
                "post": {
                    "tags": [
                        "API"
                    ],
                    "summary": "contact upsert",
                    "description": "create contact",
                    "operationId": "contact-upsert",
                    "parameters": [
                        {
                            "name": "Authorization",
                            "in": "header",
                            "description": "bearer token",
                            "required": true,
                            "value": "bearer ",
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "requestBody": {
                        "description": "Appointment object",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object",
                                    "properties": {
                                        "customerId": {
                                            "type": "string"
                                        },
                                        "name": {
                                            "type": "string"
                                        },
                                        "gender": {
                                            "type": "string",
                                            "default": "Male",
                                            "enum": [
                                                "Male",
                                                "Female",
                                                "Both"
                                            ],
                                            "example": "Male"
                                        },
                                        "phoneCode": {
                                            "type": "integer",
                                            "required": true,
                                        },
                                        "phone": {
                                            "type": "string",
                                            "pattern": "^\\d10$",
                                            "required": true,
                                            "example": "1234567890"
                                        },
                                        "email": {
                                            "type": "string",
                                            "pattern": "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2, 4}$",
                                            "example": "demo@gmail.com"
                                        },
                                        "location": {
                                            "type": "string"
                                        },
                                        "category": {
                                            "type": "array",
                                            "items": {
                                                "type": "string"
                                            }
                                        },
                                    }
                                }
                            }
                        }
                    },
                    "responses": {
                        "200": {
                            "description": "successful",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "object",
                                        "properties": {
                                            "id": {
                                                "type": "integer",
                                            },
                                            "customerId": {
                                                "type": "string"
                                            },
                                            "name": {
                                                "type": "string"
                                            },
                                            "gender": {
                                                "type": "string",
                                                "default": "Male",
                                                "enum": [
                                                    "Male",
                                                    "Female",
                                                    "Both"
                                                ],
                                                "example": "Male"
                                            },
                                            "phoneCode": {
                                                "type": "integer",
                                                "required": true,
                                            },
                                            "phone": {
                                                "type": "string",
                                                "pattern": "^\\d10$",
                                                "required": true,
                                                "example": "1234567890"
                                            },
                                            "email": {
                                                "type": "string",
                                                "pattern": "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2, 4}$",
                                                "example": "demo@gmail.com"
                                            },
                                            "location": {
                                                "type": "string"
                                            },
                                            "category": {
                                                "type": "array",
                                                "items": {
                                                    "type": "string"
                                                }
                                            },
                                        }
                                    }
                                }
                            }
                        },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "/tenant-waba": {
                "get": {
                    "tags": [
                        "API"
                    ],
                    "summary": "get waba accounts",
                    "description": "get waba accounts",
                    "operationId": "get-waba-account",
                    "parameters": [
                        {
                            "name": "Authorization",
                            "in": "header",
                            "description": "bearer token",
                            "required": true,
                            "value": "bearer ",
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "responses": {
                        "200": {
                            "description": "successful",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "array",
                                        "items": {
                                            "type": "object",
                                            "properties": {
                                                "id": {
                                                    "type": "integer"
                                                },
                                                "tenantId": {
                                                    "type": "string"
                                                },
                                                "wabaNumber": {
                                                    "type": "string"
                                                },
                                                "bspId": {
                                                    "type": "string"
                                                },
                                                "phone": {
                                                    "type": "string"
                                                },
                                                "phoneCode": {
                                                    "type": "integer"
                                                },
                                                "phone": {
                                                    "type": "string"
                                                },
                                                "phoneNumberId": {
                                                    "type": "string"
                                                },
                                                "whatsappDisplayName": {
                                                    "type": "string"
                                                },
                                                "accountStatus": {
                                                    "type": "string"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "400": {
                            "description": "Invalid planCode",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/400ApiResponse"
                                    }
                                }
                            }
                        },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "/tenant/whatsapp-message/send-template-message": {
                "post": {
                    "tags": [
                        "API"
                    ],
                    "summary": "Send Template Message",
                    "description": "send template message",
                    "operationId": "send-template-message",
                    "parameters": [
                        {
                            "name": "Authorization",
                            "in": "header",
                            "description": "bearer token",
                            "required": true,
                            "value": "bearer ",
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "requestBody": {
                        "description": "Message object",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object",
                                    "properties": {
                                        "whatsappNumber": {
                                            "type": "string"
                                        },
                                        "wabaNumber": {
                                            "type": "string"
                                        },
                                        "templateName": {
                                            "type": "string"
                                        },
                                        "components": {
                                            "type": "array",
                                            "items": {
                                                "$ref": "#/components/schemas/component"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "responses": {
                        "200": {
                            "description": "successful",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "object",
                                        "properties": {
                                            "status": {
                                                "type": "boolean"
                                            },
                                            "message": {
                                                "type": "string"
                                            },
                                            "statusCode": {
                                                "type": "integer",
                                                "format": "int64"
                                            },
                                            "data": {
                                                "type": "object",
                                                "properties": {
                                                    "appointmentId": {
                                                        "type": "integer",
                                                        "format": "int64"
                                                    },
                                                    "status": {
                                                        "type": "string",
                                                        "default": "Requested",
                                                        "enum": [
                                                            "Requested",
                                                            "Confirmed",
                                                            "Cancelled",
                                                            "Completed",
                                                            "Closed"
                                                        ]
                                                    }
                                                }
                                            }
                                        },
                                        "example": {
                                            "status": true,
                                            "message": "successful",
                                            "statusCode": 200,
                                            "data": {
                                                "appointmentId": 210,
                                                "status": "Requested"
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "400": {
                            "description": "Invalid planCode",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/400ApiResponse"
                                    }
                                }
                            }
                        },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        "components": {
            "schemas": {
                "component": {
                    "type": "object",
                    "properties": {
                        "type": {
                            "type": "string",
                            "example": "body"
                        },
                        "sub_type": {
                            "type": "string",
                            "example": "url"
                        },
                        "index": {
                            "type": "integer",
                            "example": "1"
                        },
                        "parameters": {
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "type": "object",
                                    "type": {
                                        "type": "string",
                                        "default": "text"
                                    },
                                    "text": {
                                        "type": "string",
                                        "example": "₹950000"
                                    },
                                }
                            }
                        }
                    }
                },
                "UnauthorizedApiResponse": {
                    "type": "object",
                    "properties": {
                        "code": {
                            "type": "string",
                            "example": "AUTH4041"
                        },
                        "message": {
                            "type": "string",
                            "example": "user not found"
                        },
                        "description": {
                            "type": "string",
                            "example": "api key does not exist"
                        }
                    }
                },
                "400ApiResponse": {
                    "type": "object",
                    "properties": {
                        "code": {
                            "type": "string",
                            "example": "Z4001"
                        },
                        "message": {
                            "type": "string",
                            "example": "Invalid Argument"
                        },
                        "description": {
                            "type": "string",
                            "example": "Invalid Argument"
                        }
                    }
                },
                "500ApiResponse": {
                    "type": "object",
                    "properties": {
                        "status": {
                            "type": "string",
                            "example": ""
                        },
                        "message": {
                            "type": "string",
                            "example": "Internal server error"
                        },
                        "statusCode": {
                            "type": "string",
                            "example": "Internal server error"
                        }
                    }
                }
            }
        }
    }
};