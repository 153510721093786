import { Dropdown } from 'flowbite-react';
import React, { useEffect, useState } from 'react'
import WhatsAppIntegration from '../../layouts/tenant/WhatsAppIntegration';
import PopUp from '../../components/PopUp';
import RightSidePanel from '../../components/RightSidePanel';
import AccountInfo from '../../layouts/tenant/AccountInfo';
import { allIntegrationList } from '../../utils/helper';
import WhatsAppMsgTemplates from '../../layouts/tenant/WhatsAppMsgTemplates';
import { useDispatch, useSelector } from 'react-redux';
import { changeTenantWabaStatus, searchTenantWaba } from '../../store/actions/tenantAction';
import { getInfo } from '../../utils/types';
import ViewTenantRateCard from '../../layouts/tenant/ViewTenantRateCard';
import EditWaba from '../../layouts/tenant/EditWaba';

function Integration() {
    let dispatch = useDispatch(); 
    const info = getInfo();
    const [IntegratedList, setIntegratedList] = useState([]);
    const [availableList, setAvailableList] = useState([]);
    const [showEditWaba, setShowEditWaba] = useState(false);
    const [showConnectWhatsApp, setshowConnectWhatsApp] = useState(false);
    const [showDisconnect, setShowDisconnect] = useState(false);
    const [showWhatsAppMsgTemplates, setShowWhatsAppMsgTemplates] = useState(false);
    const [showIntegrations, setShowIntegrations] = useState(false);
    const [showAccountInfo, setShowAccountInfo] = useState(false);
    const [showRateCard, setShowRateCard] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState({});
    const tenantReducer = useSelector((state) => state.tenantReducer);

    useEffect(() => {
        onSearch();
    }, []);

    const onClickAvailableIntegrations = () => {
        if (Array.isArray(tenantReducer.tenantDetails?.plan?.availableIntegrations)) {
            setAvailableList(allIntegrationList.filter(f => tenantReducer.tenantDetails?.plan?.availableIntegrations?.some(s => s === f.type)));
        }
        setShowIntegrations(true);
    }

    const onSearch = () => {
        dispatch(searchTenantWaba({ tenantId: info?.tenantId }, a => {
            if (a && Array.isArray(a.data)) {
                setIntegratedList(a.data.map(m => {
                    if (m.bsp?.integrationType === "GRAPH_API") {
                        return { ...m, name: "WhatsApp Business", title: `connected with +${m.phoneCode} ${m.phone}`, iconPath: `${process.env.PUBLIC_URL}/images/WhatsApp-Business.png` };
                    } else {
                        return {};
                    }
                }));
            }
            else {
                setIntegratedList([]);
            }
        }));
    }

    return (
        <div>
            <div className="fixed top-0 w-[82%] bg-white z-30 ">
                <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                    <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                        <i className="fa-regular text-[14px] fa-solid fa-link text-theme"></i>
                        <div className="text-[14px] font-medium">Integration Setup</div>
                    </div>
                    <div className="flex gap-2">
                    </div>
                </div>
                <div className="bg-white text-black font-medium px-5 min-h-[50.4px] box-border border-b flex justify-between items-end">
                    <div className="flex text-[15px]">
                        <button className={" text-gray-500 px-1 pb-2 " + (showIntegrations ? "" : " border-theme text-theme afterline border-b-[2px]")}
                            onClick={(a) => setShowIntegrations(false)}>
                            Integrated
                        </button>
                        <button className={" text-gray-500 px-6 pb-2 " + (showIntegrations ? " border-theme text-theme afterline border-b-[2px]" : "")}
                            onClick={(a) => { onClickAvailableIntegrations(); }}>
                            Available Integrations
                        </button>
                    </div>
                </div>
            </div>

            {showIntegrations ? <div className="grid grid-cols-3 gap-4 pl-8 pt-2">
                {availableList.map((p, i) => <div key={i} className='bg-gray-100 p-5 min-w-[320px] rounded-lg'>
                    <div className='flex justify-between text-[14px]'>
                        <div className='min-w-[42px]'>
                            <img className="w-[37.5px] h-[37.5px] box-border" src={p?.iconPath} />
                        </div>
                        <div>
                            <div>{p.name}</div>
                            <div className='text-gray-500'>{p.title}</div>
                        </div>
                        <div>
                            <Dropdown inline arrowIcon={false} inputMode as="button" className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                label={<i className="fa-solid fa-ellipsis"></i>}>
                                <Dropdown.Item onClick={() => { setshowConnectWhatsApp(true); }}>
                                    Connect
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                </div>)}
            </div> : <div className="grid grid-cols-3 gap-4 pl-8 pt-2">
                {IntegratedList.map((p, i) => <div key={i} className='bg-gray-100 p-5 min-w-[320px] rounded-lg'>
                    <div className='flex justify-between text-[14px]'>
                        <div className='min-w-[42px]'>
                            <img className="w-[37.5px] h-[37.5px] box-border" src={p?.iconPath} />
                        </div>
                        <div>
                            <div>{p.name}</div>
                            <div className='text-gray-500'>{p.title}</div>
                        </div>
                        <div>
                            <Dropdown inline arrowIcon={false} inputMode as="button" className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                label={<i className="fa-solid fa-ellipsis"></i>}>
                                <Dropdown.Item onClick={() => { setSelectedAccount(p); setShowDisconnect(true); }} className='hover:text-theme'>
                                    <i className="fa-solid fa-link-slash pr-2"></i>Disconnect
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { setShowEditWaba(true); setSelectedAccount(p); }} className='hover:text-theme'>
                                    <i className="fa-regular fa-pen-to-square pr-2"></i> Edit
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { setSelectedAccount(p); setShowAccountInfo(true); }} className='hover:text-theme'>
                                    <i className="fa-solid fa-circle-info pr-2"></i>  Account Info
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { setShowWhatsAppMsgTemplates(true); }} className='hover:text-theme'>
                                    <i className="fa-solid fa-table-list pr-2"></i>  Manage Templates
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { setShowRateCard(true); }} className='hover:text-theme'>
                                    <i className="fa-solid fa-table-list pr-2"></i>  View Conversation Rate Card
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                </div>)}
            </div>}
            <PopUp
                ModalHeader={
                    <span className="font-medium text-[18px] text-black flex">
                        <img className="w-[30px] h-[30px] box-border mr-2" src={selectedAccount?.iconPath} />   Account Info
                    </span>
                }
                isShowModal={showAccountInfo}
                setIsShowModal={setShowAccountInfo}
                ModalBody={
                    <AccountInfo setIsShow={setShowAccountInfo} account={selectedAccount} />
                }
                size="2xl"
            />
            <RightSidePanel
                CustomClass="fixed md:w-[50%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
                isShow={showConnectWhatsApp}
                setIsShow={setshowConnectWhatsApp}
                ModalTitle={"Integrate WhatsApp"}
                Component={
                    <WhatsAppIntegration setIsShow={setshowConnectWhatsApp} onIntegrated={onSearch} />
                }
            />
            <RightSidePanel
                CustomClass="fixed md:w-[50%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
                isShow={showWhatsAppMsgTemplates}
                setIsShow={setShowWhatsAppMsgTemplates}
                ModalTitle={"WhatsApp Message Templates"}
                Component={
                    <WhatsAppMsgTemplates isPopup={true} setIsShow={setShowWhatsAppMsgTemplates} />
                }
            />
            <RightSidePanel
                CustomClass="fixed md:w-[50%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
                isShow={showRateCard}
                setIsShow={setShowRateCard}
                ModalTitle={"Conversation Rate Card"}
                Component={
                    <ViewTenantRateCard setIsShow={setShowRateCard} waba={selectedAccount} />
                }
            />
            <RightSidePanel
                CustomClass="fixed md:w-[50%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
                isShow={showEditWaba}
                setIsShow={setShowEditWaba}
                ModalTitle={"Edit Waba"}
                Component={
                    <EditWaba setIsShow={setShowEditWaba} waba={selectedAccount} />
                }
            />
            <PopUp
                ModalHeader={<>
                    <div className="font-medium text-[18px] text-black">
                        Are you want to Disconnect?
                    </div>
                    <div className="font-medium text-[14px] text-[#6E6C6D]">
                        Any unsaved changes will be lost.
                    </div>
                </>}
                isShowModal={showDisconnect} setIsShowModal={setShowDisconnect}
                ModalBody={<div className="flex justify-end gap-4 pt-10 px-0">
                    <button className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                        onClick={() => setShowDisconnect(false)}
                    > Cancel</button>
                    <button
                        className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                        onClick={() => {
                            dispatch(changeTenantWabaStatus({ id: selectedAccount.id, tenantId: selectedAccount.tenantId, isActive: false }, a => {
                                onSearch();
                                setShowDisconnect(false);
                            }));
                        }}
                    >Disconnect</button>
                </div>}
                size="md" />
        </div>
    )
}

export default Integration