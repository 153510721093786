import { HttpUtil } from "../../utils/http-utils";
import {
    SHOW_SNACKBAR,
    IS_LOADING,
    getToken,
    getRefreshToken,
    TENANT_DETAILS
} from "../../utils/types";

const getApiLoaderDispatch = (res, dispatch) => {
    dispatch({ type: IS_LOADING, payload: { type: IS_LOADING, payload: res } });
};

function postAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makePOST(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function postParamsAction(dispatch, url, isApiLoader, request, Params, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makePOSTwithParams(url, request, Params, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function getAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makeGET(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function deleteAction(dispatch, url, isApiLoader, params, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makeDELETE(url, params, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

export const getTenantDetails = (request, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/${request.tenantId}/details`, true, {}, { Authorization: `bearer ${getToken()}` }, (a) => {
        if (a) {
            dispatch({ type: TENANT_DETAILS, payload: a });
            action(a);
        }
    });

export const getTenantUsers = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantUserDetail = (id, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/detail/${id}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const tenantUserUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeUserStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/${request.tenantId}/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantWaba = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const searchTenantWaba = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/${request.tenantId}/search`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getAllTenantWaba = (action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const upsertTenantWaba = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/${request.tenantId}/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeTenantWabaStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/${request.tenantId}/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantPlans = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/plan/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const tenantPlanUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/plan/${request.tenantId}/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantRateCardDetails = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/rate-card/${request.tenantId}/details`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantContacts = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const tenantContactUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const tenantContactBulkUpload = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/bulk-upload`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeContactStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const embeddedSignup = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/facebook-embedded-signup`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeWhatsAppMsgTemplateStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getWhatsAppMsgTemplate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const searchWhatsAppMsgTemplate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/search`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const whatsAppMsgTemplateCreate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/create`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const whatsAppMsgTemplateUpdate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/update`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const deleteWhatsAppMsgTemplate = (request, action) => (dispatch) =>
    deleteAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/delete/${request.id}`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const sendWhatsAppMessage = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-message/${request.tenantId}/send`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const categoryQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/${request.tenantId}/category-query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const categoryView = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/${request.tenantId}/category-query-view`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const categoryUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/category-upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const categoryDelete = (id, action) => (dispatch) =>
    deleteAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/category-delete/${id}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const campaignQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/campaign/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const campaignCreate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/campaign/${request.tenantId}/create`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const checkAndGenerateApiKey = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/api-access/${request.tenantId}/check-generate`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const updateApiKey = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/api-access/${request.tenantId}/update`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getWalletByWaba = (request, tenantId, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/wallet/${tenantId}/wallet-by-waba`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const calculateBroadcastPayment = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/campaign/${request.tenantId}/calculate-broadcast-payment`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const messageCount = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/dashboard/message-count`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const messageCountChart = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/dashboard/message-count-chart`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const walletHistoryQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/wallet/${request.tenantId}/wallet-history`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getAccountInformation = (action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/plan/account-information`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const getLoginHistory = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/login/history`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getDashboardInfo = (wabaId, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/dashboard/info/${wabaId}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);
