import { Dropdown, Pagination } from 'flowbite-react';
import React, { useEffect, useState } from 'react'
import { deleteWhatsAppMsgTemplate, getWhatsAppMsgTemplate } from '../../store/actions/tenantAction';
import RightSidePanel from '../../components/RightSidePanel';
import CreateWhatsAppTemplate from './CreateWhatsAppTemplate';
import { useDispatch } from 'react-redux';
import { getInfo, getWaba } from '../../utils/types';
import moment from 'moment';
import PopUp from '../../components/PopUp';
import { useNavigate } from 'react-router-dom';

function WhatsAppMsgTemplates({ isPopup = false }) {
    const tenantInfo = getInfo();
    const selectedWaba = getWaba();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({ criteria: {} });
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [showAddTemplate, setShowAddTemplate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemParPage = 10;

    useEffect(() => {
        templateSearch();
    }, [currentPage])

    const templateSearch = () => {
        dispatch(getWhatsAppMsgTemplate(
            {
                tenantId: tenantInfo?.tenantId,
                ...filter,
                count: true,
                size: itemParPage,
                offset: (currentPage - 1) * itemParPage,
            },
            (d) => {
                setTemplates(d?.data ?? []);
                setTotalPages(Math.ceil(d?.count / itemParPage));
            }
        ));
    };

    const onSearch = () => {
        setCurrentPage(1);
        templateSearch();
    }

    return (<>
        <div>
            <div className="grid grid-cols-12 gap-3 pr-3 pt-2">
                <div className={`flex flex-col col-span-6`}>
                    <input type="text" placeholder="Search template by name, id" className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                        onChange={(e) => {
                            setFilter({
                                ...filter, filter: [
                                    { property: "name", operator: "CONTAINS", value: e.target.value },
                                    { clause: "OR" },
                                    { property: "messageType", operator: "CONTAINS", value: e.target.value },
                                    { clause: "OR" },
                                    { property: "referenceId", operator: "CONTAINS", value: e.target.value },
                                ]
                            })
                        }} />
                </div>
                <div className={`flex flex-col col-span-3`}>
                    <button type="button" className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                        onClick={onSearch}> Search
                    </button>
                </div>
                <div className={`flex flex-col col-span-3`}>
                    <button type="button" className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                        onClick={() => { setShowAddTemplate(true); setSelectedTemplate({}) }}> + Create Template
                    </button>
                </div>
            </div>
            <table className="text-sm text-left rtl:text-right text-midBlack w-full mt-2 overflow-x-auto">
                <thead className="text-[14px] text-midBlack">
                    <tr>
                        <th scope="col" className="pb-1 font-[500]">
                            <div className="flex gap-2">
                                <div>Template Id</div>
                            </div>
                        </th>
                        <th scope="col" className="pb-1 font-[500]">
                            <div className="flex gap-2">
                                <div>Name</div>
                            </div>
                        </th>
                        {!isPopup && <>
                            <th scope="col" className="pb-1 font-[500]">
                                <div className="flex gap-2">
                                    <div>Category</div>
                                </div>
                            </th>
                            <th scope="col" className="pb-1 font-[500]">
                                <div className="flex gap-2">
                                    <div>Language</div>
                                </div>
                            </th>
                            <th scope="col" className="pb-1 font-[500]">
                                <div className="flex gap-2">
                                    <div>DateTime</div>
                                </div>
                            </th>
                        </>}
                        <th scope="col" className="pb-1 font-[500]">
                            <div className="flex gap-2">
                                <div> Status</div>
                            </div>
                        </th>
                        <th scope="col" className="pb-1 font-[500]">
                            <div className="flex gap-2">
                                <div>Action</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {templates?.map((t, j) => {
                        return (<tr key={j} className="bg-white border-b">
                            <td className={"py-3 text-[13px] font-normal"}>
                                {t?.referenceId ?? "---"}
                            </td>
                            <td className={"py-3 text-[13px] font-normal"}>
                                {t?.name ?? "---"}
                            </td>
                            {!isPopup && <>
                                <td className={"py-3 text-[13px] font-normal"}>
                                    {t?.messageType ?? "---"}
                                </td>
                                <td className={"py-3 text-[13px] font-normal"}>
                                    {t?.language ?? "---"}
                                </td>
                                <td className={"py-3 text-[13px] font-normal"}>
                                    {moment(t?.createdAt).format("YYYY-MM-DD hh:mm A")}
                                </td>
                            </>}
                            <td className={"py-3 text-[13px] font-normal"}>
                                {t?.status?.toLowerCase() === "approved" ? <label className='bg-[#D4F8D3] p-2 rounded-lg'>{t?.status}</label> : <label className='bg-[#F8F4D3] p-2 rounded-lg'>{t?.status}</label>}
                            </td>
                            <td className=" py-3 font-normal text-[13px]">
                                <Dropdown inline arrowIcon={false} inputMode as="button" className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                    label={<i className="fa-solid fa-ellipsis"></i>}>
                                    <Dropdown.Item onClick={() => { setSelectedTemplate(t); setShowDelete(true); }}>
                                        Delete
                                    </Dropdown.Item>
                                    {["approved", "rejected", "paused"].includes(t?.status?.toLowerCase()) &&
                                        <Dropdown.Item onClick={() => { setSelectedTemplate({ ...t, isEdit: true }); setShowAddTemplate(true) }}>
                                            Edit
                                        </Dropdown.Item>}
                                    <Dropdown.Item onClick={() => { setSelectedTemplate({ ...t, isEdit: true, isView: true }); setShowAddTemplate(true) }}>
                                        View
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item onClick={() => { setSelectedTemplate({ ...t, isEdit: true, isView: true }); setShowAddTemplate(true) }}>
                                        Send Test
                                    </Dropdown.Item> */}
                                    <Dropdown.Item onClick={() => { navigate("/tenant/campaigns", { state: { isCreate: true, template: t } }); }}>
                                        Send Broadcast
                                    </Dropdown.Item>
                                </Dropdown>
                            </td>
                        </tr>
                        );
                    })}
                </tbody>
            </table>
            {totalPages > 1 && (
                <div className="flex overflow-x-auto sm:justify-center bottom-0 mb-[5rem], mt-2">
                    <Pagination
                        page={currentPage}
                        count={totalPages}
                        onChange={(event, page) => setCurrentPage(page)}
                        shape="rounded"
                        color="secondary"
                    />
                </div>
            )}
        </div>
        <RightSidePanel
            CustomClass="fixed md:w-[70%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
            isShow={showAddTemplate}
            setIsShow={setShowAddTemplate}
            ModalTitle={`${(selectedTemplate?.isView ? "View" : selectedTemplate?.isEdit ? "Edit" : "Add")} Message Template`}
            Component={
                <CreateWhatsAppTemplate waba={selectedWaba} tenantId={tenantInfo?.tenantId} setIsShow={setShowAddTemplate} selectedTemplate={selectedTemplate} isEdit={selectedTemplate?.isEdit} isView={selectedTemplate?.isView} onAdd={() => templateSearch()} />
            }
        />
        <PopUp
            ModalHeader={<>
                <div className="font-medium text-[18px] text-black">
                    Are you want to DELETE the template ?
                </div>
            </>}
            isShowModal={showDelete} setIsShowModal={setShowDelete}
            ModalBody={<div className="flex justify-end gap-4 pt-10 px-0">
                <button className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                    onClick={() => setShowDelete(false)}
                > Cancel</button>
                <button
                    className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                    onClick={() => {
                        dispatch(deleteWhatsAppMsgTemplate({ tenantId: selectedTemplate?.tenantId, id: selectedTemplate?.id }, a => {
                            onSearch();
                            setShowDelete(false);
                        }))
                    }}
                >Delete</button>
            </div>}
            size="md" />
    </>)
}

export default WhatsAppMsgTemplates