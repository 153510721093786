import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14,
    boxShadow: theme.shadows[1],
    color: "#fff",
    borderRadius: "10px",
    padding: "0.7rem"
  },
}));

export default function CustomizedTooltips({ content, title, className }) {
  return (
    <div>
      <BootstrapTooltip title={content}>
        <div className={"py-1 px-2 text-black " + className ?? ""}>
          {title}
        </div>
      </BootstrapTooltip>
    </div>
  );
}
