import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAccountInformation } from '../../store/actions/tenantAction';
import { allPlanPermissions } from '../../utils/helper';

function AccountInformation() {
    const dispatch = useDispatch();
    const [account, setAccount] = useState({});
    const tenantReducer = useSelector((state) => state.tenantReducer);

    useEffect(() => {
        dispatch(getAccountInformation(setAccount));
    }, [])

    return (
        <div>
            <div className="flex">
                <button className="border-b-2 text-[14px] font-medium border-theme pb-1 pt-3">
                    Plan Details
                </button>
            </div>
            <hr />

            <div className="mt-2">
                <div className="flex flex-col text-xs font-medium text-black bg-white rounded max-w-[438px]">
                    <div className="flex gap-5 pr-5 ">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-box-archive text-theme"></i>
                            <div>Plan Code:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.code ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-box-archive text-theme"></i>
                            <div>Plan Name:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.name ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-calendar-days text-theme"></i>
                            <div>Tenure(Months):</div>
                        </div>
                        <div className="flex-auto">
                            {account?.durationMonth ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-wallet text-theme"></i>
                            <div>Amount:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.rate ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i class="fa-solid fa-file-invoice text-theme"></i>
                            <div>Billing Frequency:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.billingFrequency ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-globe mt-1 text-theme"></i>
                            <div> Country:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.countryCode ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-globe mt-1 text-theme"></i>
                            <div> Currency:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.currency ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-brands fa-rocketchat text-theme mt-1"></i>
                            <div> Number of Live Chat Agent:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.numLiveChatAgent ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-regular fa-address-book text-theme mt-1"></i>
                            <div> Number of Contacts:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.numContacts ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-brands fa-rocketchat text-theme mt-1"></i>
                            <div> Number of Sessions:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.numSessions ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-users text-theme mt-1"></i>
                            <div> Number of Users:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.numUsers ?? "------"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-money-check text-theme mt-1"></i>
                            <div> Number of WABA Account:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.numWabaNumbers ?? "------"}
                        </div>
                    </div>
                    {allPlanPermissions.map((m, i) => <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-universal-access text-theme mt-1"></i>
                            <div> {m.value}:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.permissions?.some(s => s === m.key) ? "Yes" : "No"}
                        </div>
                    </div>)}
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-regular fa-flag mt-1 text-theme"></i>
                            <div> Status:</div>
                        </div>
                        <div className="flex-auto">
                            {account?.active ? "Active" : "Not Active"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountInformation