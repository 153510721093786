import React, { useEffect, useState } from 'react'
import { FiCreditCard } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'flowbite-react';
import { Pagination } from "@mui/material";
import RightSidePanel from '../../components/RightSidePanel';
import AddRateCard from '../../layouts/partner/AddRateCard';
import { changeRateCardStatus, getPartnerRateCardDetails, rateCardQuery } from '../../store/actions/partnerAction';
import { getInfo } from '../../utils/types';
import moment from 'moment';
import SelectCountry from '../../components/Dropdowns/SelectCountry';
import ViewPartnerRateCard from '../../layouts/partner/ViewPartnerRateCard';

function ConversationRateCard() {
    const dispatch = useDispatch();
    const [rateCards, setRateCards] = useState([]);
    const [partnerRateCard, setPartnerRateCard] = useState();
    const [viewParterRateCard, setViewParterRateCard] = useState(false);
    const [selectedRateCard, setSelectedRateCard] = useState({});
    const [showAddRateCard, setShowAddRateCard] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [filter, setFilter] = useState({ criteria: {} });
    const globalReducer = useSelector((state) => state.globalReducer);
    const info = getInfo();
    const itemParPage = 10;

    useEffect(() => {
        onSearch();
    }, []);

    useEffect(() => {
        if (globalReducer.country?.length > 0) {
            dispatch(getPartnerRateCardDetails({ criteria: { partnerId: info.partnerId } }, a => {
                if (a && a.length > 0) {
                    const pRateCard = a?.map(m => {
                        const country = globalReducer.country.find(fi => fi.code === m.countryCode);
                        return { ...m, countryName: country?.name, currencyCode: country.currencyCode }
                    });
                    setPartnerRateCard({ rateCardName: pRateCard[0].rateCardName, applicableDate: pRateCard[0].applicableDate, createdAt: pRateCard[0].createdAt, countryName: pRateCard.filter(f => f.currency === "NATIVE")[0].countryName, rateCard: pRateCard });
                }
            }));
        }
    }, [globalReducer.country]);

    const onSearch = () => {
        dispatch(rateCardQuery({
            ...filter, criteria: { ...filter.criteria, partnerId: info?.partnerId },
            count: true,
            size: itemParPage,
            offset: (currentPage - 1) * itemParPage,
        }, a => {
            setRateCards(a?.data ?? []);
            setTotalPages(Math.ceil(a?.count / itemParPage));
        }));
    }

    return (<>
        <div className="fixed top-0 w-[82%] bg-white z-30">
            <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                    <FiCreditCard className='text-theme text-[14px]' />
                    <div className="text-[14px] font-medium">Configurations</div>
                </div>
                <div className="flex justify-center items-center">
                    <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                        onClick={() => { setSelectedRateCard(null); setShowAddRateCard(true); }}>New Conversation Rate Card
                    </div>
                </div>
            </div>
            <div className="bg-white text-black font-medium px-5 min-h-[40px] box-border border-b flex justify-between items-end">
                <div className="flex text-[14px] gap-6">
                    <button className={`pb-2 px-1 border-b-[2px] text-theme border-theme afterline`}>
                        Conversation Rate Card
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-10 gap-4 pl-8 pt-2">
                <div className={`flex flex-col col-span-2`}>
                    <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                        <label>Company Name</label>
                        <i className="fa-solid fa-circle-info px-2 text-xs"></i>
                    </div>
                    <input type="text" placeholder="Name" className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                        onChange={(e) => setFilter({ ...filter, filter: [{ property: "name", operator: "CONTAINS", value: e.target.value }] })} />
                </div>
                <div className={`flex flex-col col-span-2`}>
                    <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                        <label>Country</label>
                        <i className="fa-solid fa-circle-info px-2 text-xs"></i>
                    </div>
                    <SelectCountry countryList={globalReducer.country} onSelectCountry={(country) => {
                        if (country) {
                            filter.criteria.countryCode = country.code;
                        } else {
                            delete filter.criteria.countryCode;
                        }
                        setFilter({ ...filter })
                    }} />
                </div>
                <div className={`flex flex-col col-span-1`}>
                    <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                        <label>Status</label>
                        <i className="fa-solid fa-circle-info px-2 text-xs"></i>
                    </div>
                    <select className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                        onChange={(e) => { setFilter({ ...filter, criteria: { ...filter.criteria, active: e.target.value == "true" } }) }}>
                        <option value={true} selected>Active</option>
                        <option value={false}>Not Active</option>
                    </select>
                </div>
                <div className={`flex flex-col`}>
                    <button type="button" className="justify-center mt-7 items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                        onClick={onSearch}> Search
                    </button>
                </div>
            </div>
            <div className="h-full">
                <div className="px-5 mt-4 bg-white">
                    <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                        <thead className="text-[14px] text-midBlack">
                            <tr>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div> Rate Card Name</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div> Country Name</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div> Applicable Date</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div> Created Date </div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>Status</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>Action</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {partnerRateCard && <tr className="bg-gray-100 border-b">
                                <th scope="row" className="py-3 pl-2 font-medium text-[14px] hover:underline hover:text-theme cursor-pointer text-theme whitespace-nowrap"
                                    onClick={() => { setViewParterRateCard(true); }}>
                                    {partnerRateCard?.rateCardName ?? "------"}
                                </th>
                                <td className="py-3 font-normal text-[13px]">
                                    {partnerRateCard?.countryName ?? "------"}
                                </td>
                                <td className="py-3 font-normal text-[13px]">
                                    {moment(partnerRateCard?.applicableDate).format("YYYY-MM-DD") ?? "------"}
                                </td>
                                <td className="py-3 font-normal text-[13px]">
                                    {moment(partnerRateCard?.createdAt).format("YYYY-MM-DD hh:mm A") ?? "------"}
                                </td>
                                <td className="py-3 font-normal text-[13px]">
                                    <label className='bg-[#D4F8D3] p-2 rounded-lg'>Active</label>
                                </td>
                                <td className=" py-3 font-normal text-[13px]">
                                    <Dropdown inline arrowIcon={false} inputMode as="button"
                                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                        label={<i className="fa-solid fa-ellipsis"></i>} >
                                        <Dropdown.Item onClick={() => { setViewParterRateCard(true); }}>
                                            View
                                        </Dropdown.Item>
                                    </Dropdown>
                                </td>
                            </tr>}
                            {rateCards.map((p, i) => {
                                return (
                                    <tr className="bg-white border-b">
                                        <th scope="row" className="py-3 pl-2 font-medium text-[14px] hover:underline hover:text-theme cursor-pointer text-theme whitespace-nowrap"
                                            onClick={() => { setSelectedRateCard({ ...p, isEdit: true, isView: true }); setShowAddRateCard(true); }}>
                                            {p?.name ?? "------"}
                                        </th>
                                        <td className="py-3 font-normal text-[13px]">
                                            {p?.country?.name ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {moment(p?.applicableDate).format("YYYY-MM-DD") ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {moment(p?.createdAt).format("YYYY-MM-DD hh:mm A") ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {p?.active ? <label className='bg-[#D4F8D3] p-2 rounded-lg'>Active</label> : <label className='bg-[#F8F4D3] p-2 rounded-lg'>Not Active</label>}
                                        </td>
                                        <td className=" py-3 font-normal text-[13px]">
                                            <Dropdown inline arrowIcon={false} inputMode as="button"
                                                className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                                label={<i className="fa-solid fa-ellipsis"></i>} >
                                                {p?.active ? <Dropdown.Item onClick={() => { dispatch(changeRateCardStatus({ id: p?.id, isActive: false }, a => onSearch())) }}>
                                                    Deactivate
                                                </Dropdown.Item> : <Dropdown.Item onClick={() => { dispatch(changeRateCardStatus({ id: p?.id, isActive: true }, a => onSearch())) }}>
                                                    Activate
                                                </Dropdown.Item>}
                                                <Dropdown.Item onClick={() => { setSelectedRateCard({ ...p, isEdit: true }); setShowAddRateCard(true); }}>
                                                    Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setSelectedRateCard({ ...p, isEdit: true, duplicate: true }); setShowAddRateCard(true); }}>
                                                    Duplicate
                                                </Dropdown.Item>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {totalPages > 1 ? (
                        <div className="flex overflow-x-auto sm:justify-center bottom-0 mb-[5rem], mt-2">
                            <Pagination
                                page={currentPage}
                                count={totalPages}
                                onChange={(event, page) => setCurrentPage(page)}
                                shape="rounded"
                                color="secondary"
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
        <RightSidePanel IsCustomize={true}
            CustomClass="fixed md:w-[70%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
            isShow={showAddRateCard}
            setIsShow={setShowAddRateCard}
            Component={
                <AddRateCard setIsShow={setShowAddRateCard} onAdd={onSearch} isEdit={selectedRateCard?.isEdit} isView={selectedRateCard?.isView} duplicate={selectedRateCard?.duplicate} defaultRate={selectedRateCard} />
            }
        />

        <RightSidePanel
            CustomClass="fixed md:w-[70%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
            isShow={viewParterRateCard} ModalTitle="Conversation Rate Card"
            setIsShow={setViewParterRateCard}
            Component={
                <ViewPartnerRateCard setIsShow={setViewParterRateCard} selectedRateCard={partnerRateCard?.rateCard} />
            }
        />
    </>)
}

export default ConversationRateCard