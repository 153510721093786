import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { categoryQuery, tenantContactUpsert } from '../../store/actions/tenantAction';
import MobileInput from '../../components/MobileInput';
import { getInfo } from '../../utils/types';
import { Autocomplete, TextField } from '@mui/material';

function AddContact({ setIsShow, onAdd, isEdit = false, defaultContact = {}, onlyGetContact = false }) {
    let dispatch = useDispatch();
    const info = getInfo();
    const [Contact, setContact] = useState(defaultContact ?? {});
    const [categories, setCategories] = useState([]);
    const globalReducer = useSelector((state) => state.globalReducer);
    const tenantReducer = useSelector((state) => state.tenantReducer);
    useEffect(() => {
        dispatch(categoryQuery(
            {
                tenantId: info.tenantId,
                count: true,
                size: 100,
            },
            (d) => {
                setCategories(d?.data ?? []);
            }
        ));
        if (!isEdit) {
            const country = globalReducer.country.find(f => f.code === tenantReducer.tenantDetails.countryCode);
            if (country) {
                setContact({ ...Contact, phoneCode: country.phoneCode });
            }
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isEdit) {
            Contact.tenantId = info.tenantId;
            Contact.uid = info.uid;
        }
        Contact.whatsappNumber = Contact.phoneCode + Contact.phone;
        if (onlyGetContact) {
            onAdd(Contact);
            setIsShow(false);
            return;
        }
        dispatch(tenantContactUpsert(Contact, (a) => {
            setIsShow(false);
            onAdd(a);
        }))
    }

    const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
    return (
        <form className="mt-3 max-h-[550px] overflow-auto px-3" onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-3 w-full">
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label> Customer Id</label>
                    </div>
                    <input type="text" placeholder="Enter customer id" name="name"
                        value={Contact?.customerId}
                        onChange={(e) => setContact({ ...Contact, customerId: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 w-[100%]" />
                </div>
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label> Name</label>
                    </div>
                    <input type="text" placeholder="Enter Name" name="name"
                        value={Contact?.name}
                        onChange={(e) => setContact({ ...Contact, name: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 w-[100%]" />
                </div>
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label> Gender</label>
                    </div>
                    <select value={Contact?.gender} className="border w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
                        onChange={(e) => {
                            setContact({ ...Contact, gender: e.target.value })
                        }}>
                        <option value="" selected disabled>
                            Select Gender.
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Phone Number</label>
                    </div>
                    <MobileInput className={"py-1 h-10 text-[16px]"} required={true} countryList={globalReducer.country} phoneWithCode={Contact?.whatsappNumber} phoneCode={Contact?.phoneCode} phone={Contact?.phone} setPhoneCode={(code) => setContact({ ...Contact, phoneCode: code })} setPhone={(phone) => setContact({ ...Contact, phone: phone })} />
                </div>
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Email</label>
                    </div>
                    <input type="email" placeholder="Enter Email" name="email" value={Contact.email}
                        onChange={(e) => setContact({ ...Contact, email: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 w-[100%]" />
                </div>
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Location</label>
                    </div>
                    <input type="text" placeholder="Enter location" name="location" value={Contact.location}
                        onChange={(e) => setContact({ ...Contact, location: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 w-[100%]" />
                </div>
                <div className="col-span-12">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Category</label>
                    </div>
                    <Autocomplete multiple={true} aria-required={true} value={categories?.filter(m => Contact.category?.some(s => s === m.categoryName))} options={categories} isOptionEqualToValue={(option, value) => option?.categoryName === value?.categoryName}
                        getOptionLabel={(option) => option?.categoryName ?? ""}
                        onChange={(e, value) => { setContact({ ...Contact, categories: value, category: value?.map(c => c.categoryName) ?? [] }) }}
                        sx={{
                            '& .MuiAutocomplete-inputRoot': {
                                padding: '0px',
                            }
                        }}
                        renderInput={(params) => (<TextField {...params} placeholder="Select Category" className="custom-input"
                            sx={{
                                '& .MuiInputBase-root': {
                                    padding: "0px",
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'none',
                                        border: '0px',
                                    },
                                    fontSize: "14px",
                                },
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: '#FFFFFF',
                                    "& fieldset": {
                                        borderColor: '#ccc'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#888',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#1976d2',
                                    }
                                }
                            }}
                        />)} />
                </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
                <button type='button'
                    className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                    onClick={() => setIsShow(false)}>
                    Cancel
                </button>
                <button type='submit'
                    className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                    Save
                </button>
            </div>
        </form>
    )
}

export default AddContact