import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField } from "@mui/material";

function SelectCountry({ countryList, onSelectCountry, selectedCode, required = false }) {
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        setSelectedValue({ ...countryList?.find(f => f.code === selectedCode) })
    }, [countryList, selectedCode])

    return (<div>
        <Autocomplete value={selectedValue} options={countryList} isOptionEqualToValue={(option, value) => option?.code === value?.code}
            getOptionLabel={(option) => option?.name ?? ""}
            onChange={(e, value) => { setSelectedValue(value); onSelectCountry(value); }}
            sx={{
                '& .MuiAutocomplete-inputRoot': {
                    padding: '0px',
                }
            }}
            renderInput={(params) => (<TextField {...params} required={required} placeholder="Select Country" className="custom-input"
                sx={{
                    '& .MuiInputBase-root': {
                        padding: "0px",
                        '&.Mui-focused fieldset': {
                            borderColor: 'none',
                            border: '0px',
                        },
                        fontSize: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                        backgroundColor: '#FFFFFF',
                        "& fieldset": {
                            borderColor: '#ccc'
                        },
                        '&:hover fieldset': {
                            borderColor: '#888',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#1976d2',
                        }
                    }
                }}
            />)} />
    </div>)
}

export default SelectCountry