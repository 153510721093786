import React, { useEffect } from "react";
import { IS_SHOWOPACITY } from "../utils/types";
import { useDispatch } from "react-redux";

function RightSidePanel({
  isShow,
  setIsShow,
  Component,
  ModalTitle,
  IsCustomize,
  ZIndex = "40",
  CustomClass = null,
  CustomClassDiv = null,
  downloadFile = null,
  fileUrl = null
}) {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: IS_SHOWOPACITY, payload: isShow });
    const handleBodyOverflow = () => {
      document.body.style.overflow = isShow ? "hidden" : "auto";
    };
    handleBodyOverflow();
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isShow]);
  return (
    <div
      className={
        (CustomClass
          ? CustomClass
          : "fixed md:w-[600px] min-[320px]:w-full top-[29px] right-0 z-[" +
          ZIndex +
          "] h-screen py-4 min-[600px]:px-[36px] min-[320px]:px-4 overflow-y-auto transition-transform bg-white w-80") +
        (isShow ? " transform-none" : " translate-x-full")
      }
      tabIndex={-1}
      aria-labelledby="drawer-right-label"
    >
      {isShow ? (
        <div
          className={
            CustomClassDiv ? CustomClassDiv : "height0 flex flex-col pb-[0px]"
          }
        >
          {IsCustomize ? (
            Component
          ) : (
            <>
              <div className="h-full relative">
                {ModalTitle ? (
                  <div className="py-2.5 border-b-[1px] sticky top-0 w-full text-[16px] flex items-center justify-between  border-[#F0F1F3]">
                    <span className="text-[14px] font-medium text-black">
                      {ModalTitle}
                    </span>
                    <div className="flex items-center gap-4">
                      {downloadFile && fileUrl ? (
                        <a download={downloadFile} href={fileUrl}
                          className="min-w-[76px] text-center px-[16px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline">
                          Download Template
                        </a>
                      ) : (
                        ""
                      )}
                      <button
                        onClick={() => {
                          setIsShow(false);
                        }}
                        type="button"
                        className="text-gray-400 z-50 bg-transparent hover:bg-gray-200 hover:text-black rounded text-sm w-8 h-8 inline-flex items-center justify-center"
                      >
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close menu</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {Component}
                {/* {IsComponentVisible ? Component : <div></div>} */}
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default RightSidePanel;

