import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ROUTES from "../utils/routesConstants";
import { getUUid, sendOTP, verifyUser, generateToken } from "../store/actions/loginAction";
import { getInfo, SHOW_SNACKBAR } from "../utils/types";

function Login() {
  const dispatch = useDispatch();
  const authInfo = getInfo();
  const [uuid, setUuid] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [verifyType, setVerifyType] = useState("PHONE");
  const [formData, setFormData] = useState({});
  const [isShowPassword, setIsShowPassword] = useState(true);
  const [isOTP, setIsOTP] = useState(true);
  const [tenantId, setTenantId] = useState();
  const [config, setConfig] = useState({});
  const { clientId } = useParams();
  const navigate = useNavigate();

  const authorizeRoute = () => {
    if (!(authInfo && authInfo.roles && Array.isArray(authInfo.roles) && authInfo.roles?.length > 0)) {
      return false;
    }
    if (!authInfo.partnerId && !authInfo.tenantId) {
      return false;
    }
    return true;
  };
  const fetchIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      setIpAddress(data?.ip);
    } catch (error) {
      console.error("Error fetching IP:", error);
    }
  };
  useEffect(() => {
    fetchIP();
    if (authorizeRoute()) {
      if (authInfo.roles.some(s => s === "GLOBAL_ADMIN")) {
        navigate(ROUTES.GLOBAL_ADMIN);
      } else if (authInfo.roles.some(s => s === "SUPER_ADMIN")) {
        navigate(ROUTES.PARTNER);
      }
      else {
        navigate(ROUTES.TENANT);
      }
    } else {
      if (clientId?.toLowerCase() === "partner") {
        setConfig(JSON.parse(process.env.REACT_APP_PARTNER_PORTAL_CONFIG));
      } else if (clientId) {
        setConfig(JSON.parse(process.env.REACT_APP_TENANT_PORTAL_CONFIG));
        setTenantId(clientId.toUpperCase());
      }
    }
  }, []);

  const gotoSignUp = () => {
    navigate(ROUTES.SIGNUP);
  };

  const sendOtp = () => {
    dispatch(
      getUUid(config, (d) => {
        setUuid(d);
        if (verifyType !== "EMAIL" && formData?.mobile?.length === 10) {
          dispatch(
            sendOTP(
              {
                tenantId: tenantId,
                address: formData?.mobile,
                addressType: verifyType,
              },
              { uuid: d },
              (d) => {
                setIsOTP(d);
              }
            )
          );
        } else if (verifyType === "EMAIL") {
          dispatch(
            sendOTP(
              {
                tenantId: tenantId,
                address: formData?.email,
                addressType: verifyType,
              },
              { uuid: d },
              (d) => {
                setIsOTP(d);
              }
            )
          );
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "error",
              message: "Please Enter a valid mobile number.",
              open: true,
            },
          });
        }
      })
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const verifyParams = { uuid: uuid };
    if (formData?.otp) {
      dispatch(verifyUser(
        {
          tenantId: tenantId,
          address: verifyType === "EMAIL" ? formData?.email : formData?.mobile,
          secret: verifyType === "PHONE" ? formData?.otp : formData?.otp,
          addressType: verifyType,
        },
        verifyParams,
        "OTP", (code) => {
          const tokenParams = { ...config, code: code, tenantId: tenantId, client_ip: ipAddress };
          dispatch(generateToken({}, tokenParams, (d) => {
            localStorage.setItem("sessionToken", d?.access_token);
            localStorage.setItem("refreshToken", d?.refresh_token);

            const [header, payload, signature] = d?.access_token?.split(".");
            const decodedPayload = JSON.parse(atob(payload));
            const InfoParse = JSON.parse(atob(decodedPayload?.info));
            if (InfoParse && InfoParse.roles && Array.isArray(InfoParse.roles)) {
              if (InfoParse.roles.some(s => s === "GLOBAL_ADMIN")) {
                window.location.replace(config.redirect_uri.replace("partner", "admin"));
              }
              else {
                window.location.replace(config.redirect_uri);
              }
            }
          }));
        }));
    }
  };

  return (
    <div className="flex flex-col h-screen bg-slate-100 max-md:px-5 p-4">
      <div className="flex justify-between">
        <div>
          <img
            alt=""
            loading="lazy"
            src={`${process.env.PUBLIC_URL}/iomnihub-logo.svg`}
            className=" w-40 max-w-full aspect-[3.85] max-md:mt-10"
          />
        </div>
        <div className="flex gap-5 items-center px-5 text-sm ">
          <div className="grow my-auto text-black font-[500] leading-[20px]">
            New To our Product ?
          </div>
          <div
            className="justify-center cursor-pointer items-start px-[12px] py-[8px] font-medium tracking-wide text-white whitespace-nowrap bg-theme rounded shadow-lg leading-[100%]"
            onClick={gotoSignUp}>
            Sign Up
          </div>
        </div>
      </div>
      <div className="flex flex-col min-h-[88vh] justify-center items-center bg-slate-100 max-md:px-5">
        <div className="flex justify-center items-center">
          <form onSubmit={onSubmit} action="POST">
            <div className="flex flex-col gap-2 p-[37px] bg-white rounded-[16px] shadow-input max-w-[750px] max-md:px-5 w-[450px]">
              <div className="justify-center items-center pb-[32px] text-[24px] font-[600] text-center text-black max-md:px-5 max-md:max-w-full tracking-[-0.02em] leading-[28px]">
                Login
              </div>
              {verifyType === "EMAIL" && (
                <div>
                  <div className="flex flex-col gap-2">
                    <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em]">
                      Email
                    </div>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      type="Email"
                      id="email"
                      placeholder="Email"
                      className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                      autoComplete="NA"
                    />
                  </div>
                  {isOTP ? (
                    <button
                      type="submit"
                      className="justify-center cursor-pointer items-center p-[12px]  mt-9 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
                      onClick={sendOtp}>
                      Generate OTP
                    </button>
                  ) : (
                    <>
                      <div className="flex flex-col gap-2">
                        <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em] mt-[20px]">
                          OTP
                        </div>
                        <div className="relative hidden-field mt-2">
                          <div className="absolute inset-y-0 right-0 flex items-center justify-center px-2">
                            {isShowPassword ? (
                              <img
                                alt=""
                                loading="lazy"
                                src={`${process.env.PUBLIC_URL}/images/eyeOff.svg`}
                                className="w-4 aspect-square cursor-pointer"
                                onClick={() =>
                                  setIsShowPassword(!isShowPassword)
                                }
                              />
                            ) : (
                              <img
                                alt=""
                                loading="lazy"
                                src={`${process.env.PUBLIC_URL}/images/eyeOn.svg`}
                                className="w-4 aspect-square cursor-pointer"
                                onClick={() =>
                                  setIsShowPassword(!isShowPassword)
                                }
                              />
                            )}
                          </div>

                          <input
                            className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                            id="password"
                            placeholder="•••• "
                            //   value={OTP}
                            type={isShowPassword ? "password" : "number"}
                            data-maxlength={4}
                            onChange={(e) =>
                              setFormData({ ...formData, otp: e.target.value })
                            }
                            autoComplete="off"
                            onInput={(e) => {
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.dataset.maxlength
                              );
                            }}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="justify-center cursor-pointer items-center p-[12px]  mt-9 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
                      >
                        Submit
                      </button>
                    </>
                  )}
                  <div className="mt-6 text-center text-gray-500">Or</div>
                  <div
                    className="flex justify-center cursor-pointer items-center px-16 py-3 mt-6 text-base leading-6 text-violet-600 bg-white rounded border border-violet-600 border-solid max-md:px-5"
                    onClick={() =>
                      setVerifyType(verifyType === "PHONE" ? "EMAIL" : "PHONE")
                    }
                  >
                    <div className="flex gap-2.5 justify-center">
                      <img
                        alt=""
                        loading="lazy"
                        src={`${process.env.PUBLIC_URL}/images/cusemail.svg`}
                        className="shrink-0 w-5 aspect-square"
                      />
                      <div>Login with Mobile</div>
                    </div>
                  </div>
                </div>
              )}
              {verifyType === "PHONE" && (
                <div>
                  <div className="flex flex-col gap-2">
                    <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em]">
                      Phone Number
                    </div>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, mobile: e.target.value })
                      }
                      type="phone"
                      id="email"
                      data-maxlength={10}
                      placeholder="Mobile"
                      className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                      autoComplete="NA"
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.dataset.maxlength
                        );
                      }}
                    />
                  </div>
                  {isOTP ? (
                    <button
                      type="submit"
                      className="justify-center cursor-pointer items-center p-[12px]  mt-9 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
                      onClick={sendOtp}
                    >
                      Generate OTP
                    </button>
                  ) : (
                    <>
                      <div className="flex flex-col gap-2">
                        <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em] mt-[20px]">
                          OTP
                        </div>
                        <div className="relative hidden-field">
                          <div className="absolute inset-y-0 right-0 flex items-center justify-center px-2">
                            {isShowPassword ? (
                              <img
                                alt=""
                                loading="lazy"
                                src={`${process.env.PUBLIC_URL}/images/eyeOff.svg`}
                                className="w-4 aspect-square cursor-pointer"
                                onClick={() =>
                                  setIsShowPassword(!isShowPassword)
                                }
                              />
                            ) : (
                              <img
                                alt=""
                                loading="lazy"
                                src={`${process.env.PUBLIC_URL}/images/eyeOn.svg`}
                                className="w-4 aspect-square cursor-pointer"
                                onClick={() =>
                                  setIsShowPassword(!isShowPassword)
                                }
                              />
                            )}
                          </div>

                          <input
                            className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                            id="password"
                            placeholder="•••••• "
                            //   value={OTP}
                            type={isShowPassword ? "password" : "number"}
                            data-maxlength={6}
                            onChange={(e) =>
                              setFormData({ ...formData, otp: e.target.value })
                            }
                            autoComplete="off"
                            onInput={(e) => {
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.dataset.maxlength
                              );
                            }}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="justify-center cursor-pointer items-center p-[12px]  mt-9 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
                      >
                        Submit
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login