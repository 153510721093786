import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userUpsert } from "../store/actions/adminAction";
import { getInfo } from '../utils/types';
import MobileInput from '../components/MobileInput';
import Password from '../components/Password';

function AddPartnerUser({ setIsShow, onAdd, isEdit = false, defaultUser = {}, partnerId, isSuperAdmin = false }) {
  let dispatch = useDispatch();
  const partnerInfo = getInfo();
  const [user, setUser] = useState(defaultUser ?? {});
  const [showRole, setShowRole] = useState(partnerInfo.roles.some(s => s === "SUPER_ADMIN") || isSuperAdmin);
  const globalReducer = useSelector((state) => state.globalReducer);
  const [passwordError, setPasswordError] = useState();
  const partnerReducer = useSelector((state) => state.partnerReducer);

  useEffect(() => {
    if (!isEdit) {
      const country = globalReducer.country.find(f => f.code === partnerReducer.partnerDetails.countryCode);
      if (country) {
        setUser({ ...user, phoneCode: country.phoneCode });
      }
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!showRole) {
      if (partnerInfo.roles.some(s => s === "GLOBAL_ADMIN")) {
        user.roles = ["GLOBAL_ADMIN"];
      }
    }
    if (!isEdit) {
      user.partnerId = partnerId
    }
    dispatch(userUpsert(user, (a) => {
      setIsShow(false);
      onAdd(a);
    }))
  }

  const passwordMessage = (password, confirmPassword) => {
    var msg = "";
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        msg = "password and confirm password should be same";
      }
      if (password.length < 6 || confirmPassword.length < 6) {
        msg = msg ? msg + ", password should be minimum 6 characters" : "password should be minimum 6 characters";
      }
    }
    setPasswordError(msg);
  }

  const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
  return (
    <form className="mt-3 max-h-[550px] overflow-auto px-3" onSubmit={handleSubmit}>
      <div className="grid grid-cols-12 gap-3 w-full">
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal">
            <label> Name</label>
          </div>
          <input type="text" placeholder="Enter Name" name="name"
            value={user?.name}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            className="border border-gray-300 rounded-md py-2 w-[100%]" required />
        </div>
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal">
            <label>Phone Number</label>
          </div>
          <MobileInput className={"py-2 text-[16px]"} required={true} countryList={globalReducer.country} phoneCode={user.phoneCode} phone={user.phone} setPhoneCode={(code) => setUser({ ...user, phoneCode: code })} setPhone={(phone) => setUser({ ...user, phone: phone })} />
        </div>
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal">
            <label>Email</label>
          </div>
          <input
            type="email"
            placeholder="Enter Email"
            name="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            className="border border-gray-300 rounded-md py-2 w-[100%]"
            required
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal">
            <label>Password</label>
          </div>
          <Password size='medium' isError={passwordError ? true : false} onChange={(a) => {
            passwordMessage(a, user.confirmPassword);
            setUser({ ...user, password: a });
          }} value={user.password} required={true} />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal">
            <label>Confirm Password</label>
          </div>
          <Password size='medium' isError={passwordError ? true : false} onChange={(a) => {
            passwordMessage(user.password, a);
            setUser({ ...user, confirmPassword: a });
          }} value={user.confirmPassword} required={true} />
        </div>
        <div className="col-span-12 h-1 mt-[-15px]">
          {passwordError && <label className='text-[12px] text-[#f10808]'>{passwordError}</label>}
        </div>
        {showRole && <div className="col-span-12">
          <div className="text-midBlack text-[13px] font-normal">
            <label>Role</label>
          </div>
          <select name='roles' defaultValue={isEdit ? user?.roles[0] ?? "" : ""} className="border h-10 w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
            onChange={(e) => {
              setUser({ ...user, roles: [e.target.value] })
            }}>
            <option value="" selected disabled>
              Select role...
            </option>
            <option value="SUPER_ADMIN">Super Admin</option>
            <option value="GENERAL">General</option>
          </select>
        </div>}
        <div className="col-span-4 item-end mt-[2px]">
          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" onChange={(e) => {
              setUser({ ...user, active: e.target.checked === true })
            }} checked={user?.active} className="sr-only peer" />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-theme"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
          </label>
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-[-15px]">
        <button type='button'
          className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
          onClick={() => setIsShow(false)}>
          Cancel
        </button>
        <button type='submit'
          className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
          Save
        </button>
      </div>
    </form>
  )
}

export default AddPartnerUser