import React, { useEffect, useState } from 'react'
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import RightSidePanel from "../../components/RightSidePanel";
import PopUp from "../../components/PopUp";
import AddPartner from '../../layouts/globalAdmin/AddPartner';
import PartnerDetails from '../../layouts/globalAdmin/PartnerDetails';
import { getPartners, changeStatus } from "../../store/actions/adminAction";
import { useDispatch, useSelector } from 'react-redux';
import AddPartnerUser from '../../layouts/AddPartnerUser';
import SelectCountry from '../../components/Dropdowns/SelectCountry';
import MapPartnerRateCard from '../../layouts/globalAdmin/MapPartnerRateCard';

function ManagePatner() {
  const dispatch = useDispatch();
  const [showAddPartner, setShowAddPartner] = useState(false);
  const [showAddPartnerUser, setShowAddPartnerUser] = useState(false);
  const [showPartnerDtails, setShowPartnerDetails] = useState(false);
  const [viewMapRateCard, setViewMapRateCard] = useState(false);
  const [Partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({ criteria: {} });
  const globalReducer = useSelector((state) => state.globalReducer);
  const itemParPage = 10;

  useEffect(() => {
    partnerSearch();
  }, [currentPage])

  const partnerSearch = () => {
    dispatch(getPartners(
      {
        ...filter,
        count: true,
        size: itemParPage,
        offset: (currentPage - 1) * itemParPage,
      },
      (d) => {
        setPartners(d?.data ?? []);
        setTotalPages(Math.ceil(d?.count / itemParPage));
      }
    ));
  };

  const onSearch = () => {
    setCurrentPage(1);
    partnerSearch();
  }

  return (<div>
    <div className="fixed top-0 w-[82%] bg-white z-30 ">
      <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
        <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
          <i className="fa-regular text-[14px] fa fa-handshake-o text-theme"></i>
          <div className="text-[14px] font-medium">Manage Partner</div>
        </div>
        <div className="flex gap-2">
          <div
            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
            onClick={() => { setSelectedPartner({}); setShowAddPartner(true) }}>Add Partner
          </div>
        </div>
      </div>
      <div className="bg-white text-black font-medium px-5 min-h-[50.4px] box-border border-b flex justify-between items-end">
        <div className="flex">
          <button className="border-b-[2px] text-[16px] text-black px-1 pb-2 border-theme afterline">
            Manage Partner
          </button>
        </div>
      </div>
    </div>
    <div className="grid grid-cols-10 gap-4 pl-8 pt-2">
      <div className={`flex flex-col col-span-2`}>
        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
          <label>Partner Name</label>
          <i className="fa-solid fa-circle-info px-2 text-xs"></i>
        </div>
        <input type="text" placeholder="Name" className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
          onChange={(e) => setFilter({ ...filter, filter: [{ property: "name", operator: "CONTAINS", value: e.target.value }] })} />
      </div>
      <div className={`flex flex-col col-span-2`}>
        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
          <label>Country</label>
          <i className="fa-solid fa-circle-info px-2 text-xs"></i>
        </div>
        <SelectCountry countryList={globalReducer.country} onSelectCountry={(country) => {
          if (country) {
            filter.criteria.countryCode = country.code;
          } else {
            delete filter.criteria.countryCode;
          }
          setFilter({ ...filter })
        }} />
      </div>
      <div className={`flex flex-col col-span-2`}>
        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
          <label>Status</label>
          <i className="fa-solid fa-circle-info px-2 text-xs"></i>
        </div>
        <select className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
          onChange={(e) => { setFilter({ ...filter, criteria: { ...filter.criteria, active: e.target.value == "true" } }) }}>
          <option value={true} selected>Active</option>
          <option value={false}>Not Active</option>
        </select>
      </div>
      <div className={`flex flex-col`}>
        <button type="button" className="justify-center mt-7 items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
          onClick={onSearch}> Search
        </button>
      </div>
    </div>
    {Partners && Partners?.length > 0 ? (
      <div className="h-full">
        <div className="px-5 mt-2 bg-white">
          <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
            <thead className="text-[14px] text-midBlack">
              <tr>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div> Partner Id</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div> Partner Name</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div> Country</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div>Status</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div>Action</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {Partners.map((p, i) => {
                return (
                  <tr className="bg-white border-b">
                    <td className="py-3 font-normal text-[13px]">
                      {p?.id ?? "------"}
                    </td>
                    <th
                      scope="row"
                      className=" py-3 font-medium text-[14px] hover:underline hover:text-theme cursor-pointer text-theme whitespace-nowrap"
                      onClick={() => { setSelectedPartner(p); setShowPartnerDetails(true); }}>
                      {p?.name ?? "------"}
                    </th>
                    <td className="py-3 font-normal text-[13px]">
                      {p?.country?.name ?? "------"}
                    </td>
                    <td className="py-3 font-normal text-[13px]">
                      {p?.active ? <label className='bg-[#D4F8D3] p-2 rounded-lg'>Active</label> : <label className='bg-[#F8F4D3] p-2 rounded-lg'>Not Active</label>}
                    </td>
                    <td className=" py-3 font-normal text-[13px]">
                      <Dropdown
                        inline
                        arrowIcon={false}
                        inputMode
                        as="button"
                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                        label={<i className="fa-solid fa-ellipsis"></i>}
                      >
                        {p?.active ? <Dropdown.Item onClick={() => { dispatch(changeStatus({ id: p?.id, isActive: false }, a => partnerSearch())) }}>
                          Deactivate
                        </Dropdown.Item> : <Dropdown.Item onClick={() => { dispatch(changeStatus({ id: p?.id, isActive: true }, a => partnerSearch())) }}>
                          Activate
                        </Dropdown.Item>}
                        <Dropdown.Item onClick={() => { setSelectedPartner({ ...p, isEdit: true }); setShowAddPartner(true); }}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => { setSelectedPartner(p); setShowAddPartnerUser(true); }}>
                          Add User
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => { setSelectedPartner(p); setViewMapRateCard(true); }}>
                          Map Rate Card
                        </Dropdown.Item>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {totalPages > 1 ? (
            <div className="flex overflow-x-auto sm:justify-center bottom-0 mb-[5rem], mt-2">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(event, page) => setCurrentPage(page)}
                shape="rounded"
                color="secondary"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    ) : (
      <>
        <div className="m-[4rem] text-center flex flex-col items-center gap-2">
          <img
            src={`${process.env.PUBLIC_URL}/images/noList.png`}
            alt="No List"
            className="w-[486px] h-[278px] "
          />
          <p className="my-[24px] ">
            The space is empty . Add a Partner to get started.
          </p>
          <div className="flex gap-2.5 justify-center text-base font-medium">
            <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
              <div className="flex gap-2" onClick={() => setShowAddPartner(true)}>
                <div className="">
                  <span className="mr-2">
                    <i className="fa-solid fa-plus"></i>
                  </span>
                  Add Partner
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )}

    <RightSidePanel
      CustomClass="fixed md:w-[50%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
      isShow={showPartnerDtails}
      setIsShow={setShowPartnerDetails}
      ModalTitle="Partner Details"
      Component={
        <PartnerDetails setIsShow={setShowAddPartner} partnerId={selectedPartner.id} />
      }
    />
    <PopUp
      ModalHeader={
        <span className="font-medium text-[18px] text-black">
          Add Partner
        </span>
      }
      isShowModal={showAddPartner}
      setIsShowModal={setShowAddPartner}
      ModalBody={
        <AddPartner setIsShow={setShowAddPartner} onAdd={partnerSearch} isEdit={selectedPartner.isEdit} defaultPartner={selectedPartner} />
      }
      size="2xl"
    />
    <PopUp
      ModalHeader={
        <span className="font-medium text-[18px] text-black">
          Add User
        </span>
      }
      isShowModal={showAddPartnerUser}
      setIsShowModal={setShowAddPartnerUser}
      ModalBody={
        <AddPartnerUser isSuperAdmin={true} setIsShow={setShowAddPartnerUser} onAdd={() => { }} partnerId={selectedPartner.id} />
      }
      size="2xl"
    />
    <RightSidePanel
      CustomClass="fixed md:w-[60%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
      isShow={viewMapRateCard}
      setIsShow={setViewMapRateCard}
      ModalTitle="Map Rate Card"
      Component={
        <MapPartnerRateCard setIsShow={setViewMapRateCard} partner={selectedPartner} onAdd={() => { }} />
      }
    />
  </div>
  )
}

export default ManagePatner