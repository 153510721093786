import React from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "../utils/routesConstants";
import { getInfo } from "../utils/types";

const NotFound = () => {
  const InfoParse = getInfo();
  const navigate = useNavigate();

  const gotoHome = () => {
    if (InfoParse && InfoParse.roles && Array.isArray(InfoParse.roles)) {
      if (InfoParse.roles.some(s => s === "GLOBAL_ADMIN")) {
        navigate(ROUTES.GLOBAL_ADMIN);
      } else if (InfoParse.roles.some(s => s === "SUPER_ADMIN")) {
        navigate(ROUTES.PARTNER);
      }
      else {
        navigate(ROUTES.TENANT);
      }
    }
  }

  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme">
            404
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-medium text-black md:text-4xl">
            Something's missing.
          </p>
          <p className="mb-4 text-lg font-light text-gray-500">
            Sorry, we can't find that page. You'll find lots to explore on the
            home page.{" "}
          </p>
          <button
            className="inline-flex text-white bg-theme hover:bg-theme focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded text-sm px-5 py-2.5 text-center my-4"
            onClick={gotoHome}
          >
            Back to Homepage
          </button>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
