import { combineReducers } from "redux";
import { COUNTRY } from "../../utils/types";

const initialState = {
  country: []
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNTRY: {
      return {
        ...state,
        country: action.payload,
      };
    }
    default:
      return state;
  }
};

export default globalReducer