import React, { useEffect, useState } from 'react'
import { getInfo } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { DatePicker } from "antd";
import { getMessageType } from '../../store/actions/partnerAction';
const { RangePicker } = DatePicker;

function AllConversations() {
    const info = getInfo();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({ criteria: { partnerId: info.partnerId } });
    const [conversations, setConversations] = useState([]);
    const [messageTypes, setMessageTypes] = useState([]);

    useEffect(() => {
        dispatch(getMessageType(setMessageTypes));
        Usersearch();
    }, [])

    const Usersearch = () => {

    };

    const onSearch = () => {
        Usersearch();
    }

    const selectHolidayDate = (dates) => {
        const [start, end] = dates;
        // filter([
        //     moment(start).format("YYYY-MM-DD"),
        //     moment(end).format("YYYY-MM-DD"),
        // ]);
    };

    return (<div>
        <div className="fixed top-0 w-[82%] bg-white z-30 ">
            <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                    <i className="fa-regular fa-comments text-[14px] text-theme"></i>
                    <div className="text-[14px] font-medium">All Conversations</div>
                </div>
                <div className="flex gap-2">

                </div>
            </div>
            <div className="bg-white text-black font-medium px-5 min-h-[70px] box-border border-b">
                <div className="flex py-2 gap-3">
                    <div className={`flex flex-col col-span-2`}>
                        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                            <label>All Phone number</label>
                        </div>
                        <select className="border border-gray-300 min-w-[200px] rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                            onChange={(e) => { setFilter({ ...filter, criteria: { ...filter.criteria, active: e.target.value == "true" } }) }}>
                            <option value={true} selected>Active</option>
                            <option value={false}>Not Active</option>
                        </select>
                    </div>
                    <div className={`flex flex-col col-span-2`}>
                        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                            <label>All Country</label>
                        </div>
                        <select className="border border-gray-300 min-w-[200px] rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                            onChange={(e) => { setFilter({ ...filter, criteria: { ...filter.criteria, active: e.target.value == "true" } }) }}>
                            <option value={true} selected>Active</option>
                            <option value={false}>Not Active</option>
                        </select>
                    </div>
                    <div className={`flex flex-col col-span-2`}>
                        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                            <label>Date Range</label>
                        </div>
                        <RangePicker className={"h-10"} onChange={selectHolidayDate} format="YYYY-MM-DD" />
                    </div>

                    <div className={`flex flex-col`}>
                        <button type="button" className="justify-center mt-7 items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                            onClick={onSearch}> Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-3 gap-4 px-3 pt-10">
            <div className='bg-gray-50 p-3 text-[14px]'>
                <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                    <thead className="text-[14px] text-midBlack">
                        <tr>
                            <th scope="col" className="pb-1 font-[500] font-bold">
                                <div> All Conversations</div>
                            </th>
                            <th scope="col" className="pb-1 font-[500] font-bold">
                                <div> 0</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {messageTypes.map((m, i) => {
                            return (
                                <tr key={i}>
                                    <td className="py-3 text-[13px]">{m.messageType}</td>
                                    <td className="py-3 text-[13px]">0</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>

    </div>)
}

export default AllConversations