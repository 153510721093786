import React, { useEffect, useState } from 'react'
import { getToken, removeToken, getInfo } from '../utils/types';
import ROUTES from '../utils/routesConstants';
import PartnerLeftPanel from '../layouts/partner/PartnerLeftPanel';
import TenantLeftPanel from '../layouts/tenant/TenantLeftPanel';
import PopUp from '../components/PopUp';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCountry } from '../store/actions/globalAction';

function PrivateRouteAdmin({ category }) {
  const auth = getToken();
  let dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { tabName, subtabName = null } = useParams();
  const [logoutPopup, setLogoutPopup] = useState(false);
  const loginInfo = getInfo();
  const globalReducer = useSelector((state) => state.globalReducer);

  const authorizeRoute = () => {
    if (auth) {
      if (!(loginInfo && loginInfo.roles && Array.isArray(loginInfo.roles) && loginInfo.roles?.length > 0)) {
        return false;
      }
      if (!loginInfo.partnerId && !loginInfo.tenantId) {
        return false;
      }
      return true;
    }
  };

  useEffect(() => {
    if (!globalReducer.country || globalReducer.country?.length <= 0) {
      dispatch(getCountry({}, c => { }));
    }
  }, [])

  useEffect(() => {
    if (!auth) {
      navigate(ROUTES.LOGIN);
    }
    if (!authorizeRoute()) {
      navigate(ROUTES.UN_AUTH);
    }
  }, [tabName]);

  const logOut = () => {
    removeToken();
    navigate(ROUTES.LOGIN);
  };

  return auth ? (<>
    <div>
      <header className="flex fixed top-0 bg-[#4078AD] w-[100%] z-[99999] justify-between h-[30px] animate__animated animate__fadeInDown animate__faster">
        <div className="pl-5 pt-[6px]">
          <img src={`${process.env.PUBLIC_URL}/iomnihub-logo.svg`} width={80} height={80} alt="Logo" />
        </div>
        <div className="flex justify-center items-center one">
          <i
            className="fas fa-sign-out-alt text-white pr-5 cursor-pointer"
            onClick={() => setLogoutPopup(true)}
          ></i>
        </div>
      </header>
      <div className="container-fluid page-body-wrapper pt-0 mt-[30px]">
        {(category === "PARTNER" || category === "GLOBAL_ADMIN" ? <PartnerLeftPanel category={category} tabName={tabName} subtabName={subtabName} /> : <TenantLeftPanel tabName={tabName} subtabName={subtabName} />)}
      </div>
    </div>
    <PopUp
      ModalHeader={
        <>
          <div className="font-medium text-[18px] text-black">
            Are you want to quit?
          </div>
          <div className="font-medium text-[14px] text-[#6E6C6D]">
            Any unsaved changes will be lost.
          </div>
        </>
      }
      isShowModal={logoutPopup}
      setIsShowModal={setLogoutPopup}
      ModalBody={
        <>
          <div className="flex justify-end gap-4 pt-10 px-0">
            <button
              className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
              onClick={() => setLogoutPopup(false)}
            > Cancel</button>
            <button
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
              onClick={() => logOut()}
            >Logout</button>
          </div>
        </>
      }
      size="md"
    />
  </>
  ) : (
    <Navigate to="/" replace />
  )
}

export default PrivateRouteAdmin