import React, { useEffect, useState } from 'react'
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { whatsAppDoc } from "../../apidoc/whatsapp";
import { checkAndGenerateApiKey, updateApiKey } from '../../store/actions/tenantAction';
import { getInfo } from '../../utils/types';
import PopUp from '../../components/PopUp';
import { useDispatch } from 'react-redux';
import { getRandomString } from '../../utils/helper';
import CustomizedTooltips from '../../components/Tooltip';


function APIDocs() {
    const dispatch = useDispatch();
    const info = getInfo();
    const [docType, setDocType] = useState("WHATSAPP");
    const [apiKey, setApiKey] = useState({});
    const [showUpdate, setShowUpdate] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [isUrlCopied, setIsUrlCopied] = useState(false);

    useEffect(() => {
        getKeys();
    }, []);

    const getKeys = () => {
        const expiry = new Date();
        expiry.setMonth(expiry.getMonth() + 1);
        dispatch(checkAndGenerateApiKey(
            {
                tenantId: info.tenantId,
                apiKey: getRandomString(75),
                expiry: expiry
            }, setApiKey
        ));
    }

    const onUpdateApikey = () => {
        dispatch(updateApiKey(apiKey, (a) => {
            setApiKey(a);
            setShowUpdate(false);
        }));
    }
    const handleCopy = () => {
        navigator.clipboard.writeText(apiKey?.apiKey)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 5000); // Reset after 2 seconds
            })
            .catch((error) => console.error('Copy failed', error));
    };

    const handleUrlCopy = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_PRODUCT_APIURL)
            .then(() => {
                setIsUrlCopied(true);
                setTimeout(() => setIsUrlCopied(false), 5000); // Reset after 2 seconds
            })
            .catch((error) => console.error('Copy failed', error));
    };

    return (<div>
        <div className="fixed top-0 w-[82%] z-30">
            <div className="flex gap-5 justify-between mt-[30px] px-5 py-[14px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                    <i className="text-[14px] fa-regular fa-file-code text-theme"></i>
                    <div className="text-[14px] font-medium">API Documentation</div>
                </div>
            </div>
        </div>
        <div className='bg-gray-100 px-4 py-3 mt-[-3.6rem]'>
            <div className='px-4 py-2 bg-white'>
                <div className='p-3'>
                    <div className='flex justify-between'>
                        <div className='text-[16px] font-semibold'>
                            APIs For WhatsApp
                        </div>
                        <div className='flex gap-4 text-[14px]'>
                            {/* <button type='button' className='text-white bg-theme px-2 py-1 rounded-[4px]'>Authorize</button> */}
                            <button type='button' className='text-white bg-theme px-2 py-1 rounded-[4px]'>Download Postman Collection</button>
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        <div className='text-[14px] text-gray-500 max-w-[480px]'>
                            Our developer-friendly RESTful APIs are super-easy to integrate and have bearer authentication mechanism. You can integrate these APIs with your own programming languages.
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-4 bg-white mt-3 pb-4'>
                <div>
                    <div className="text-black font-medium min-h-[50.4px] box-border border-b flex justify-between items-end">
                        <div className="flex text-[15px]">
                            <button className={" text-gray-500 px-1 pb-2 " + (docType === "WHATSAPP" ? " border-theme text-theme afterline border-b-[2px]" : "")}
                                onClick={(a) => setDocType("WHATSAPP")}>
                                WhatsApp
                            </button>
                            {/* <button className={" text-gray-500 px-6 pb-2 " + (docType === "FACEBOOK_FEED" ? " border-theme text-theme afterline border-b-[2px]" : "")}
                                onClick={(a) => setDocType("FACEBOOK_FEED")}>
                                Facebook Feed
                            </button> */}
                        </div>
                    </div>
                    <div className='flex mt-6 text-[14px]'>
                        <div className='font-semibold mt-1'>
                            Access Token
                        </div>
                        <div className='ml-3 min-w-[60%]'>
                            <input type="text" readOnly value={apiKey?.apiKey} className="border-0 border-gray-300 bg-gray-100 text-gray-600 rounded-md py-1 h-8 w-[100%]" />
                        </div>
                        {/* <div className='px-2 py-1 '>
                            Expiry
                            <label className='px-2 ml-2 py-2 text-gray-600 bg-gray-100 rounded-lg'>{apiKey?.expiry}</label>
                        </div> */}
                        <div className='ml-2'>
                            <button type='button' className='px-4 py-1 bg-green-50 text-green-500 border-2 border-green-300 rounded-[4px] rounded-[4px]' onClick={handleCopy}>{isCopied ? "Copied" : "Copy"}</button>
                        </div>
                        {/* <div className='ml-3'>
                            <button type='button' onClick={() => setShowUpdate(true)} className='text-green-500 bg-green-50 border-2 border-green-300 rounded-[4px] px-4 py-1 rounded-[4px]'>Update</button>
                        </div> */}
                    </div>
                    <div className='flex mt-3 text-[14px]'>
                        <div className='font-semibold mt-1'>
                            API Endpoint
                        </div>
                        <div className='ml-3 min-w-[60%]'>
                            <input type="text" readOnly value={process.env.REACT_APP_PRODUCT_APIURL} className="border-0 text-gray-600 border-gray-300 bg-gray-100 rounded-md py-1 h-8 w-[100%]" />
                        </div>
                        <div className='ml-2'>
                            <button type='button' onClick={handleUrlCopy} className='text-green-500 bg-green-50 border-2 border-green-300 rounded-[4px] px-4 py-1 rounded-[4px]'>{isUrlCopied ? "Copied" : "Copy"}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white mt-3 pb-1'>
                <SwaggerUI spec={whatsAppDoc(apiKey?.tenantId, apiKey?.apiKey)} defaultModelsExpandDepth={-1} />
            </div>
        </div>
        <PopUp
            ModalHeader={
                <span className="font-medium text-[18px] text-black">
                    Update Api Key
                </span>
            }
            isShowModal={showUpdate}
            setIsShowModal={setShowUpdate}
            ModalBody={
                <div className='p-3'>
                    <div className='flex'>
                        <input type="text" readOnly value={apiKey?.apiKey} className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-8 w-[100%]" />
                        <div className='ml-3'>
                            <button type='button' className='text-green-500 bg-green-50 border-2 border-green-300 rounded-[4px] px-4 py-1 rounded-[4px]'>New Key</button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <input type="datetime-local" className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-8 w-[100%]"
                            value={apiKey?.expiry} onChange={(e) => setApiKey({ ...apiKey, expiry: e.target.value })} />
                    </div>
                    <div className='mt-3'>
                        <button type='button' onClick={onUpdateApikey} className='text-green-500 bg-green-50 border-2 border-green-300 rounded-[4px] px-4 py-1 rounded-[4px]'>Update</button>
                    </div>
                </div>
            }
            size="2xl"
        />
    </div>)
}

export default APIDocs