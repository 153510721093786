import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../utils/routesConstants';

function SignUp() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [isShowPassword, setIsShowPassword] = useState(true);
  const [isShowRePassword, setIsShowRePassword] = useState(true);
  const [errors, setErrors] = useState({});
  const [isOTP, setIsOTP] = useState(true);
  const [uuid, setUuid] = useState(null);
  const [verifyType, setVerifyType] = useState();

  const navigate = useNavigate();

  const gotoLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  const sendOtp = () => {

  }

  const handleSubmit = async (e) => {}
  return (
    <>
      <div className="flex flex-col h-screen bg-slate-100 max-md:px-5 p-4">
        <div className="flex justify-between">
          <div>
            <img
              alt=""
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/iomnihub-logo.svg`}
              className=" w-40 max-w-full aspect-[3.85] max-md:mt-10"
            />
          </div>
          <div className="flex gap-5 px-5 items-center text-[14px] font-normal">
            <div className="grow my-auto text-black font-[500] leading-[20px]">
              Already playing with Iomnihub?
            </div>
            <div
              className="justify-center cursor-pointer items-start px-[12px] py-[8px] font-medium tracking-wide text-white whitespace-nowrap bg-theme rounded shadow-lg leading-[100%]"
              onClick={gotoLogin}
            >
              Login
            </div>
          </div>
        </div>
        <div className="flex flex-col min-h-[88vh] justify-center items-center bg-slate-100 max-md:px-5">
          <div className="flex justify-center items-center">
            <div className="flex flex-col gap-2 p-[37px] bg-white rounded-[16px] shadow-input max-w-[750px] max-md:px-5 w-[450px]">
              <div className="justify-center items-center pb-[32px] text-[24px] font-[600] text-center text-black max-md:px-5 max-md:max-w-full tracking-[-0.02em] leading-[28px]">
                Sign Up
              </div>
              <div>
                <div className="flex flex-col gap-2">
                  <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em]">
                    Phone Number / Email
                  </div>
                  <input
                    onChange={(e) =>
                      setFormData({ ...formData, mobile: e.target.value })
                    }
                    type="text"
                    id="email"
                    placeholder="Mobile/Email"
                    data-maxlength={10}
                    className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                    autoComplete="NA"
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.dataset.maxlength
                      );
                    }}
                  />
                </div>
                {isOTP ? (
                  <button
                    type="button"
                    className="justify-center cursor-pointer items-center p-[12px]  mt-9 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
                    onClick={sendOtp}
                  >
                    Generate OTP
                  </button>
                ) : (
                  <>
                    <div className="flex flex-col gap-2">
                      <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em] mt-[20px]">
                        OTP
                      </div>
                      <div className="relative hidden-field">
                        <div className="absolute inset-y-0 right-0 flex items-center justify-center px-2">
                          {isShowPassword ? (
                            <img
                              alt=""
                              loading="lazy"
                              src={`${process.env.PUBLIC_URL}/images/eyeOff.svg`}
                              className="w-4 aspect-square cursor-pointer"
                              onClick={() => setIsShowPassword(!isShowPassword)}
                            />
                          ) : (
                            <img
                              alt=""
                              loading="lazy"
                              src={`${process.env.PUBLIC_URL}/images/eyeOn.svg`}
                              className="w-4 aspect-square cursor-pointer"
                              onClick={() => setIsShowPassword(!isShowPassword)}
                            />
                          )}
                        </div>

                        <input
                          className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                          id="password"
                          placeholder="•••• "
                          //   value={OTP}
                          type={isShowPassword ? "password" : "number"}
                          data-maxlength={4}
                          onChange={(e) =>
                            setFormData({ ...formData, otp: e.target.value })
                          }
                          autoComplete="off"
                          onInput={(e) => {
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.dataset.maxlength
                            );
                          }}
                        />
                      </div>
                    </div>
                    <button
                      className="justify-center cursor-pointer items-center p-[12px]  mt-9 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp