import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { upsertBsp } from "../../store/actions/adminAction";

function AddBsp({ setIsShow, onAdd, isEdit = false, defaultBsp = {} }) {
    let dispatch = useDispatch();
    const [Bsp, setBsp] = useState(defaultBsp ?? { type: "GRAPH_API" });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!Bsp?.type) {
            Bsp.type = 'GRAPH_API';
        }
        dispatch(upsertBsp(Bsp, (a) => {
            setIsShow(false);
            onAdd(a);
        }))
    }

    const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
    return (
        <form className="mt-3 max-h-[550px] overflow-auto px-3" onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-3 w-full">
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Name</label>
                    </div>
                    <input type="text" placeholder="Enter Name"
                        value={Bsp?.name}
                        onChange={(e) => setBsp({ ...Bsp, name: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 w-[100%]" required />
                </div>
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Type</label>
                    </div>
                    <select required className="border border-gray-300 rounded-md w-full py-2 h-10 px-4 cursor-pointer text-[15px]" value={Bsp?.type}
                        onChange={(e) => { setBsp({ ...Bsp, type: e.target.value }); }}>
                        <option value="GRAPH_API" selected>Cloud Api </option>
                        <option value="OTHER">Other</option>
                    </select>
                </div>
                <div className="col-span-12">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Business Id</label>
                    </div>
                    <input type="text" placeholder="Enter Business Id"
                        value={Bsp?.businessId}
                        onChange={(e) => setBsp({ ...Bsp, businessId: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 w-[100%]" />
                </div>
                <div className="col-span-10">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Url</label>
                    </div>
                    <input type="text" placeholder="Enter url"
                        value={Bsp?.url}
                        onChange={(e) => setBsp({ ...Bsp, url: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 w-[100%]" required />
                </div>
                <div className="col-span-2">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Api Version</label>
                    </div>
                    <input type="text" placeholder="version"
                        value={Bsp?.apiVersion}
                        onChange={(e) => setBsp({ ...Bsp, apiVersion: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 w-[100%]" required />
                </div>
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>App Id</label>
                    </div>
                    <input type="text" placeholder="Enter App Id"
                        value={Bsp?.appId}
                        onChange={(e) => setBsp({ ...Bsp, appId: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 w-[100%]" required />
                </div>
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>App Secret</label>
                    </div>
                    <input type="text" placeholder="Enter App Secret"
                        value={Bsp?.appSecret}
                        onChange={(e) => setBsp({ ...Bsp, appSecret: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 w-[100%]" required />
                </div>
                <div className="col-span-4 item-end mt-[2px]">
                    <label className="inline-flex items-center cursor-pointer">
                        <input type="checkbox" onChange={(e) => {
                            setBsp({ ...Bsp, active: e.target.checked === true })
                        }} checked={Bsp?.active} className="sr-only peer" />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-theme"></div>
                        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
                    </label>
                </div>
            </div>
            <div className="flex justify-end gap-2 mt-4 mt-[-15px]">
                <button type='button'
                    className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                    onClick={() => setIsShow(false)}>
                    Cancel
                </button>
                <button type='submit'
                    className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                    Save
                </button>
            </div>
        </form>
    )
}

export default AddBsp