import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getMessageType } from '../../store/actions/partnerAction';

function ViewPartnerRateCard({ setIsShow, selectedRateCard }) {
    let dispatch = useDispatch();
    const [messageTypes, setMessageTypes] = useState([]);
    const [rateCard, setRateCard] = useState(selectedRateCard ?? []);

    useEffect(() => {
        dispatch(getMessageType(setMessageTypes));
    }, []);

    const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
    return (
        <div className="mt-3 max-h-[550px] overflow-auto px-3 pb-3">
            {rateCard?.length > 0 && <><div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mt-2 mb-1">
                Domestic Rate
            </div>
                <div className='mt-2 max-h-[400px] overflow-y-scroll'>
                    <table className="text-sm text-left rtl:text-right text-midBlack">
                        <thead className="text-[14px] text-midBlack">
                            <tr className='h-9 bg-gray-100'>
                                <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                                    <div>Countries</div>
                                </th>
                                {messageTypes.map((m, i) => <th key={i} scope="col" className="pb-1 pr-1 font-[500]">
                                    <div>{m.messageType}</div>
                                </th>)}
                            </tr>
                            {rateCard?.filter(f => f.currency === "NATIVE").map((f, r) =>
                                <tr key={r}>
                                    <td className="py-2 font-normal text-[13px] pr-2">
                                        {f?.countryName ?? "Domestic"} ({f?.currencyCode})
                                    </td>
                                    {messageTypes.map((m, i) => <td key={i} className="py-2 font-normal text-[13px] pr-1">
                                        <input data-maxlength={8} onInput={OnInput} type="number" className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                                            value={f?.rates?.[m.messageType]} disabled />
                                    </td>)}
                                </tr>)}
                            <tr>
                                <td colSpan={messageTypes.length + 1}>
                                    <div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mt-1 mb-1 flex justify-between">
                                        <label className='mt-1'>International (USD)</label>
                                        <input type="text" placeholder="Search Countries" className="border border-gray-300 rounded-md h-9 py-1"
                                            onChange={(e) => {
                                                const cn = e.target.value;
                                                setRateCard(rateCard.map(f => {
                                                    if (f.countryCode?.includes(cn) || f.countryName?.includes(cn)) {
                                                        return { ...f, isHide: false };
                                                    }
                                                    else return { ...f, isHide: true };
                                                }));
                                            }} />
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {rateCard?.map((d, c) =>
                                <tr className={"border " + (d.isHide || d.currency === "NATIVE" ? "hidden" : "")} key={c}>
                                    <td className="py-1 font-normal text-[13px] pl-2 pr-2">
                                        {d?.countryName}
                                    </td>
                                    {messageTypes.map((m, i) => <td key={i} className="py-1 font-normal text-[13px] pr-1">
                                        <input data-maxlength={8} onInput={OnInput} type="number" className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                                            value={d?.rates?.[m.messageType]} disabled />
                                    </td>)}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div></>}
            <div className="flex justify-end gap-2 mt-4">
                <button type='button' className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                    onClick={() => setIsShow(false)}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default ViewPartnerRateCard