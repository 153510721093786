import React, { useEffect, useState } from 'react'
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import PopUp from "../../components/PopUp";
import AddCountry from '../../layouts/globalAdmin/AddCountry';
import { changeCountryStatus, searchCountry } from "../../store/actions/adminAction";
import { useDispatch } from 'react-redux';

function Country() {
  const dispatch = useDispatch();
  const [showAddCountry, setshowAddCountry] = useState(false);
  const [Country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({});
  const itemParPage = 10;

  useEffect(() => {
    Countrysearch();
  }, [currentPage])

  const Countrysearch = () => {
    dispatch(searchCountry(
      {
        ...filter,
        count: true,
        size: itemParPage,
        offset: (currentPage - 1) * itemParPage,
      },
      (d) => {
        setCountry(d?.data)
        setTotalPages(Math.ceil(d?.count / itemParPage));
      }
    ));
  };

  const onSearch = () => {
    setCurrentPage(1);
    Countrysearch();
  }

  return (<div>
    <div className="fixed top-0 w-[82%] bg-white z-30 ">
      <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
        <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
          <i className="text-[14px] fa-solid fa-globe text-theme "></i>
          <div className="text-[14px] font-medium">Manage Country</div>
        </div>
        <div className="flex gap-2">
          <div
            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
            onClick={() => { setSelectedCountry({}); setshowAddCountry(true) }}>Add Country
          </div>
        </div>
      </div>
      <div className="bg-white text-black font-medium px-5 min-h-[50.4px] box-border border-b flex justify-between items-end">
        <div className="flex">
          <button className="border-b-[2px] text-[16px] text-black px-1 pb-2 border-theme afterline">
            Manage Country
          </button>
        </div>
      </div>
    </div>
    <div className="grid grid-cols-10 gap-4 pl-8 pt-2">
      <div className={`flex flex-col col-span-2`}>
        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
          <label>Country Details</label>
          <i className="fa-solid fa-circle-info px-2 text-xs"></i>
        </div>
        <input type="text" placeholder="Enter Country Details" className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
          onChange={(e) => {
            var fl = [];
            fl.push({ property: "name", operator: "CONTAINS", value: e.target.value });
            fl.push({ clause: "OR" });
            fl.push({ property: "code", operator: "CONTAINS", value: e.target.value });
            fl.push({ clause: "OR" });
            fl.push({ property: "niceName", operator: "CONTAINS", value: e.target.value });
            fl.push({ clause: "OR" });
            fl.push({ property: "currency", operator: "CONTAINS", value: e.target.value });
            setFilter({ ...filter, filter: fl })
          }} />
      </div>
      <div className={`flex flex-col col-span-2`}>
        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
          <label>Status</label>
          <i className="fa-solid fa-circle-info px-2 text-xs"></i>
        </div>
        <select className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
          onChange={(e) => { setFilter({ ...filter, criteria: { ...filter.criteria, active: e.target.value == "true" } }) }}>
          <option value={true} selected>Active</option>
          <option value={false}>Not Active</option>
        </select>
      </div>
      <div className={`flex flex-col`}>
        <button type="button" className="justify-center mt-7 items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
          onClick={onSearch}> Search
        </button>
      </div>
    </div>
    {Country && Country.length > 0 ? (
      <div className="h-full">
        <div className="px-5 mt-2 bg-white">
          <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto table-fixed">
            <thead className="text-[14px] text-midBlack">
              <tr>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div>Code</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div> Name</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div> Full Name</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div>phoneCode</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div>Currency</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div>Currency Code</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div>Status</div>
                  </div>
                </th>
                <th scope="col" className="pb-1 font-[500]">
                  <div className="flex gap-2">
                    <div>Action</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {Country.map((p, i) => {
                return (
                  <tr className="bg-white border-b">
                    <td className={"py-3 text-[13px] font-normal"}>
                      {p?.code ?? "------"}
                    </td>
                    <td className="py-3 font-normal text-[13px]">
                      {p?.name ?? "------"}
                    </td>
                    <td className="py-3 font-normal text-[13px]">
                      {p?.niceName ?? "------"}
                    </td>
                    <td className="py-2 font-normal text-[13px]">
                      {p?.phoneCode ?? "------"}
                    </td>
                    <td className="py-3 font-normal text-[13px]">
                      {p?.currency ?? "------"}
                    </td>
                    <td className="py-3 font-normal text-[13px]">
                      {p?.currencyCode ?? "------"}
                    </td>
                    <td className="py-3 font-normal text-[13px]">
                      {p?.active ? <label className='bg-[#D4F8D3] p-2 rounded-lg'>Active</label> : <label className='bg-[#F8F4D3] p-2 rounded-lg'>Not Active</label>}
                    </td>
                    <td className="py-3 font-normal text-[13px] flex">
                      <div className='text-right ml-5'>
                        <Dropdown
                          inline
                          arrowIcon={false}
                          inputMode
                          as="button"
                          className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                          label={<i className="fa-solid fa-ellipsis"></i>}
                        >
                          {!p?.isPrimary && (p?.active ? <Dropdown.Item onClick={() => { dispatch(changeCountryStatus({ id: p?.id, isActive: false }, a => Countrysearch())) }}>
                            Deactivate
                          </Dropdown.Item> : <Dropdown.Item onClick={() => { dispatch(changeCountryStatus({ id: p?.id, isActive: true }, a => Countrysearch())) }}>
                            Activate
                          </Dropdown.Item>)}
                          <Dropdown.Item onClick={() => { setSelectedCountry({ ...p, isEdit: true }); setshowAddCountry(true); }}>
                            Edit
                          </Dropdown.Item>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {totalPages > 1 ? (
            <div className="flex overflow-x-auto sm:justify-center bottom-0 mb-[5rem], mt-2 mb-4">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(event, page) => setCurrentPage(page)}
                shape="rounded"
                color="secondary"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    ) : (
      <>
        <div className="m-[4rem] text-center flex flex-col items-center gap-2">
          <img
            src={`${process.env.PUBLIC_URL}/images/noList.png`}
            alt="No List"
            className="w-[486px] h-[278px] "
          />
          <p className="my-[24px] ">
            The space is empty . Add a Country to get started.
          </p>
          <div className="flex gap-2.5 justify-center text-base font-medium">
            <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
              <div className="flex gap-2" onClick={() => setshowAddCountry(true)}>
                <div className="">
                  <span className="mr-2">
                    <i className="fa-solid fa-plus"></i>
                  </span>
                  Add Customer
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )}
    <PopUp
      ModalHeader={
        <span className="font-medium text-[18px] text-black">
          {selectedCountry.isEdit ? "Update Country" : "Add Country"}
        </span>
      }
      isShowModal={showAddCountry}
      setIsShowModal={setshowAddCountry}
      ModalBody={
        <AddCountry setIsShow={setshowAddCountry} onAdd={Countrysearch} defaultCountry={selectedCountry} isEdit={selectedCountry.isEdit} />
      }
      size="2xl"
    />
  </div>
  )
}

export default Country