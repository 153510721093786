import React, { useEffect, useState } from 'react'
import PopUp from '../../components/PopUp';
import { Autocomplete, Pagination, TextField } from '@mui/material';
import { Dropdown } from 'flowbite-react';
import { useDispatch } from 'react-redux';
import { categoryQuery, changeContactStatus, getTenantContacts, tenantContactBulkUpload } from '../../store/actions/tenantAction';
import { getInfo, SHOW_SNACKBAR } from '../../utils/types';
import AddContact from "../../layouts/tenant/AddContact";
import RightSidePanel from '../../components/RightSidePanel';
import ImportExcel from '../../components/ImportExcel';

function Contacts() {
    const info = getInfo();
    const dispatch = useDispatch();
    const [showAddContact, setshowAddContact] = useState(false);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [Contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [filter, setFilter] = useState({ criteria: { tenantId: info.tenantId } });
    const columnValidation = [
        { name: "customerId", displayName: "Customer Id", type: "text", isMandatory: false },
        { name: "name", displayName: "Name", type: "text", isMandatory: false },
        { name: "gender", displayName: "Gender", type: "text", isMandatory: false },
        { name: "phone", displayName: "Phone", type: "text", isMandatory: true },
        { name: "email", displayName: "Email", type: "email", isMandatory: false },
        { name: "location", displayName: "location", type: "text", isMandatory: false }
    ];
    const itemParPage = 10;

    useEffect(() => {
        Contactsearch();
    }, [currentPage])

    useEffect(() => {
        dispatch(categoryQuery(
            {
                tenantId: info.tenantId,
                count: true,
                size: 100,
            },
            (d) => {
                setCategories(d?.data ?? []);
            }
        ));
    }, [])


    const Contactsearch = () => {
        dispatch(getTenantContacts(
            {
                ...filter,
                tenantId: info.tenantId,
                count: true,
                size: itemParPage,
                offset: (currentPage - 1) * itemParPage,
            },
            (d) => {
                setContacts(d?.data)
                setTotalPages(Math.ceil(d?.count / itemParPage));
            }
        ));
    };

    const onSearch = () => {
        setCurrentPage(1);
        Contactsearch();
    }

    return (<div>
        <div className="fixed top-0 w-[82%] bg-white z-30 ">
            <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                    <i className="text-[14px] fa-regular fa-address-card text-theme"></i>
                    <div className="text-[14px] font-medium">Manage Contacts</div>
                </div>
                <div className="flex gap-2">
                    <div
                        className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                        onClick={() => { setSelectedContact({}); setShowBulkUpload(true) }}>Upload Bulk Contact
                    </div>
                    <div
                        className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                        onClick={() => { setSelectedContact({}); setshowAddContact(true) }}>Add Contact
                    </div>
                </div>
            </div>
            <div className="bg-white text-black font-medium px-5 min-h-[50.4px] box-border border-b flex justify-between items-end">
                <div className="flex">
                    <button className="border-b-[2px] text-[16px] text-black px-1 pb-2 border-theme afterline">
                        Contacts
                    </button>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-10 gap-4 pl-8 pt-2">
            <div className={`flex flex-col col-span-2`}>
                <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                    <label>Contact Details</label>
                    <i className="fa-solid fa-circle-info px-2 text-xs"></i>
                </div>
                <input type="text" placeholder="Enter Contact Details" className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                    onChange={(e) => {
                        const fl = []
                        if (!isNaN(e.target.value)) {
                            fl.push({ property: "phone", operator: "CONTAINS", value: e.target.value });
                        }
                        else if (e.target.value?.includes('@')) {
                            fl.push({ property: "email", operator: "CONTAINS", value: e.target.value });
                        }
                        else { fl.push({ property: "name", operator: "CONTAINS", value: e.target.value }) }
                        setFilter({ ...filter, filter: fl })
                    }} />
            </div>
            <div className={`flex flex-col col-span-2`}>
                <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                    <label>Status</label>
                    <i className="fa-solid fa-circle-info px-2 text-xs"></i>
                </div>
                <select className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                    onChange={(e) => { setFilter({ ...filter, criteria: { ...filter.criteria, active: e.target.value == "true" } }) }}>
                    <option value={true} selected>Active</option>
                    <option value={false}>Not Active</option>
                </select>
            </div>
            <div className={`flex flex-col`}>
                <button type="button" className="justify-center mt-7 items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                    onClick={onSearch}> Search
                </button>
            </div>
        </div>
        {Contacts && Contacts.length > 0 ? (
            <div className="h-full">
                <div className="px-5 mt-2 bg-white">
                    <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                        <thead className="text-[14px] text-midBlack">
                            <tr>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>Name</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div> Email</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div> Phone</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>Status</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>Action</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Contacts.map((p, i) => {
                                return (
                                    <tr className="bg-white border-b">
                                        <td className={"py-3 text-[13px] font-normal"}>
                                            {p?.name ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {p?.email ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {`+${p?.whatsappNumber}`}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {p?.active ? <label className='bg-[#D4F8D3] p-2 rounded-lg'>Active</label> : <label className='bg-[#F8F4D3] p-2 rounded-lg'>Not Active</label>}
                                        </td>
                                        <td className=" py-3 font-normal text-[13px]">
                                            <Dropdown
                                                inline
                                                arrowIcon={false}
                                                inputMode
                                                as="button"
                                                className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                                label={<i className="fa-solid fa-ellipsis"></i>}
                                            >
                                                {p?.active ? <Dropdown.Item className='hover:text-theme' onClick={() => { dispatch(changeContactStatus({ id: p?.id, isActive: false }, a => Contactsearch())) }}>
                                                    <i className="fa-solid fa-link-slash pr-2"></i>  Deactivate
                                                </Dropdown.Item> : <Dropdown.Item className='hover:text-theme' onClick={() => { dispatch(changeContactStatus({ id: p?.id, isActive: true }, a => Contactsearch())) }}>
                                                    <i className="fa-solid fa-link pr-2"></i>Activate
                                                </Dropdown.Item>}
                                                <Dropdown.Item className='hover:text-theme' onClick={() => { setSelectedContact({ ...p, isEdit: true }); setshowAddContact(true); }}>
                                                    <i className="fa-regular fa-pen-to-square pr-2"></i> Edit
                                                </Dropdown.Item>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {totalPages > 1 && (
                        <div className="flex overflow-x-auto sm:justify-center bottom-0 mb-[5rem], mt-2">
                            <Pagination
                                page={currentPage}
                                count={totalPages}
                                onChange={(event, page) => setCurrentPage(page)}
                                shape="rounded"
                                color="secondary"
                            />
                        </div>
                    )}
                </div>
            </div>
        ) : (
            <>
                <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/noList.png`}
                        alt="No List"
                        className="w-[486px] h-[278px] "
                    />
                    <p className="my-[24px] ">
                        The space is empty . Add a Contact to get started.
                    </p>
                    <div className="flex gap-2.5 justify-center text-base font-medium">
                        <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                            <div className="flex gap-2" onClick={() => setshowAddContact(true)}>
                                <div className="">
                                    <span className="mr-2">
                                        <i className="fa-solid fa-plus"></i>
                                    </span>
                                    Add Contact
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
        <PopUp
            ModalHeader={
                <span className="font-medium text-[18px] text-black">
                    {selectedContact?.isEdit ? "Update Contacts" : "Add Contacts"}
                </span>
            }
            isShowModal={showAddContact}
            setIsShowModal={setshowAddContact}
            ModalBody={
                <AddContact setIsShow={setshowAddContact} onAdd={Contactsearch} defaultContact={selectedContact} isEdit={selectedContact?.isEdit} tenantId={info.tenantId} />
            }
            size="2xl"
        />
        <RightSidePanel ModalTitle={"Upload Contacts"} setIsShow={setShowBulkUpload} isShow={showBulkUpload} downloadFile="contact_template.xlsx" fileUrl="/files/contact_template.xlsx"
            Component={
                <div>
                    <div className='w-full h-10 mt-2 px-5'>
                        <Autocomplete multiple={true} aria-required={true} options={categories} isOptionEqualToValue={(option, value) => option?.categoryName === value?.categoryName}
                            getOptionLabel={(option) => option?.categoryName ?? ""}
                            onChange={(e, value) => { setSelectedCategories(value) }}
                            sx={{
                                '& .MuiAutocomplete-inputRoot': {
                                    padding: '0px',
                                }
                            }}
                            renderInput={(params) => (<TextField {...params} placeholder="Select Category" className="custom-input"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        padding: "0px",
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'none',
                                            border: '0px',
                                        },
                                        fontSize: "14px",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        backgroundColor: '#FFFFFF',
                                        "& fieldset": {
                                            borderColor: '#ccc'
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#888',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#1976d2',
                                        }
                                    }
                                }}
                            />)} />
                    </div>
                    <ImportExcel Title="Upload Contacts" setIsShow={setShowBulkUpload} columnValidation={columnValidation}
                        onImportClick={(con) => {
                            con.map(m => {
                                m.tenantId = info.tenantId;
                                m.uid = info.uid;
                                m.whatsappNumber = m.phone;
                                m.category = selectedCategories?.map(c => c.categoryName)
                                // if (mob.length < 2) {
                                //     dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: "mobile number should be with country code", open: true } });
                                //     return;
                                // }
                            })
                            dispatch(tenantContactBulkUpload(con, (a) => {
                                Contactsearch();
                                setShowBulkUpload(false);
                            }))
                        }}
                    />
                </div>
            }
            CustomClass="fixed md:w-[50%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
        />
    </div>
    )
}

export default Contacts