import React, { useEffect } from 'react'
import WhatsAppTemplatePreview from '../../components/WhatsAppTemplatePreview'
import { useState } from 'react'
import { calculateBroadcastPayment, campaignCreate, searchWhatsAppMsgTemplate, sendWhatsAppMessage } from '../../store/actions/tenantAction';
import { useDispatch, useSelector } from 'react-redux';
import { getInfo, getWaba } from '../../utils/types';
import SetTemplateVariableValue from './SetTemplateVariableValue';
import TestMessage from './TestMessage';
import PopUp from '../../components/PopUp';
import { SHOW_SNACKBAR } from "../../utils/types";
import RightSidePanel from '../../components/RightSidePanel';
import ImportExcel from '../../components/ImportExcel';
import AddContact from './AddContact';
import ChooseContact from './ChooseContact';

function CreateBroadcast({ setIsShow, onAdd }) {
    const dispatch = useDispatch();
    const tenantInfo = getInfo();
    const selectedWaba = getWaba();
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [contacts, setContacts] = useState([]);
    const [paymentInformation, setPaymentInformation] = useState({});
    const [showSendTestMessage, setShowSendTestMessage] = useState(false);
    const [showContactUpload, setShowContactUpload] = useState(false);
    const [showAddContact, setShowAddContact] = useState(false);
    const [showChooseContacts, setShowChooseContacts] = useState(false);
    const [carouselTemplate, setCarouselTemplate] = useState([]);
    const [template, setTemplate] = useState({ templateType: "GENERIC", category: "MARKETING", language: "English", header: { type: "HEADER", format: "" }, body: '', footer: '', buttons: [] });
    const [broadcast, setBroadcast] = useState({ campaignType: "INTERNAL", scheduleType: "IMMEDIATE", broadcastType: "ONEGO" });
    const [step, setStep] = useState(1);
    const tenantReducer = useSelector((state) => state.tenantReducer);
    const columnValidation = [
        { name: "customerId", displayName: "Customer Id", type: "text", isMandatory: false },
        { name: "name", displayName: "Name", type: "text", isMandatory: false },
        { name: "gender", displayName: "Gender", type: "text", isMandatory: false },
        { name: "phone", displayName: "Phone", type: "text", isMandatory: true },
        { name: "email", displayName: "Email", type: "email", isMandatory: false },
        { name: "location", displayName: "location", type: "text", isMandatory: false },
        { name: "category", type: "text", displayName: "Category", isMandatory: false }
    ];

    useEffect(() => {
        dispatch(searchWhatsAppMsgTemplate({ tenantId: tenantInfo.tenantId }, t => {
            setTemplates(t.data ?? []);
        }))
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const clickedButton = e.nativeEvent.submitter;
        if (clickedButton?.value === "TestMessage") {
            setShowSendTestMessage(true);
        } else {
            if (step === 2) {
                if (contacts?.length == 0) {
                    dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: "please add at least one contact", open: true } });
                    return;
                }
                dispatch(calculateBroadcastPayment({
                    ...broadcast,
                    templateId: parseInt(broadcast.templateId),
                    tenantId: tenantInfo.tenantId,
                    tenantWabaId: selectedWaba?.id,
                    waba: selectedWaba,
                    template: selectedTemplate,
                    campaignDetails: contacts.map(c => {
                        return {
                            tenantId: tenantInfo.tenantId,
                            contactId: c.id,
                            contact: c
                        }
                    })
                }, a => {
                    setPaymentInformation(a);
                }));
            }
            else if (step === 3) {
                delete selectedTemplate?.waba;
                dispatch(campaignCreate({
                    ...broadcast,
                    templateId: parseInt(broadcast.templateId),
                    tenantId: tenantInfo.tenantId,
                    tenantWabaId: selectedWaba?.id,
                    waba: selectedWaba,
                    template: selectedTemplate,
                    templateValue: { components: getVariableComponent() },
                    campaignDetails: contacts.map(c => {
                        return {
                            tenantId: tenantInfo.tenantId,
                            contactId: c.id,
                            contact: c
                        }
                    })
                }, a => {
                    setIsShow(false);
                    onAdd(a);
                }));
            }
            setStep(step < 3 ? step + 1 : step);
        }
    }

    const getVariableComponent = () => {
        const components = [];
        const bodyVariables = [];
        const headerVariables = [];
        template?.bodyVariables?.map(v => {
            bodyVariables.push({ type: "text", text: v.text });
        });
        if (bodyVariables.length > 0) {
            components.push({ type: "body", parameters: bodyVariables });
        }
        if (template?.header?.format === "TEXT") {
            template?.header?.headerVariables?.map(v => {
                headerVariables.push({ type: "text", text: v.text });
            });
            if (headerVariables.length > 0) {
                components.push({ type: "header", parameters: headerVariables });
            }
        } else if (template?.header?.format) {
            components.push({
                type: "header", parameters: {
                    type: template?.header?.format?.toLowerCase(),
                    image: {
                        "link": template?.header?.fileUrl
                    }
                }
            });
        }
        if (template?.buttons?.length > 0) {
            template?.buttons?.map((b, i) => {
                if (b.type === "URL" && b.variableValues?.length > 0) {
                    let btnVariables = [];
                    b.variableValues.map(v => {
                        btnVariables.push({ type: "text", text: v });
                    });
                    components.push({ type: "button", sub_type: "url", index: i, parameters: btnVariables });
                }
            });
        }
        const cards = [];
        if (carouselTemplate.length > 0) {
            carouselTemplate.map((m, i) => {
                const cardComponents = [];
                if (m.format) {
                    cardComponents.push({
                        type: "header",
                        parameters: [
                            {
                                "type": m.format?.toLowerCase(),
                                "image": {
                                    "id": m.fileUrl
                                }
                            }
                        ]
                    });
                }
                if (m.buttons?.length > 0) {
                    m.buttons?.map(b => {
                        if (b.type === "URL" && b.variableValues?.length > 0) {
                            const btnVariables = [];
                            b.variableValues.map(d => {
                                btnVariables.push({ type: "text", text: d });
                            });
                            cardComponents.push({ type: "button", sub_type: "url", index: i, parameters: btnVariables });
                        }
                    });
                }
                cards.push({ card_index: i, components: cardComponents });
            });
            components.push({ type: "carousel", cards: cards });
        }
        return components;
    }

    const onSelectTemplate = (e) => {
        setBroadcast({ ...broadcast, templateId: e.target.value });
        const t = templates.find(f => f.id == e.target.value);
        setSelectedTemplate(t);
        if (t) {
            const tpl = {};
            t?.components?.map(c => {
                switch (c.type.toLowerCase()) {
                    case "header":
                        tpl["header"] = {
                            type: "HEADER",
                            format: c.format,
                            text: c.text
                        };
                        if (c.format && c?.example) {
                            if (c.format === "TEXT" && Array.isArray(c?.example?.header_text))
                                tpl.header.headerVariables = c.example.header_text.map(h => { return { text: h } });
                            else if (Array.isArray(c?.example?.header_handle)) tpl.header.fileUrl = c.example.header_handle[0];
                        }
                        break;
                    case "body":
                        tpl["body"] = c.text;
                        if (c?.example && Array.isArray(c?.example?.body_text)) {
                            tpl.bodyVariables = c.example.body_text[0].map(h => { return { text: h } });
                        }
                        break;
                    case "footer":
                        tpl["footer"] = c.text;
                        break;
                    case "buttons":
                        tpl["buttons"] = c.buttons;
                        if (c.buttons?.length > 0) {
                            c.buttons.map(b => {
                                if (b.type === "URL" && Array.isArray(b.example)) {
                                    b["variableValues"] = b.example;
                                    b["isVariable"] = true;
                                }
                            });
                        }
                        break;
                    case "carousel":
                        c.cards?.map(t => {
                            const cul = {};
                            t.components?.map(p => {
                                switch (p.type.toLowerCase()) {
                                    case "header":
                                        cul["format"] = p.format;
                                        if (p.format && p?.example && Array.isArray(c?.example?.header_handle)) {
                                            cul.fileUrl = c.example.header_handle[0];
                                        }
                                        break;
                                    case "body":
                                        cul["body"] = p.text;
                                        break;
                                    case "buttons":
                                        cul["buttons"] = p.buttons;
                                        if (p.buttons?.length > 0) {
                                            p.buttons.map(b => {
                                                if (p.type === "URL" && Array.isArray(p.example)) {
                                                    p["variableValues"] = p.example;
                                                    b["isVariable"] = true;
                                                }
                                            });
                                        }
                                        break;
                                }
                            });
                            carouselTemplate.push(cul);
                        });
                        break;
                }
            });
            setTemplate({ ...tpl, name: t.name, language: t.language, templateType: t.templateType, category: t.category });
            setCarouselTemplate([...carouselTemplate]);
        }
    }

    const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
    return (<div>
        <div className='grid grid-cols-3 gap-2 p-2'>
            <div className={`border-t border-2 ${step === 1 ? "border-theme" : "border-gray-300"}`} />
            <div className={`border-t border-2 ${step === 2 ? "border-theme" : "border-gray-300"}`} />
            <div className={`border-t border-2 ${step === 3 ? "border-theme" : "border-gray-300"}`} />
            <div>
                <p className={`text-[14px] ${step === 1 ? "text-theme" : "text-gray-500"}`}>STEP 1</p>
                <p className='text-[14px]'>Broadcast Details</p>
            </div>
            <div>
                <p className={`text-[14px] ${step === 2 ? "text-theme" : "text-gray-500"}`}>STEP 2</p>
                <p className='text-[14px]'>Select Audiences</p>
            </div>
            <div>
                <p className={`text-[14px] ${step === 3 ? "text-theme" : "text-gray-500"}`}>STEP 3</p>
                <p className='text-[14px]'>Summary</p>
            </div>
        </div>
        <form className='bg-gray-50' onSubmit={handleSubmit}>
            <div className={`${step === 1 ? "" : "hidden"} grid grid-cols-12`}>
                <div className='px-3 py-2 overflow-y-auto col-span-7'>
                    <div className='' style={{ height: "calc(-230px + 100vh)" }}>
                        <div className="grid gap-3 w-full pb-2">
                            <div className="w-full">
                                <label className="block text-sm rtl:text-right text-gray-600 font-medium">
                                    Campaign Name
                                </label>
                                <div className="relative mt-1 rounded-md shadow-sm">
                                    <input required value={broadcast?.campaignName} type="text" className="block w-full rounded-md border-gray-300 pr-10 rtl:pl-10 placeholder-gray-400 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                        placeholder="e.g. My First Broadcast" onChange={(e) => setBroadcast({ ...broadcast, campaignName: e.target.value })} data-maxlength={24} onInput={OnInput} />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-0 flex items-center pr-3 rtl:pl-3">
                                        <p className="text-xs text-gray-400 py-1 px-1 bg-gray-50 rounded">
                                            {broadcast?.campaignName?.length}/24
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-1">
                                <label className="block text-sm rtl:text-right text-gray-600 font-medium">
                                    Campaign Type
                                </label>
                                <select required value={broadcast?.campaignType} className="border w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
                                    onChange={(e) => { setBroadcast({ ...broadcast, campaignType: e.target.value }) }}>
                                    <option value="INTERNAL" selected>Internal</option>
                                    <option value="EXTERNAL">External</option>
                                </select>
                            </div>
                            <div className="w-full mt-1">
                                <label className="block text-sm rtl:text-right text-gray-600 font-medium">
                                    Schedule Campaign
                                </label>
                                <div className="w-full gap-1 flex justify-between rounded-md border border-gray-300 bg-white shadow-sm">
                                    <select required value={broadcast?.scheduleType} className="border-0 w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
                                        onChange={(e) => { setBroadcast({ ...broadcast, scheduleType: e.target.value }) }}>
                                        <option value="IMMEDIATE" selected>Immediate</option>
                                        <option value="SCHEDULED">Schedule</option>
                                    </select>
                                    {broadcast?.scheduleType === "SCHEDULED" && <><div className='border-l border' />
                                        <input type='datetime-local' required value={broadcast?.scheduledTime} onChange={(e) => setBroadcast({ ...broadcast, scheduledTime: e.target.value })} className='w-full border-0 border-gray-300 rounded-md' /></>}
                                </div>
                            </div>
                            <div className="w-full mt-1">
                                <label className="block text-sm rtl:text-right text-gray-600 font-medium">
                                    Broadcast Type
                                </label>
                                <select required value={broadcast?.broadcastType} className="border w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
                                    onChange={(e) => { setBroadcast({ ...broadcast, broadcastType: e.target.value }) }}>
                                    <option value="ONEGO" selected>One Go</option>
                                    <option value="DRIP">Drip</option>
                                </select>
                            </div>
                            <div className="w-full mt-1">
                                <label className="text-sm text-gray-600 font-medium" id="headlessui-listbox-label-:rq:">
                                    Message Template
                                </label>
                                <select required className="border w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
                                    onChange={onSelectTemplate}>
                                    <option value="" selected disabled>Select Template</option>
                                    {templates.map((m, i) => <option key={i} value={m.id}>{m.name}</option>)}
                                </select>
                            </div>
                            <div className="w-full mt-3 border-t border-gray-400">
                                <label className="block text-sm rtl:text-right text-gray-600 font-medium">Set Template Variable Value</label>
                                <SetTemplateVariableValue setTemplate={setTemplate} template={template} carouselTemplate={carouselTemplate} setCarouselTemplate={setCarouselTemplate} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative flex flex-col gap-2 items-center bg-white p-2 col-span-5"
                    style={{ height: "calc(-200px + 100vh)" }}>
                    <h1 className="text-[14px] flex justify-end font-medium leading-7 capitalize text-gray-900">
                        Preview
                    </h1>
                    <WhatsAppTemplatePreview template={template} carouselTemplate={carouselTemplate} />
                </div>
                <div className='px-5 py-2 col-span-7 gap-3 bg-gray-100 flex justify-end'>
                    {step === 1 ? <button type='button' onClick={() => setIsShow(false)} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                        Cancel
                    </button> : <button type='button' onClick={() => setStep(step > 1 ? step - 1 : step)} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                        Back
                    </button>}
                    <button type='submit' value={"Next"} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                        Next
                    </button>
                </div>
                <div className='col-span-5 py-2 flex justify-center bg-gray-100'>
                    <button type='submit' value={"TestMessage"} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                        Send a Test Message
                    </button>
                </div>
            </div>
            <div className={`${step === 2 ? "" : "hidden"}`}>
                <div className="h-[65vh]">
                    <div className="mt-2 bg-gray-50">
                        <div className='flex justify-end'>
                            <a onClick={() => setShowAddContact(true)} className="min-w-[76px] text-center px-[10px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline">
                                Add Contact
                            </a>
                            <a onClick={() => setShowContactUpload(true)} className="min-w-[76px] text-center px-[10px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline">
                                Upload Contact
                            </a>
                            <a onClick={() => setShowChooseContacts(true)} className="min-w-[76px] text-center px-[10px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline">
                                Choose from Contacts
                            </a>
                        </div>
                        {contacts?.length > 0 && <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                            <thead className="text-[14px] py-1 text-midBlack bg-gray-100 w-full">
                                <tr>
                                    <th scope="col" className="py-1 pl-3 font-[500]">
                                        <div className="flex gap-2">
                                            <div>Name</div>
                                        </div>
                                    </th>
                                    <th scope="col" className="py-1 font-[500]">
                                        <div className="flex gap-2">
                                            <div> Phone</div>
                                        </div>
                                    </th>
                                    <th scope="col" className="py-1 font-[500]">
                                        <div className="flex gap-2">
                                            <div>Category</div>
                                        </div>
                                    </th>
                                    <th scope="col" className="py-1 font-[500]">
                                        <div className="flex gap-2">
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts.map((p, i) => {
                                    return (
                                        <tr className="bg-white border-b">
                                            <td className={"py-1 pl-3 text-[13px] font-normal"}>
                                                {p?.name ?? "------"}
                                            </td>
                                            <td className="py-1 font-normal text-[13px]">
                                                {`+${p?.whatsappNumber}`}
                                            </td>
                                            <td className="py-1 font-normal text-[13px]">
                                                {p?.category?.join(',') ?? "----"}
                                            </td>
                                            <td className="py-1 font-normal text-[13px]">
                                                <a onClick={() => { contacts.splice(i, 1); setContacts([...contacts]) }} className="min-w-[76px] text-center px-[10px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline">
                                                    Remove
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>}
                    </div>
                </div>
                <div className='px-5 py-2 gap-3 flex justify-end'>
                    <button type='button' onClick={() => setStep(step > 1 ? step - 1 : step)} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                        Back
                    </button>
                    <button type='submit' value={"Next"} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                        Next
                    </button>
                </div>
            </div>
            <div className={`${step === 3 ? "" : "hidden"}`}>
                <div className='h-[65vh]'>
                    <div className='grid grid-cols-2 gap-5 px-2 py-3'>
                        <div>
                            <p className='text-[14px]'>{broadcast?.campaignName} | Broadcast Summary</p>
                            <div className='bg-white border border-gray-300 p-3 text-[14px] rounded-lg min-h-[150px]'>
                                <p><label className='text-gray-500'>Sent from</label> <label>+{selectedWaba?.phoneCode + selectedWaba?.phone}</label></p>
                                <p className='mt-2'><label className='text-gray-500'>Message template</label> <label>{selectedTemplate?.name}</label></p>
                                <p className='mt-2'><label className='text-gray-500'>Scheduled to</label> <label>{contacts.length} Customers</label></p>
                                <p className='mt-2'><label className='text-gray-500 mt-5'>Invalid</label> <label>0 Customers</label></p>
                            </div>
                        </div>
                        <div>
                            <p className='text-[14px]'>Payment Information</p>
                            <div className='bg-white border border-gray-300 p-3 text-[14px] rounded-lg min-h-[150px]'>
                                <label className='text-gray-500'>Your available credits will be used first. Additional credits ($0)will be charged from your card.</label>
                                <div className='grid grid-cols-2 justify-between gap-2 pt-1'>
                                    <p>Estimated Cost</p><p>{paymentInformation?.currencyCode ?? tenantReducer.tenantDetails?.country?.currencyCode} {paymentInformation?.estimatedAmount ?? 0}</p>
                                    <p>Available Credits</p><p>{paymentInformation?.currencyCode ?? tenantReducer.tenantDetails?.country?.currencyCode} {paymentInformation?.availableAmount ?? 0}</p>
                                    <p>Additional Credits Required</p><p>{paymentInformation?.currencyCode ?? tenantReducer.tenantDetails?.country?.currencyCode} {paymentInformation?.additionalAmount ?? 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-5 py-2 gap-3 flex justify-end'>
                    <button type='button' onClick={() => setIsShow(false)} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                        Cancel
                    </button>
                    <button type='button' onClick={() => setStep(step > 1 ? step - 1 : step)} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                        Back
                    </button>
                    <button type='submit' className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                        Submit
                    </button>
                </div>
            </div>
        </form>
        <PopUp
            ModalHeader={
                <span className="font-medium text-[18px] text-black">
                    Send Test Message
                </span>
            }
            isShowModal={showSendTestMessage}
            setIsShowModal={setShowSendTestMessage}
            ModalBody={
                <TestMessage setIsShow={setShowSendTestMessage} setMobileNumbers={(m) => {
                    m?.map(mobile => {
                        // var mob = mobile?.split('-');
                        // if (mob.length < 2) {
                        //     dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: "mobile number should be with country code", open: true } });
                        //     return;
                        // }
                        dispatch(sendWhatsAppMessage({
                            tenantId: tenantInfo.tenantId,
                            contact: { uid: tenantInfo.uid, tenantId: tenantInfo.tenantId, whatsappNumber: mobile },
                            tenantWabaId: selectedWaba?.id,
                            waba: selectedWaba,
                            templateId: broadcast.templateId,
                            template: selectedTemplate,
                            languageCode: selectedTemplate.language,
                            messageType: selectedTemplate.messageType,
                            type: "template",
                            templateValue: { components: getVariableComponent() }
                        }, a => {
                            setShowSendTestMessage(false);
                        }));
                    })
                }} />
            }
            size="2xl"
        />
        <RightSidePanel ModalTitle={"Upload Contacts"} setIsShow={setShowContactUpload} isShow={showContactUpload} downloadFile="contact_template.xlsx" fileUrl="/files/contact_template.xlsx"
            Component={
                <ImportExcel Title="Upload Contacts" setIsShow={setShowContactUpload} columnValidation={columnValidation}
                    onImportClick={(con) => {
                        con.map(m => {
                            m.tenantId = tenantInfo.tenantId;
                            m.uid = tenantInfo.uid;
                            // var mob = m.phone?.split('-');
                            // if (mob.length < 2) {
                            //     dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: "mobile number should be with country code", open: true } });
                            //     return;
                            // }
                            m.whatsappNumber = m.phone;
                            if ((typeof m.category) == "string")
                                m.category = m.category?.split(',');
                            if (!contacts.some(s => (s.whatsappNumber) == m.phone)) {
                                contacts.push(m);
                            }
                        })
                        setContacts([...contacts]);
                        setShowContactUpload(false);
                    }}
                />
            }
            CustomClass="fixed md:w-[50%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
        />
        <PopUp
            ModalHeader={
                <span className="font-medium text-[18px] text-black">
                    Add Contact
                </span>
            }
            isShowModal={showAddContact}
            setIsShowModal={setShowAddContact}
            ModalBody={
                <AddContact setIsShow={setShowAddContact} onlyGetContact={true} onAdd={(c) => {
                    if (!contacts.some(s => (s.whatsappNumber) == (c.whatsappNumber))) {
                        setContacts([...contacts, c]);
                    }
                }} />
            }
            size="2xl"
        />
        <PopUp
            ModalHeader={
                <span className="font-medium text-[18px] text-black">
                    Choose Contacts
                </span>
            }
            isShowModal={showChooseContacts}
            setIsShowModal={setShowChooseContacts}
            ModalBody={
                <ChooseContact setIsShow={setShowChooseContacts} onAdd={(a) => {
                    a?.map(c => {
                        if (!contacts.some(s => (s.whatsappNumber) == (c.whatsappNumber))) {
                            contacts.push(c);
                        }
                    });
                    setContacts([...contacts]);
                }} />
            }
            size="2xl"
        />
    </div>)
}

export default CreateBroadcast