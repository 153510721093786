import React, { useEffect, useState } from 'react'
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import PopUp from "../../components/PopUp";
import AddContactCategory from '../../layouts/tenant/AddContactCategory';
import { categoryDelete, categoryQuery, categoryView, getTenantContacts, tenantContactBulkUpload } from "../../store/actions/tenantAction";
import { useDispatch } from 'react-redux';
import { getInfo } from '../../utils/types';
import RightSidePanel from '../../components/RightSidePanel';
import ImportExcel from '../../components/ImportExcel';
import moment from 'moment/moment';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';

function ManageCategories() {
    const info = getInfo();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showAddCategory, setshowAddCategory] = useState(false);
    const [uploadContacts, setUploadContacts] = useState(false);
    const [downloadContacts, setDownloadContacts] = useState([]);
    const [deleteContact, setDeleteContact] = useState(false);
    const [ContactHeaders, setContactHeaders] = useState([{ label: "customerId", key: "customerId" }, { label: "name", key: "name" }, { label: "gender", key: "gender" }, { label: "whatsappNumber", key: "whatsappNumber" }, { label: "email", key: "email" }, { label: "location", key: "location" }, { label: "category", key: "category" }]);
    const [Category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [filter, setFilter] = useState({});
    const itemParPage = 10;
    const columnValidation = [
        { name: "customerId", displayName: "Customer Id", type: "text", isMandatory: false },
        { name: "name", displayName: "Name", type: "text", isMandatory: false },
        { name: "gender", displayName: "Gender", type: "text", isMandatory: false },
        { name: "phone", displayName: "Phone", type: "text", isMandatory: true },
        { name: "email", displayName: "Email", type: "email", isMandatory: false },
        { name: "location", displayName: "location", type: "text", isMandatory: false }
    ];

    useEffect(() => {
        Categorysearch();
    }, [currentPage])

    const Categorysearch = () => {
        dispatch(categoryView(
            {
                ...filter,
                tenantId: info.tenantId,
                count: true,
                size: itemParPage,
                offset: (currentPage - 1) * itemParPage,
            },
            (d) => {
                setCategory(d?.data)
                setTotalPages(Math.ceil(d?.count / itemParPage));
            }
        ));
    };

    const onSearch = () => {
        setCurrentPage(1);
        Categorysearch();
    }

    return (<div>
        <div className="fixed top-0 w-[82%] bg-white z-30 ">
            <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                    <i class="fa-solid fa-code-branch text-[14px] text-theme"></i>
                    <div className="text-[14px] font-medium">Manage Category</div>
                </div>
                <div className="flex gap-2">
                    <div>
                        <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                            onClick={() => {
                                dispatch(getTenantContacts({ tenantId: info.tenantId, size: 1000000 }, d => {
                                    setDownloadContacts(d.data); setTimeout(() => {
                                        document.getElementById("ContactsDownload").click();
                                    }, 500);
                                }))
                            }}>Download All Contacts
                        </div>
                        <CSVLink filename="Contacts" data={downloadContacts} headers={ContactHeaders} id="ContactsDownload"></CSVLink>
                    </div>
                    <div
                        className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                        onClick={() => { setSelectedCategory({}); setshowAddCategory(true) }}>Add Category
                    </div>
                </div>
            </div>
            <div className="bg-white text-black font-medium px-5 min-h-[50.4px] box-border border-b flex justify-between items-end">
                <div className="flex">
                    <button className="border-b-[2px] text-[16px] text-black px-1 pb-2 border-theme afterline">
                        Manage Category
                    </button>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-10 gap-4 pl-8 pt-2">
            <div className={`flex flex-col col-span-4`}>
                <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                    <label>Category Details</label>
                    <i className="fa-solid fa-circle-info px-2 text-xs"></i>
                </div>
                <input type="text" placeholder="Enter Category Details" className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                    onChange={(e) => {
                        var fl = [];
                        fl.push({ property: "categoryName", operator: "CONTAINS", value: e.target.value });
                        setFilter({ ...filter, filter: fl })
                    }} />
            </div>
            <div className={`flex flex-col`}>
                <button type="button" className="justify-center mt-7 items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                    onClick={onSearch}> Search
                </button>
            </div>
        </div>
        {Category && Category.length > 0 ? (
            <div className="h-full">
                <div className="px-5 mt-2 bg-white">
                    <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto table-fixed">
                        <thead className="text-[14px] text-midBlack">
                            <tr>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>Category Name</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>Permission</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>Contacts Updated Date</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>No. of Contacts</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>Opt-Out Count</div>
                                    </div>
                                </th>
                                <th scope="col" className="pb-1 font-[500]">
                                    <div className="flex gap-2">
                                        <div>Action</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Category.map((p, i) => {
                                return (
                                    <tr className="bg-white border-b" key={i}>
                                        <td className={"py-3 text-[13px] font-normal"}>
                                            {p?.categoryName ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {p?.permission ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {moment(p?.contactUpdatedDate).format("YYYY-MM-DD hh:mm A") ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">
                                            {p?.contactCount ?? "------"}
                                        </td>
                                        <td className="py-3 font-normal text-[13px]">

                                        </td>
                                        <td className="py-3 font-normal text-[13px] flex">
                                            <div className='text-right ml-5'>
                                                <Dropdown inline arrowIcon={false} inputMode as="button" className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                                    label={<i className="fa-solid fa-ellipsis"></i>}>
                                                    <Dropdown.Item className='hover:text-theme' onClick={() => { setSelectedCategory({ ...p, isEdit: true }); setshowAddCategory(true); }}>
                                                        Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className='hover:text-theme' onClick={() => { setSelectedCategory({ ...p }); setDeleteContact(true); }}>
                                                        Delete
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className='hover:text-theme' onClick={() => {
                                                        dispatch(getTenantContacts({ tenantId: info.tenantId, size: 1000000, criteria: { category: [p.categoryName] } }, d => {
                                                            setDownloadContacts(d.data); setTimeout(() => {
                                                                document.getElementById("ContactsDownload").click();
                                                            }, 500);
                                                        }))
                                                    }}>Download Contacts
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className='hover:text-theme' onClick={() => {
                                                        dispatch(getTenantContacts({ tenantId: info.tenantId, size: 1000000, criteria: { category: [p.categoryName] } }, d => {
                                                            setDownloadContacts(d.data); setTimeout(() => {
                                                                document.getElementById("ContactsDownload").click();
                                                            }, 500);
                                                        }))
                                                    }}>Download Opt-Out Contacts
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className='hover:text-theme' onClick={() => { setSelectedCategory({ ...p }); setUploadContacts(true); }}>
                                                        Upload Contacts
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className='hover:text-theme' onClick={() => {
                                                        navigate("/tenant/campaigns", { state: { isCreate: true, category: p } });
                                                    }}>Send Broadcast
                                                    </Dropdown.Item>
                                                </Dropdown>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {totalPages > 1 && (
                        <div className="flex overflow-x-auto sm:justify-center bottom-0 mb-[5rem], mt-2">
                            <Pagination
                                page={currentPage}
                                count={totalPages}
                                onChange={(event, page) => setCurrentPage(page)}
                                shape="rounded"
                                color="secondary"
                            />
                        </div>
                    )}
                </div>
            </div>
        ) : (
            <>
                <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/noList.png`}
                        alt="No List"
                        className="w-[486px] h-[278px] "
                    />
                    <p className="my-[24px] ">
                        The space is empty . Add a Category to get started.
                    </p>
                    <div className="flex gap-2.5 justify-center text-base font-medium">
                        <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                            <div className="flex gap-2" onClick={() => setshowAddCategory(true)}>
                                <div className="">
                                    <span className="mr-2">
                                        <i className="fa-solid fa-plus"></i>
                                    </span>
                                    Add Category
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
        <PopUp
            ModalHeader={
                <span className="font-medium text-[18px] text-black">
                    {selectedCategory.isEdit ? "Update Category" : "Add Category"}
                </span>
            }
            isShowModal={showAddCategory}
            setIsShowModal={setshowAddCategory}
            ModalBody={
                <AddContactCategory setIsShow={setshowAddCategory} onAdd={Categorysearch} defaultCategory={selectedCategory} isEdit={selectedCategory.isEdit} />
            }
            size="2xl"
        />
        <PopUp
            ModalHeader={
                <>
                    <div className="font-medium text-[18px] text-black">
                        are you sure you want to delete?
                    </div>
                </>
            }
            isShowModal={deleteContact}
            setIsShowModal={setDeleteContact}
            ModalBody={
                <>
                    <div className="flex justify-end gap-4 pt-10 px-0">
                        <button
                            className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                            onClick={() => setDeleteContact(false)}
                        > Cancel</button>
                        <button
                            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                            onClick={() => { dispatch(categoryDelete(selectedCategory?.id, a => { setDeleteContact(false); })) }}
                        >Delete</button>
                    </div>
                </>
            }
            size="md"
        />
        <RightSidePanel ModalTitle={"Upload Contacts"} setIsShow={setUploadContacts} isShow={uploadContacts} downloadFile="contact_template.xlsx" fileUrl="/files/contact_template.xlsx"
            Component={
                <ImportExcel Title="Upload Contacts" setIsShow={setUploadContacts} columnValidation={columnValidation}
                    onImportClick={(con) => {
                        con.map(m => {
                            m.tenantId = info.tenantId;
                            m.uid = info.uid;
                            m.whatsappNumber = m.phone;
                            m.category = [selectedCategory.categoryName]
                        })
                        dispatch(tenantContactBulkUpload(con, (a) => {
                            Categorysearch();
                            setUploadContacts(false);
                        }))
                    }}
                />
            }
            CustomClass="fixed md:w-[50%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
        />
    </div>
    )
}

export default ManageCategories