import React, { useEffect, useState } from 'react'
import { FiBriefcase } from "react-icons/fi";
import { useDispatch } from 'react-redux';
import { Pagination } from "@mui/material";
import { Dropdown } from 'flowbite-react';
import { changePlanStatus, getPLans } from '../../store/actions/partnerAction';
import moment from 'moment';
import PopUp from '../../components/PopUp';
import AddPlan from '../../layouts/partner/AddPlan';
import PlanDetails from '../../layouts/partner/PlanDetails';
import RightSidePanel from '../../components/RightSidePanel';
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;


function Plans() {
  const dispatch = useDispatch();
  const [planList, setPlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();
  const [showPlanDetails, setShowPlanDetails] = useState(false);
  const [addPlan, setAddPlan] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState({});
  const [dateFilter, setDateFilter] = useState();
  const itemParPage = 10;

  useEffect(() => {
    planSearch();
  }, [currentPage])

  const planSearch = () => {
    if (dateFilter && searchText) {
      filter.filter = [
        { property: "name", operator: "CONTAINS", value: searchText },
        { clause: "OR" },
        dateFilter];
    } else if (searchText) {
      filter.filter = [{ property: "name", operator: "CONTAINS", value: searchText }];
    }
    else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }
    dispatch(getPLans(
      {
        ...filter,
        count: true,
        size: itemParPage,
        offset: (currentPage - 1) * itemParPage,
      },
      (d) => {
        setPlanList(d?.data)
        setTotalPages(Math.ceil(d?.count / itemParPage));
      }
    ));
  }

  const onSearch = () => {
    setCurrentPage(1);
    planSearch();
  }

  const selectCreatedDate = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else { setDateFilter(null) }
    } else { setDateFilter(null) }
  };

  const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
  return (
    <div className="fixed  top-0 w-[82%] bg-white z-30 ">
      <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
        <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer"
          onClick={() => setAddPlan(false)}>
          <FiBriefcase className='text-theme text-[14px]' />
          <div className="text-[14px] font-medium">Configurations</div>
        </div>
        <div className="flex justify-center items-center">
          <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
            onClick={() => { setSelectedPlan(null); setAddPlan(true) }}>
            Add Plan
          </div>
        </div>
      </div>
      <div className="bg-white text-black font-medium px-5 min-h-[35px] box-border border-b flex justify-between items-end">
        <div className="flex">
          <button className="border-b-[2px] text-[16px] text-black px-1 pb-1 border-theme afterline">
            Plans
          </button>
        </div>
      </div>
      <div className="grid grid-cols-10 gap-4 pl-8 pt-2">
        <div className={`flex flex-col col-span-2`}>
          <div className="text-[#656F7D] text-[13px] font-normal mb-2">
            <label>Plan Name</label>
            <i className="fa-solid fa-circle-info px-2 text-xs"></i>
          </div>
          <input type="text" placeholder="Plan Name" className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
            onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className={`flex flex-col col-span-2`}>
          <div className="text-[#656F7D] text-[13px] font-normal mb-2">
            <label>Status</label>
            <i className="fa-solid fa-circle-info px-2 text-xs"></i>
          </div>
          <select className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
            onChange={(e) => { setFilter({ ...filter, criteria: { ...filter.criteria, active: e.target.value == "true" } }) }}>
            <option value={true} selected>Active</option>
            <option value={false}>Not Active</option>
          </select>
        </div>
        <div className={`flex flex-col col-span-3`}>
          <div className="text-[#656F7D] text-[13px] font-normal mb-2">
            <label>Date</label>
            <i className="fa-solid fa-circle-info px-2 text-xs"></i>
          </div>
          <RangePicker className='h-10' onChange={selectCreatedDate} format="YYYY-MM-DD" />
        </div>
        <div className={`flex flex-col`}>
          <button type="button" className="justify-center mt-7 items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
            onClick={onSearch}> Search
          </button>
        </div>
      </div>
      {planList && planList.length > 0 ? (
        <div className="h-full">
          <div className="px-5 mt-2 bg-white">
            <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
              <thead className="text-[14px] text-midBlack">
                <tr>
                  <th scope="col" className="pb-1 font-[500]">
                    <div className="flex gap-2">
                      <div> Plan Name</div>
                    </div>
                  </th>
                  <th scope="col" className="pb-1 font-[500]">
                    <div className="flex gap-2">
                      <div> Plan Amount</div>
                    </div>
                  </th>
                  <th scope="col" className="pb-1 font-[500]">
                    <div className="flex gap-2">
                      <div> Tenure(Months)</div>
                    </div>
                  </th>
                  <th scope="col" className="pb-1 font-[500]">
                    <div className="flex gap-2">
                      <div> Country</div>
                    </div>
                  </th>
                  <th scope="col" className="pb-1 font-[500]">
                    <div className="flex gap-2">
                      <div>Date & time</div>
                    </div>
                  </th>
                  <th scope="col" className="pb-1 font-[500]">
                    <div className="flex gap-2">
                      <div>Status</div>
                    </div>
                  </th>
                  <th scope="col" className="pb-1 font-[500]">
                    <div className="flex gap-2">
                      <div>Action</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {planList.map((p, i) => {
                  return (
                    <tr className="bg-white border-b">
                      <th
                        scope="row"
                        className=" py-3 font-medium text-[14px] hover:underline hover:text-theme cursor-pointer text-theme whitespace-nowrap"
                        onClick={() => { setSelectedPlan(p); setShowPlanDetails(true); }}>
                        {p?.name ?? "------"}
                      </th>
                      <td className="py-3 font-normal text-[13px]">
                        {p?.currency + "  "} {p?.rate ?? "------"}
                      </td>
                      <td className="py-3 font-normal text-[13px]">
                        {p?.durationMonth ?? "------"}
                      </td>
                      <td className="py-3 font-normal text-[13px]">
                        {p?.countryCode ?? "------"}
                      </td>
                      <td className="py-3 font-normal text-[13px]">
                        {moment(p?.createdAt).format("YYYY-MM-DD hh:mm A") ?? "------"}
                      </td>
                      <td className="py-3 font-normal text-[13px]">
                        {p?.active ? <label className='bg-[#D4F8D3] p-2 rounded-lg'>Active</label> : <label className='bg-[#F8F4D3] p-2 rounded-lg'>Not Active</label>}
                      </td>
                      <td className=" py-3 font-normal text-[13px]">
                        <Dropdown
                          inline
                          arrowIcon={false}
                          inputMode
                          as="button"
                          className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                          label={<i className="fa-solid fa-ellipsis"></i>}
                        >
                          {p?.active ? <Dropdown.Item onClick={() => { dispatch(changePlanStatus({ id: p?.code, isActive: false }, planSearch)) }}>
                            Deactivate
                          </Dropdown.Item> : <Dropdown.Item onClick={() => { dispatch(changePlanStatus({ id: p?.code, isActive: true }, planSearch)) }}>
                            Activate
                          </Dropdown.Item>}
                          <Dropdown.Item onClick={() => { setSelectedPlan({ ...p, isEdit: true }); setAddPlan(true); }}>
                            Edit
                          </Dropdown.Item>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {totalPages > 1 ? (
              <div className="flex overflow-x-auto sm:justify-center bottom-0 mb-[5rem], mt-2">
                <Pagination
                  page={currentPage}
                  count={totalPages}
                  onChange={(event, page) => setCurrentPage(page)}
                  shape="rounded"
                  color="secondary"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="ml-[4rem] mr-[4rem] text-center flex flex-col items-center gap-2">
            <img
              src={`${process.env.PUBLIC_URL}/images/noList.png`}
              alt="No List"
              className="w-[486px] h-[250px] "
            />
            <p className="my-[24px] ">
              The space is empty . Add a Plan to get started.
            </p>
            <div className="flex gap-2.5 justify-center text-base font-medium">
              <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                <div className="flex gap-2" onClick={() => { setSelectedPlan(null); setAddPlan(true) }}>
                  <div className="">
                    <span className="mr-2">
                      <i className="fa-solid fa-plus"></i>
                    </span>
                    Add Plan
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <RightSidePanel
        CustomClass="fixed md:w-[50%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={addPlan}
        setIsShow={setAddPlan}
        ModalTitle={selectedPlan?.isEdit ? "Edit Plan" : " Add Plan"}
        Component={
          <AddPlan setIsShow={setAddPlan} onAdd={planSearch} isEdit={selectedPlan?.isEdit} defaultPlan={selectedPlan} />
        }
      />
      <RightSidePanel
        CustomClass="fixed md:w-[40%] pl-4 shadow-[0_12px_26px_0px_rgba(0,0,0,0.25)] top-[31.8px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showPlanDetails}
        setIsShow={setShowPlanDetails}
        ModalTitle=" Plan Details"
        Component={
          <PlanDetails setIsShow={setShowPlanDetails} details={selectedPlan} />
        }
      />
    </div>
  )
}

export default Plans