import React, { useEffect, useState } from 'react'
import { getInfo } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function AllMessages() {
    const info = getInfo();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({ criteria: { partnerId: info.partnerId } });

    useEffect(() => {
        Usersearch();
    }, [])

    const Usersearch = () => {

    };

    const onSearch = () => {
        Usersearch();
    }

    const selectHolidayDate = (dates) => {
        const [start, end] = dates;
        // filter([
        //     moment(start).format("YYYY-MM-DD"),
        //     moment(end).format("YYYY-MM-DD"),
        // ]);
    };

    return (<div>
        <div className="fixed top-0 w-[82%] bg-white z-30 ">
            <div className="flex gap-5 justify-between mt-[30px] px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                    <i className="fa-regular fa-comment-dots text-[14px] text-theme"></i>
                    <div className="text-[14px] font-medium">All Messages</div>
                </div>
                <div className="flex gap-2">

                </div>
            </div>
            <div className="bg-white text-black font-medium px-5 min-h-[70px] box-border border-b">
                <div className="flex py-3 gap-3">
                    <div className={`flex flex-col col-span-2`}>
                        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                            <label>All Phone number</label>
                        </div>
                        <select className="border border-gray-300 min-w-[200px] rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                            onChange={(e) => { setFilter({ ...filter, criteria: { ...filter.criteria, active: e.target.value == "true" } }) }}>
                            <option value={true} selected>Active</option>
                            <option value={false}>Not Active</option>
                        </select>
                    </div>
                    <div className={`flex flex-col col-span-2`}>
                        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                            <label>All Country</label>
                        </div>
                        <select className="border border-gray-300 min-w-[200px] rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                            onChange={(e) => { setFilter({ ...filter, criteria: { ...filter.criteria, active: e.target.value == "true" } }) }}>
                            <option value={true} selected>Active</option>
                            <option value={false}>Not Active</option>
                        </select>
                    </div>
                    <div className={`flex flex-col col-span-2`}>
                        <div className="text-[#656F7D] text-[13px] font-normal mb-2">
                            <label>Date Range</label>
                        </div>
                        <RangePicker className={"h-10"} onChange={selectHolidayDate} format="YYYY-MM-DD" />
                    </div>

                    <div className={`flex flex-col`}>
                        <button type="button" className="justify-center mt-7 items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                            onClick={onSearch}> Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-10 gap-4 pl-8 pt-2">


        </div>

    </div>)
}

export default AllMessages