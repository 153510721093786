import React, { useEffect, useState } from 'react'
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useDispatch } from 'react-redux';
import { campaignQuery, getAllTenantWaba, getDashboardInfo, getWhatsAppMsgTemplate, messageCount, messageCountChart } from '../../store/actions/tenantAction';
import { getInfo, getWaba, saveWaba } from '../../utils/types';
import moment from 'moment';
import PopUp from '../../components/PopUp';
import SelectWaba from '../../layouts/tenant/SelectWaba';
import WalletHistory from '../../layouts/tenant/WalletHistory';
import AccountInformation from '../../layouts/tenant/AccountInformation';
import LoginHistory from '../../layouts/tenant/LoginHistory';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  let dispatch = useDispatch();
  const info = getInfo();
  const navigate = useNavigate();
  const selectedWaba = getWaba();
  const [totalMessage, setTotalMessage] = useState({});
  const [months, setMonths] = useState([]);
  const [tickPlacement, setTickPlacement] = useState('middle');
  const [statistics, setStatistics] = useState([]);
  const [tickLabelPlacement, setTickLabelPlacement] = useState('middle');
  const [chartData, setChartData] = useState([]);
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [showSelectWaba, setShowSelectWaba] = useState(false);
  const [showWalletHistory, setShowWalletHistory] = useState(false);
  const [walletHistoryFilter, setWalletHistoryFilter] = useState({});
  const [showAccountInformation, setShowAccountInformation] = useState(false);
  const [showLoginHistory, setShowLoginHistory] = useState(false);
  const [Wabas, setWabas] = useState([]);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [broadcasts, setBroadcasts] = useState([]);

  const chartSetting = {
    yAxis: [{ label: 'Statistics' }],
    series: [{ dataKey: 'count', label: 'Today’s Statistics', valueFormatter }],
    height: 350,
    sx: { [`& .${axisClasses.directionY} .${axisClasses.label}`]: { transform: 'translateX(-10px)' } },
  };

  useEffect(() => {
    dispatch(getAllTenantWaba(
      (d) => {
        setWabas(d ?? []);
        if (Array.isArray(d)) {
          if (selectedWaba) {
            saveWaba(d.find(f => f.id === selectedWaba.id));
          } else {
            saveWaba(d[0]);
          }
        }
      }
    ));
    let messageDate = moment();
    messageDate.subtract(1, 'month');
    dispatch(messageCount({ tenantId: info.tenantId, sendDate: messageDate.format("YYYY-MM-DD") }, (a) => {
      setTotalMessage(a);
      setStatistics([{ count: a.submitted, status: 'Submitted' }, { count: a.accepted, status: 'Sent' }, { count: a.delivered, status: 'Delivered', }, { count: a.failed, status: 'Failed' }]);
    }));
    const month = new Date().getMonth();
    const monthDates = [];
    for (var i = 6 - 1; i >= 0; i--) {
      const fromDate = new Date(new Date().getFullYear() + '-' + (month - i) + '-01');
      const toDate = new Date(new Date().getFullYear() + '-' + (month - (i - 1)) + '-01');
      monthDates.push({ text: moment(fromDate).format("MMMM"), fromDate: fromDate, toDate: toDate });
      if (i === 0) {
        monthDates.push({ text: moment(new Date()).format("MMMM"), fromDate: toDate, toDate: new Date() });
      }
    }
    setMonths(monthDates);
    dispatch(messageCountChart({ tenantId: info.tenantId, dates: monthDates }, (a) => {
      setChartData(a)
    }));
    dispatch(getDashboardInfo(selectedWaba?.id, setDashboardInfo));
    dispatch(campaignQuery(
      {
        criteria: { active: true },
        tenantId: info.tenantId,
        count: true,
        size: 5,
        offset: 0,
        orderBy: [{ property: "id", direction: "DESC" }]
      }, (d) => setBroadcasts(d?.data ?? [])
    ));
    dispatch(getWhatsAppMsgTemplate(
      {
        criteria: { active: true },
        tenantId: info.tenantId,
        count: true,
        size: 5,
        offset: 0,
        orderBy: [{ property: "id", direction: "DESC" }]
      }, (d) => setMessageTemplates(d?.data ?? [])
    ));
  }, []);

  function valueFormatter(value) {
    return `${value} message`;
  }

  const getGreeting = () => {
    const currentHour = new Date().getHours(); // Get the current hour (0-23)
    if (currentHour < 12) {
      return <span className='text-[#FFA500]'>Good Morning!</span>;
    } else if (currentHour < 18) {
      return <span className='text-[#0000FF]'>Good Afternoon!</span>;
    } else {
      return <span className='text-[#00008B]'>Good Night!</span>;
    }
  };
  return (<div>
    <div className='bg-gray-100 h-full mt-[-110px] px-4 py-2'>
      <div className='py-2 flex justify-between'>
        <div className=''>
          Teams :
          <select value={selectedWaba?.id} className="ml-2 border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
            onChange={(e) => {
              saveWaba(Wabas?.find(f => f.id === e.target.value));
            }}>
            {Wabas?.map((w, i) => <option key={i} value={w.id}>{w.wabaNumber}</option>)}
          </select>
          {/* Team Name: <label className='text-gray-400'>{selectedWaba?.whatsappDisplayName}</label> <label onClick={() => setShowSelectWaba(true)} className='text-[14px] hover:underline hover:text-theme text-theme cursor-pointer ml-2'>change</label> */}
        </div>
        <div className='text-[16px]'>
          <label> {getGreeting()} <span className='text-gray-500'>{info.name}</span></label>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-4 text-[14px]'>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <div className='flex justify-between border-b'>
            <div>
              <label className='text-gray-400'>Remaining Balance</label><br />
              <label className='text-[#008743] text-[16px]'>{dashboardInfo?.remainingBalance}</label>
            </div>
            <div className='pt-4'>
              <i className="fa-solid fa-suitcase text-[#008743] text-[24px]"></i>
            </div>
          </div>
          <div className='py-2'>
            <label onClick={() => { setShowWalletHistory(true); setWalletHistoryFilter(null) }} className='cursor-pointer hover:text-theme hover:underline'><i className="fa-solid fa-envelope mr-1"></i>Account Statement</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <div className='flex justify-between border-b'>
            <div className='text-[12px]'>
              <label className='text-gray-400'>Account Expiry Date</label><br />
              <label className='text-[#D92D20]'>{moment(dashboardInfo?.accountExpiryDate).format("YYYY-MM-DD")}</label><br />
              <label className='text-gray-400'>API Expiry Date</label><br />
              <label className='text-[#D92D20]'>{moment(dashboardInfo?.apiExpiryDate).format("YYYY-MM-DD")}</label>
            </div>
            <div className='pt-8'>
              <i className="fa-regular fa-calendar-days text-[#D92D20] text-[24px]"></i>
            </div>
          </div>
          <div className='pt-1'>
            <label onClick={() => setShowAccountInformation(true)} className='cursor-pointer hover:text-theme hover:underline'><i className="fa-regular fa-calendar mr-1"></i>Account Information</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <div className='flex justify-between border-b'>
            <div>
              <label className='text-gray-400'>Last Credit Topup</label><br />
              <label className='text-[#37B3FF] text-[16px]'>{dashboardInfo?.lastCreditTopUp}</label><br />
            </div>
            <div className='pt-4'>
              <i className="fa-solid fa-cart-shopping text-[#37B3FF] text-[24px]"></i>
            </div>
          </div>
          <div className='pt-1'>
            <label onClick={() => { setShowWalletHistory(true); setWalletHistoryFilter({ walletTransactionType: "CREDIT" }) }} className='cursor-pointer hover:text-theme hover:underline'><i className="fa-regular fa-eye mr-1"></i>View More</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <div className='flex justify-between border-b'>
            <div>
              <label className='text-gray-400'>Last Login DateTime</label><br />
              <label className='text-[#F7B400] text-[16px]'>{moment(dashboardInfo?.lastLoginDateTime).format("YYYY-MM-DD hh:mm A")}</label><br />
            </div>
            <div className='pt-4'>
              <i className="fa-regular fa-clock text-[#F7B400] text-[24px]"></i>
            </div>
          </div>
          <div className='pt-1'>
            <label onClick={() => { setShowLoginHistory(true) }} className='cursor-pointer hover:text-theme hover:underline'><i className="fa-solid fa-clock-rotate-left mr-1"></i>Login History</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <label className='text-gray-400'>Total Submitted</label>
          <div className='flex justify-between'>
            <div className='text-[18px] pt-0.5'>{totalMessage?.submitted ?? 0}</div>
            <div>
              <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                <i class="fa-solid fa-check text-gray-500"></i>
              </button>
            </div>
          </div>
          <div className='flex text-[#008743]'>
            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
            <label className='ml-1'>Today Status</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <label className='text-gray-400'>Total Sent</label>
          <div className='flex justify-between'>
            <div className='text-[18px] pt-0.5'>{totalMessage?.accepted ?? 0}</div>
            <div>
              <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                <i class="fa-solid fa-check-double text-gray-500"></i>
              </button>
            </div>
          </div>
          <div className='flex text-[#008743]'>
            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
            <label className='ml-1'>Today Status</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <label className='text-gray-400'>Total Delivered</label>
          <div className='flex justify-between'>
            <div className='text-[18px] pt-0.5'>{totalMessage?.delivered ?? 0}</div>
            <div>
              <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                <i class="fa-solid fa-check-double text-[#1291D0]"></i>
              </button>
            </div>
          </div>
          <div className='flex text-[#008743]'>
            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
            <label className='ml-1'>Today Status</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <label className='text-gray-400'>Total Failed</label>
          <div className='flex justify-between'>
            <div className='text-[18px] pt-0.5'>{totalMessage?.failed ?? 0}</div>
            <div>
              <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                <i class="fa-solid fa-xmark text-[#D92D20]"></i>
              </button>
            </div>
          </div>
          <div className='flex text-[#D92D20]'>
            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
            <label className='ml-1'>Today Status</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <label className='text-gray-400'>Total Submitted</label>
          <div className='flex justify-between'>
            <div className='text-[18px] pt-0.5'>{totalMessage?.submittedByDate ?? 0}</div>
            <div>
              <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                <i class="fa-solid fa-check text-gray-500"></i>
              </button>
            </div>
          </div>
          <div className='flex text-[#008743]'>
            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
            <label className='ml-1'>Last 30 Days Status</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <label className='text-gray-400'>Total Sent</label>
          <div className='flex justify-between'>
            <div className='text-[18px] pt-0.5'>{totalMessage?.acceptedByDate ?? 0}</div>
            <div>
              <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                <i class="fa-solid fa-check-double text-gray-500"></i>
              </button>
            </div>
          </div>
          <div className='flex text-[#008743]'>
            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
            <label className='ml-1'>Last 30 Days Status</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <label className='text-gray-400'>Total Delivered</label>
          <div className='flex justify-between'>
            <div className='text-[18px] pt-0.5'>{totalMessage?.deliveredCount ?? 0}</div>
            <div>
              <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                <i class="fa-solid fa-check-double text-[#1291D0]"></i>
              </button>
            </div>
          </div>
          <div className='flex text-[#008743]'>
            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
            <label className='ml-1'>Last 30 Days Status</label>
          </div>
        </div>
        <div className='bg-white p-3 rounded-lg shadow-lg'>
          <label className='text-gray-400'>Total Failed</label>
          <div className='flex justify-between'>
            <div className='text-[18px] pt-0.5'>{totalMessage?.failedByDate ?? 0}</div>
            <div>
              <button type='button' className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
                <i class="fa-solid fa-xmark text-[#D92D20]"></i>
              </button>
            </div>
          </div>
          <div className='flex text-[#D92D20]'>
            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
            <label className='ml-1'>Last 30 Days Status</label>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-12 mt-5'>
        <div className='col-span-8'>
          {months.length > 0 &&
            <LineChart
              height={350}
              series={[
                { data: chartData.map(m => m.submitted), label: 'Submitted', area: true, stack: 'total', showMark: false },
                { data: chartData.map(m => m.accepted), label: 'Sent', area: true, stack: 'total', showMark: false },
                { data: chartData.map(m => m.delivered), label: 'Delivered', area: true, stack: 'total', showMark: false },
                { data: chartData.map(m => m.failed), label: 'Failed', area: true, stack: 'total', showMark: false },
              ]}
              xAxis={[{ scaleType: 'point', data: months.map(m => m.text) }]}
              sx={{
                [`& .${lineElementClasses.root}`]: {
                  display: 'none',
                },
              }}
            />}
        </div>
        <div className='col-span-4'>
          <BarChart
            dataset={statistics}
            xAxis={[
              { scaleType: 'band', dataKey: 'status', tickPlacement, tickLabelPlacement },
            ]}
            {...chartSetting}
          />
        </div>
      </div>
      <div className='grid grid-cols-12 gap-5 mt-3 mb-3'>
        <div className='col-span-6'>
          <p className='text-[16px]'>Campaigns <label className='ml-2 text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline'
            onClick={() => navigate("/tenant/campaigns")}>View More</label></p>
          <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
            <thead className="text-[14px] text-midBlack bg-gray-200">
              <tr>
                <th scope="col" className="font-[500] py-3 pl-2">
                  <div>Broadcast Name</div>
                </th>
                <th scope="col" className="font-[500]">
                  <div> Status</div>
                </th>
                <th scope="col" className="font-[500]">
                  <div>Broadcast time</div>
                </th>
                <th scope="col" className="font-[500]">
                  <div>Schedule Type</div>
                </th>
                <th scope="col" className="font-[500]">
                  <div>Delivered</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {broadcasts.map((p, i) => {
                return (
                  <tr className="bg-white border-b">
                    <td className={"py-3 text-[13px] font-normal"}>
                      {p?.campaignName ?? "------"}
                    </td>
                    <td className="py-3 font-normal text-[13px]">
                      {p?.status ?? "------"}
                    </td>
                    <td className="py-3 font-normal text-[13px]">
                      {moment(p?.scheduledTime).format("YYYY-MM-DD hh:mm A") ?? "------"}
                    </td>
                    <td className="py-3 font-normal text-[13px]">
                      {p?.scheduleType ?? "------"}
                    </td>
                    <td className="py-3 font-normal text-[13px]">
                      {p?.delivered ?? "100%"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='col-span-6'>
          <p className='text-[16px]'>Templates <label className='ml-2 text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline'
            onClick={() => navigate("/tenant/templates")}>View More</label></p>
          <table className="text-sm text-left rtl:text-right text-midBlack w-full mt-1 overflow-x-auto">
            <thead className="text-[14px] text-midBlack bg-gray-200">
              <tr>
                <th scope="col" className="font-[500] py-3 pl-2">
                  <div className="flex gap-2">
                    <div>Name</div>
                  </div>
                </th>
                <th scope="col" className="font-[500]">
                  <div className="flex gap-2">
                    <div>Template Id</div>
                  </div>
                </th>
                <th scope="col" className="font-[500]">
                  <div className="flex gap-2">
                    <div> Status</div>
                  </div>
                </th>
                <th scope="col" className="font-[500]">
                  <div className="flex gap-2">
                    <div> Crated Date</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {messageTemplates?.map((t, j) => {
                return (<tr key={j} className="bg-white border-b">
                  <td className={"py-3 text-[13px] font-normal"}>
                    {t?.name ?? "---"}
                  </td>
                  <td className={"py-3 text-[13px] font-normal"}>
                    {t?.referenceId ?? "---"}
                  </td>
                  <td className={"py-3 text-[13px] font-normal"}>
                    {t?.status?.toLowerCase() === "approved" ? <label className='bg-[#D4F8D3] p-2 rounded-lg'>{t?.status}</label> : <label className='bg-[#F8F4D3] p-2 rounded-lg'>{t?.status}</label>}
                  </td>
                  <td className={"py-3 text-[13px] font-normal"}>
                    {moment(t?.createdAt).format("YYYY-MM-DD hh:mm A")}
                  </td>
                </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <PopUp
      ModalHeader={
        <span className="font-medium text-[18px] text-black">
          Choose Team
        </span>
      }
      isShowModal={showSelectWaba}
      setIsShowModal={setShowSelectWaba}
      ModalBody={
        <SelectWaba setIsShow={setShowSelectWaba} />
      }
      size="md"
    />
    <PopUp
      ModalHeader={
        <span className="font-medium text-[18px] text-black">
          Wallet History
        </span>
      }
      isShowModal={showWalletHistory}
      setIsShowModal={setShowWalletHistory}
      ModalBody={
        <WalletHistory setIsShow={setShowWalletHistory} walletHistoryFilter={walletHistoryFilter} tenantWabaId={selectedWaba?.id} />
      }
      size="2xl"
    />
    <PopUp
      ModalHeader={
        <span className="font-medium text-[18px] text-black">
          Account Information
        </span>
      }
      isShowModal={showAccountInformation}
      setIsShowModal={setShowAccountInformation}
      ModalBody={
        <AccountInformation setIsShow={setShowAccountInformation} />
      }
      size="2xl"
    />
    <PopUp
      ModalHeader={
        <span className="font-medium text-[18px] text-black">
          Login History
        </span>
      }
      isShowModal={showLoginHistory}
      setIsShowModal={setShowLoginHistory}
      ModalBody={
        <LoginHistory setIsShow={setShowLoginHistory} info={info} />
      }
      size="2xl"
    />
  </div>)
}

export default Dashboard
