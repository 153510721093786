export const downloadBlob = (blob, filename) => {
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
};
export const base64ToUint8 = (str) =>
  Uint8Array.from(atob(str), (v) => v.charCodeAt(0));

export const getRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export function removeStringByIndex(str, startIndex, length) {
  return str.slice(0, startIndex) + str.slice(startIndex + length);
}

export function getPreviousMonths(count = 7) {
  let mons = [];
  for (var i = count - 1; i >= 0; i--) {
    mons.push(getPreviousMonth(i))
  }
  return mons;
}

export function getPreviousMonth(subtract = 1) {
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - subtract);
  return previousMonthDate;
}

export const allPlanPermissions = [{ key: "LIVE_CHAT", value: "Live Chat" }, { key: "API", value: "Api" }, { key: "CAMPAIGNS", value: "Campaigns" }, { key: "FINANCE_ACCESS", value: "Finance Access" }, { key: "CHATBOT", value: "chatbot" }];
export const allIntegrationList = [{ name: "WhatsApp Business", type: "WHATSAPP", title: "Reach out to customers on WhatsApp for higher CTRs and engagement. Schedule campaigns and send push notifications based on customer actions.", iconPath: `${process.env.PUBLIC_URL}/images/WhatsApp-Business.png` },
{ name: "Facebook Feed", type: "FACEBOOK_FEED", title: "Respond to customer comments and mentions faster from iOmnihub inbox and automate common replies to save time.", iconPath: `${process.env.PUBLIC_URL}/images/facebook_feed.svg` },
{ name: "Instagram Feed", type: "INSTAGRAM_FEED", title: "Engage your customers on Instagram by quickly responding to all your comments and mentions from one inbox.", iconPath: `${process.env.PUBLIC_URL}/images/instagram_feed.svg` },
{ name: "Instagram Chat", type: "INSTAGRAM_CHAT", title: "Quickly reply to customer messages on Instagram, share product images, and automate repetitive queries.", iconPath: `${process.env.PUBLIC_URL}/images/instagram_messenger.svg` },
{ name: "Messenger", type: "MESSENGER", title: "Quickly reply to customer messages on Messenger, share product images, and automate repetitive queries.", iconPath: `${process.env.PUBLIC_URL}/images/facebook_messenger.svg` }
];

export const whatsAppTemplateTypes = [{
  key: "GENERIC", value: "Generic",
  categories: [{ key: "MARKETING", value: "MARKETING" }, { key: "AUTHENTICATION", value: "AUTHENTICATION" }, { key: "UTILITY", value: "UTILITY" }]
}, {
  key: "CAROUSEL", value: "Carousel",
  categories: [{ key: "MARKETING", value: "MARKETING" }, { key: "UTILITY", value: "UTILITY" }]
}];

export const whatsAppLanguages = [{ "key": "ar_AR", value: "Arabic" }, { "key": "en_US", value: "English" }, { "key": "fa_IR", value: "Farsi" }, { "key": "fr_FR", value: "French" },
{ "key": "de_DE", value: "German" }, { "key": "he_IL", value: "Hebrew" }, { "key": "hi_IN", value: "Hindi" },
{ "key": "id_ID", value: "Indonesian" }, { "key": "it_IT", value: "Italian" }, { "key": "ms_MY", value: "Malay" },
{ "key": "pt_BR", value: "Portuguese" }, { "key": "ru_RU", value: "Russian" }, { "key": "es_ES", value: "Spanish" }, { "key": "sw_KE", value: "Swahili" },
{ "key": "tr_TR", value: "Turkish" }, { "key": "ur_PK", value: "Urdu" }];