import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { upsertCountry } from "../../store/actions/adminAction";

function AddCountry({ setIsShow, onAdd, isEdit = false, defaultCountry = {} }) {
  let dispatch = useDispatch();
  const [Country, setCountry] = useState(defaultCountry ?? {});

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(upsertCountry(Country, (a) => {
      setIsShow(false);
      onAdd(a);
    }))
  }

  const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
  return (
    <form className="mt-3 max-h-[550px] overflow-auto px-3" onSubmit={handleSubmit}>
      <div className="grid grid-cols-12 gap-3 w-full">
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal mb-2">
            <label> Code</label>
          </div>
          <input type="text" placeholder="Enter Code"
            value={Country?.code} data-maxlength={4} onInput={OnInput}
            onChange={(e) => setCountry({ ...Country, code: e.target.value })}
            className="border border-gray-300 rounded-md py-2 w-[100%]" required />
        </div>
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal mb-2">
            <label> Name</label>
          </div>
          <input type="text" placeholder="Enter Name"
            value={Country?.name}
            onChange={(e) => setCountry({ ...Country, name: e.target.value })}
            className="border border-gray-300 rounded-md py-2 w-[100%]" required />
        </div>
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal mb-2">
            <label> Full Name</label>
          </div>
          <input type="text" placeholder="Enter Full Name" 
            value={Country?.niceName}
            onChange={(e) => setCountry({ ...Country, niceName: e.target.value })}
            className="border border-gray-300 rounded-md py-2 w-[100%]" required />
        </div>
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal mb-2">
            <label>Phone Code</label>
          </div>
          <input type="number"
            placeholder="Enter Phone Code"
            value={Country.phoneCode}
            data-maxlength={5}
            onInput={OnInput}
            onChange={(e) => setCountry({ ...Country, phoneCode: e.target.value })}
            className="border border-gray-300 rounded-md py-2 w-[100%]"
            required
          />
        </div>
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal mb-2">
            <label> Currency</label>
          </div>
          <input type="text" placeholder="Enter Currency" 
            value={Country?.currency}
            onChange={(e) => setCountry({ ...Country, currency: e.target.value })}
            className="border border-gray-300 rounded-md py-2 w-[100%]" required />
        </div>
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal mb-2">
            <label> Currency Code</label>
          </div>
          <input type="text" placeholder="Enter Currency Code"
            value={Country?.currencyCode} data-maxlength={4} onInput={OnInput}
            onChange={(e) => setCountry({ ...Country, currencyCode: e.target.value })}
            className="border border-gray-300 rounded-md py-2 w-[100%]" required />
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-4">
        <button type='button'
          className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
          onClick={() => setIsShow(false)}>
          Cancel
        </button>
        <button type='submit'
          className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
          Save
        </button>
      </div>
    </form>
  )
}

export default AddCountry