import { HttpUtil } from "../../utils/http-utils";
import {
    SHOW_SNACKBAR, IS_LOADING, getToken, COUNTRY
} from "../../utils/types";
const getApiLoaderDispatch = (res, dispatch) => {
    dispatch({ type: IS_LOADING, payload: { type: IS_LOADING, payload: res } });
};

function postAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makePOST(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function getAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makeGET(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

export const getCountry = (request, action) => (dispatch) => getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/country`, true, {}, { Authorization: `bearer ${getToken()}` }, (a) => {
    dispatch({ type: COUNTRY, payload: a });
    action(a);
});

export const uploadFile = (request, key, action) => (dispatch) => postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/storage?kay=${key}`, false, request, { Authorization: `bearer ${getToken()}`, "content-type": "multipart/form-data" }, action);

export const getFile = (request, action) => (dispatch) => getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/storage?kay=${request.key}`, false, {}, { Authorization: `bearer ${getToken()}` }, action);

export const getSessionId = (request, action) => (dispatch) => getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/storage/session-id`, false, request, { Authorization: `bearer ${getToken()}` }, action);

export const fbUploader = (request, tenantId, wabaId, sessionId, action) => (dispatch) => postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/storage/fb-uploader?tenantId=${tenantId}&wabaId=${wabaId}&sessionId=${sessionId}`, false, request, { Authorization: `bearer ${getToken()}`, "content-type": "multipart/form-data" }, action);
