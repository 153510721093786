import { FiUsers, FiPackage, FiBriefcase, FiMessageSquare, FiCreditCard, FiBarChart, FiGlobe, FiBookmark } from "react-icons/fi";
import React from "react";

export const PartnerSidebar = {
  SidebarTitle: [
    {
      value: "Owner Module",
      active: true,
      role: "GLOBAL_ADMIN",
      tabName: [
        {
          title: "Manage Partner",
          link: "/admin/manage-partner",
          icon: <i className="fa fa-handshake-o mr-2"></i>,
          active: true,
        },
        {
          title: "Manage Master Data",
          link: "",
          icon: <FiPackage className="mr-2" />,
          active: false,
          subtab: [
            {
              title: "Country",
              link: "/admin/country",
              icon: <FiGlobe className="mr-2" />,
              active: false,
            },
            {
              title: "Message Type",
              link: "/admin/message-type",
              icon: <FiMessageSquare className="mr-2" />,
              active: false,
            },
            {
              title: "BSP",
              link: "/admin/bsp",
              icon: <i className="fa-solid fa-briefcase"></i>,
              active: false,
            },
            {
              title: "Conversation Rate Card",
              link: "/partner/conversation-rate-card",
              icon: <FiCreditCard className="mr-2" />,
              active: false,
            }
          ]
        },
        {
          title: "Manage Users",
          link: "/admin/manage-users",
          icon: <FiUsers className="mr-2" />,
          active: false,
        }
      ]
    },
    {
      value: "",
      active: true,
      role: "SUPER_ADMIN",
      tabName: [
        {
          title: "Dashboard",
          link: "/partner/dashboard",
          icon: <FiBarChart className="mr-2" />,
          active: true,
        },
        {
          title: "Company Management",
          link: "/partner/company-management",
          icon: <i className="fa-regular mr-2 fa fa-handshake-o"></i>,
          active: false,
        },
        {
          title: "Configurations",
          link: "",
          icon: <FiPackage className="mr-2" />,
          active: false,
          subtab: [
            {
              title: "Plans",
              link: "/partner/plans",
              icon: <FiBriefcase className="mr-2" />,
              active: false,
            },
            {
              title: "Conversation Rate Card",
              link: "/partner/conversation-rate-card",
              icon: <FiCreditCard className="mr-2" />,
              active: false,
            }
          ]
        },
        {
          title: "Manage Users",
          link: "/admin/manage-users",
          icon: <FiUsers className="mr-2" />,
          active: false,
        }
      ]
    }
  ]
};
