import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createRateCard, getMessageType, rateCardDetailsQuery, updateRateCard } from '../../store/actions/partnerAction';
import moment from 'moment';
import SelectCountry from '../../components/Dropdowns/SelectCountry';
import PopUp from '../../components/PopUp';
import ImportExcel from '../../components/ImportExcel';
import * as XLSX from "xlsx";

function AddRateCard({ setIsShow, onAdd, isEdit = false, defaultRate, isView = false, duplicate = false }) {
    const dispatch = useDispatch();
    const [isUpload, setIsUpload] = useState(false);
    const [rateCard, setRateCard] = useState({});
    const [rateCardDetails, setRateCardDetails] = useState([]);
    const [messageTypes, setMessageTypes] = useState([]);
    const partnerReducer = useSelector((state) => state.partnerReducer);
    const globalReducer = useSelector((state) => state.globalReducer);
    const [columnValidation, setColumnValidation] = useState([{ name: "countryCode", displayName: "CountryCode", type: "text", isMandatory: true }, { name: "currency", displayName: "Currency", type: "text", isMandatory: true }]);

    useEffect(() => {
        dispatch(getMessageType((a) => {
            setColumnValidation(columnValidation.concat([...a.map(m => {
                return { name: m.messageType, displayName: m.messageType, type: "number", isMandatory: false }
            })]));
            setMessageTypes(a)
        }));
        if (isEdit) {
            dispatch(rateCardDetailsQuery({ criteria: { id: defaultRate?.id } }, d => {
                setRateCard({ ...d.data[0], countryName: d.data[0]?.country?.name, currencyCode: d.data[0]?.country?.name });
                var isNative = false;
                var rCDetails = globalReducer.country.map(m => {
                    var dt = d.data[0]?.rateCardDetails?.find(f => f.countryCode === m.code);
                    if (dt == null) {
                        return { ...m, countryCode: m.code, countryName: m.name, currency: "USD" }
                    } else {
                        var cny = globalReducer.country.find(f1 => f1.code === dt.countryCode);
                        isNative = dt.currency === "NATIVE";
                        return { ...dt, countryName: cny.name, id: cny.id };
                    }
                });
                if (!isNative) {
                    rCDetails = rCDetails.map(m1 => {
                        if (m1.countryCode === partnerReducer.partnerDetails?.countryCode) {
                            return { ...m1, currency: "NATIVE" };
                        } else return m1;
                    })
                }
                setRateCardDetails(rCDetails);
            }))
        } else {
            setRateCardDetails(globalReducer.country?.map(m => {
                if (m.code === partnerReducer.partnerDetails?.countryCode) {
                    return { ...m, countryCode: m.code, countryName: m.name, currency: "NATIVE" };
                } else return { ...m, countryCode: m.code, countryName: m.name, currency: "USD" };
            }));
            setRateCard({ ...rateCard, countryCode: partnerReducer.partnerDetails?.countryCode, countryName: partnerReducer.partnerDetails?.country?.name, currencyCode: partnerReducer.partnerDetails?.country?.currencyCode });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        rateCard.rateCardDetails = rateCardDetails?.filter(f => Object.keys(f.rates ?? {}).length > 0);
        if (isEdit && !duplicate) {
            dispatch(updateRateCard(rateCard, a => {
                setIsShow(false);
                onAdd(a);
            }));
        }
        else {
            if (duplicate) {
                rateCard.id = null;
            }
            dispatch(createRateCard(rateCard, a => {
                setIsShow(false);
                onAdd(a);
            }));
        }
    }
    const updateRate = (id, key, value) => {
        setRateCardDetails([...rateCardDetails.map((c, i) => {
            if (c.id === id) {
                return { ...c, rates: { ...c.rates, [key]: value } };
            } else {
                return c;
            }
        })]);
    }

    const exportToExcel = () => {
        const list = [];
        rateCardDetails?.filter(f => Object.keys(f.rates ?? {}).length > 0).map(m => {
            if (m.currency === "NATIVE") {
                list.unshift({ countryCode: m.countryCode, currency: m.currency, ...m.rates });
            } else {
                list.push({ countryCode: m.countryCode, currency: m.currency, ...m.rates });
            }
        });
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(list);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'conversation_rate_card');
        XLSX.writeFile(workbook, 'conversation_rate_card.xlsx');
    };
    const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
    return (<>
        <div className="h-full relative">
            <div className="py-2.5 border-b-[1px] sticky top-0 w-full text-[16px] flex items-center justify-between  border-[#F0F1F3]">
                <span className="text-[14px] font-medium text-black">
                    {isEdit ? isView ? "Rate Card" : "Edit Rate Card" : "Add Rate Card"}
                </span>
                <div className="flex items-center gap-4">
                    {!isView && <a onClick={() => setIsUpload(true)} className="text-center whitespace-nowrap px-[2px] py-[4px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline">
                        Upload
                    </a>}
                    <a onClick={() => exportToExcel()} className="text-center whitespace-nowrap px-[2px] py-[4px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline">
                        Download
                    </a>
                    <a download={"conversation_rate_card_template.xlsx"} href={"/files/conversation_rate_card_template.xlsx"}
                        className="text-center whitespace-nowrap pl-[16px] py-[4px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline">
                        Download Template
                    </a>
                    <button onClick={() => { setIsShow(false); }} type="button"
                        className="text-gray-400 z-50 bg-transparent hover:bg-gray-200 hover:text-black rounded text-sm w-8 h-8 inline-flex items-center justify-center">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" >
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span className="sr-only">Close menu</span>
                    </button>
                </div>
            </div>
            <form disabled={isView} className="mt-1 max-h-[550px] pr-3 disabled:opacity-75" onSubmit={handleSubmit}>
                <div className="grid grid-cols-12 gap-3 pl-1">
                    <div className="flex flex-col col-span-3">
                        <div className="text-midBlack text-[13px] font-normal">
                            <label>Name</label>
                        </div>
                        <input type="text" placeholder="Enter Name" value={rateCard?.name}
                            onChange={(e) => { setRateCard({ ...rateCard, name: e.target.value }); }} className="border border-gray-300 rounded-md py-2 w-[100%]" required />
                    </div>
                    <div className="flex flex-col col-span-3">
                        <div className="text-midBlack text-[13px] font-normal">
                            <label>Country Name</label>
                        </div>
                        <SelectCountry selectedCode={rateCard?.countryCode} countryList={globalReducer.country} onSelectCountry={(country) => {
                            if (country) {
                                rateCard.countryCode = country.code;
                                rateCard.countryName = country.name;
                                rateCard.currencyCode = country.currencyCode;
                                setRateCardDetails(rateCardDetails.map(f => {
                                    if (f.countryCode == country.code) {
                                        return { ...f, currency: "NATIVE" };
                                    }
                                    else return { ...f, currency: "USD" };
                                }));
                            } else {
                                delete rateCard.countryCode;
                                delete rateCard.countryName;
                                delete rateCard.currencyCode;
                                setRateCardDetails(rateCardDetails.map(f => {
                                    if (f.countryCode == partnerReducer.partnerDetails?.countryCode) {
                                        return { ...f, currency: "NATIVE" };
                                    }
                                    else return { ...f, currency: "USD" };
                                }));
                            }
                            setRateCard({ ...rateCard })
                        }} />
                    </div>
                    <div className="flex flex-col col-span-3">
                        <div className="text-midBlack text-[13px] font-normal">
                            <label>Applicable Date</label>
                        </div>
                        <input type="date" value={moment(rateCard?.applicableDate).format("YYYY-MM-DD")}
                            onChange={(e) => { setRateCard({ ...rateCard, applicableDate: new Date(e.target.value) }); }} className="border border-gray-300 rounded-md py-2 w-[100%]" required />
                    </div>
                    <div className="flex flex-col col-span-3">
                        <div className="text-midBlack text-[13px] font-normal">
                            <label>Other Details</label>
                        </div>
                        <input type="text" placeholder="Enter Other Details" value={rateCard?.remark}
                            onChange={(e) => { setRateCard({ ...rateCard, remark: e.target.value }); }} className="border border-gray-300 rounded-md py-2 w-[100%]" />
                    </div>
                </div>
                <div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mt-2 mb-1">
                    Domestic Rate
                </div>
                <div className='mt-2 max-h-[400px] overflow-y-scroll'>
                    <table className="text-sm text-left rtl:text-right text-midBlack">
                        <thead className="text-[14px] text-midBlack">
                            <tr className='h-9 bg-gray-100'>
                                <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                                    <div>Countries</div>
                                </th>
                                {messageTypes.map((m, i) => <th key={i} scope="col" className="pb-1 pr-1 font-[500]">
                                    <div>{m.messageType}</div>
                                </th>)}
                            </tr>
                            {rateCardDetails.filter(f => f.currency === "NATIVE").map((f, r) =>
                                <tr key={r}>
                                    <td className="py-2 font-normal text-[13px] pr-2">
                                        {rateCard?.countryName ?? "Domestic"} ({rateCard?.currencyCode})
                                    </td>
                                    {messageTypes.map((m, i) => <td key={i} className="py-2 font-normal text-[13px] pr-1">
                                        <input data-maxlength={8} onInput={OnInput} type="number" className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                                            value={f?.rates?.[m.messageType]} onChange={(e) => { updateRate(f.id, m.messageType, e.target.value) }} />
                                    </td>)}
                                </tr>)}
                            <tr>
                                <td colSpan={messageTypes.length + 1}>
                                    <div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mt-1 mb-1 flex justify-between">
                                        <label className='mt-1'>International (USD)</label>
                                        <input type="text" placeholder="Search Countries" className="border border-gray-300 rounded-md h-9 py-1"
                                            onChange={(e) => {
                                                const cn = e.target.value;
                                                setRateCardDetails(rateCardDetails.map(f => {
                                                    if (f.countryCode?.includes(cn) || f.countryName?.includes(cn)) {
                                                        return { ...f, isHide: false };
                                                    }
                                                    else return { ...f, isHide: true };
                                                }));
                                            }} />
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {rateCardDetails?.map((d, c) =>
                                <tr className={"border " + (d.isHide || d.currency === "NATIVE" ? "hidden" : "")} key={c}>
                                    <td className="py-1 font-normal text-[13px] pl-2 pr-2">
                                        {d?.countryName}
                                    </td>
                                    {messageTypes.map((m, i) => <td key={i} className="py-1 font-normal text-[13px] pr-1">
                                        <input data-maxlength={8} onInput={OnInput} type="number" className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                                            value={d?.rates?.[m.messageType]} onChange={(e) => updateRate(d.id, m.messageType, e.target.value)} />
                                    </td>)}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-end gap-2 mt-4">
                    <button type='button'
                        className="px-[16px] py-[6px] text-[#644d81] font-medium text-[13px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                        onClick={() => setIsShow(false)}>
                        Cancel
                    </button>
                    {!isView && <button type='submit' className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[13px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]">
                        Save
                    </button>}
                </div>
            </form>
        </div>
        <PopUp ModalHeader={
            <span className="font-medium text-[18px] text-black">
                Upload Conversation Rate Card
            </span>}
            isShowModal={isUpload}
            setIsShowModal={setIsUpload}
            ModalBody={
                <ImportExcel Title="Upload Conversation Rate Card" setIsShow={setIsUpload} columnValidation={columnValidation}
                    onImportClick={(con) => {
                        con.forEach(f => {
                            const fi = rateCardDetails.find(s => s.countryCode === f.countryCode)
                            if (fi != null) {
                                if (!fi.rates) {
                                    fi.rates = {};
                                }
                                messageTypes.map(m => {
                                    fi.rates[m.messageType] = f[m.messageType];
                                });
                            }
                        })
                        setRateCardDetails(rateCardDetails);
                        setIsUpload(false);
                    }} />
            } size="4xl"
        />
    </>)
}

export default AddRateCard