import { FiUsers, FiBarChart, FiLink, FiSunset, FiHome, FiBarChart2, FiPieChart, FiInbox, FiShoppingBag, FiMessageCircle, FiMessageSquare, FiNavigation, FiAperture, FiGrid, FiSettings } from "react-icons/fi";
import React from "react";

export const TenantSidebar = {
  SidebarTitle: [
    {
      value: "",
      active: true,
      role: "TENANT_ADMIN",
      tabName: [
        {
          title: "Dashboard",
          link: "/tenant/dashboard",
          icon: <FiHome className="mr-2" />,
          active: true,
        },
        {
          title: "Templates",
          link: "/tenant/templates",
          icon: <FiBarChart className="mr-2" />,
          active: false,
        },
        {
          title: "Live Chat",
          link: "/tenant/live-chat",
          icon: <FiInbox className="mr-2" />,
          active: false,
        },
        {
          title: "Broadcast",
          link: "/tenant/campaigns",
          icon: <svg width={20} height={21} viewBox="0 0 20 21" fill="none" className="mr-1" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 9.96647L17.5 5.7998V15.7998L2.5 12.4665V9.96647Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.66677 14.7996C9.57922 15.117 9.43002 15.414 9.22769 15.6737C9.02535 15.9334 8.77385 16.1507 8.48754 16.3132C8.20122 16.4757 7.8857 16.5802 7.559 16.6208C7.23229 16.6614 6.90079 16.6372 6.58343 16.5496C6.26607 16.4621 5.96906 16.3129 5.70936 16.1106C5.44966 15.9082 5.23236 15.6567 5.06985 15.3704C4.90735 15.0841 4.80283 14.7686 4.76227 14.4419C4.7217 14.1152 4.74588 13.7837 4.83343 13.4663"
              stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
          </svg>,
          active: false,
        },
        {
          title: "Chatbot",
          link: "/tenant/chatbot",
          icon: <i className="fa-brands fa-rocketchat"></i>,
          active: false,
        },
        {
          title: "API Docs",
          link: "/tenant/api-doc",
          icon: <i className="fa-regular fa-file-code"></i>,
          active: false,
        },
        {
          title: "Integration",
          link: "/tenant/integrated",
          icon: <FiGrid className="mr-2" />,
          active: false
        },
        {
          title: "Contacts",
          link: "",
          icon: <i className="fa-regular fa-address-card"></i>,
          active: false,
          subtab: [
            {
              title: "Manage Categories",
              link: "/tenant/manage-categories",
              icon: <i className="fa-solid fa-code-branch"></i>,
              active: false,
            },
            {
              title: "Manage Contacts",
              link: "/tenant/contacts",
              icon: <i className="fa-regular fa-address-card"></i>,
              active: false,
            }
          ]
        },
        {
          title: "Manage Users",
          link: "/tenant/manage-users",
          icon: <FiUsers className="mr-2" />,
          active: false,
        },
        {
          title: "Reports",
          link: "",
          icon: <i className="fa-solid fa-file-fragment"></i>,
          active: false,
          subtab: [
            {
              title: "All Conversations",
              link: "/tenant/allConversations",
              icon: <i className="fa-regular fa-comments"></i>,
              active: false,
            },
            {
              title: "All Messages",
              link: "/tenant/allMessages",
              icon: <i className="fa-regular fa-comment-dots"></i>,
              active: false,
            }
          ]
        },
        {
          title: "Settings",
          link: "/tenant/settings",
          icon: <FiSettings className="mr-2" />,
          active: false,
        }
      ]
    }
  ]
};
