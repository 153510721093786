import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { assignToTenantRateCard, getMessageType, rateCardQuery } from '../../store/actions/partnerAction';
import { getTenantRateCardDetails } from '../../store/actions/tenantAction';

function MapTenantRateCard({ setIsShow, onAdd, tenant = {}, waba = {}, isEdit = false }) {
    let dispatch = useDispatch();
    const [rateCards, setRateCards] = useState([]);
    const [selectedRateCard, setSelectedRateCard] = useState({});
    const [selectedRateCardId, setSelectedRateCardId] = useState();
    const [tenantRateCards, setTenantRateCards] = useState([]);
    const [messageTypes, setMessageTypes] = useState([]);
    const globalReducer = useSelector((state) => state.globalReducer);

    useEffect(() => {
        dispatch(getMessageType(setMessageTypes));
        dispatch(rateCardQuery({ criteria: { active: true } }, a => {
            setRateCards(a.data)
        }));
        refresh();
    }, []);

    const refresh = () => {
        dispatch(getTenantRateCardDetails({ tenantId: tenant.id, tenantWabaId: waba.id }, a => {
            if (a && a.length > 0) {
                setSelectedRateCardId(a[0].rateCardId);
                setTenantRateCards(a?.map(m => {
                    const country = globalReducer.country.find(fi => fi.code === m.countryCode);
                    return { ...m, countryName: country?.name, currencyCode: country.currencyCode }
                }
                ));
            }
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(assignToTenantRateCard({ tenantId: tenant.id, tenantWabaId: waba.id, rateCard: selectedRateCard }, (a) => {
            refresh();
            onAdd(a);
        }))
    }
    const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
    return (
        <div className="mt-3 max-h-[550px] overflow-auto px-3">
            <div className="grid grid-cols-12 gap-3 w-full">
                <div className="col-span-10">
                    <div className="text-midBlack text-[13px] font-normal mb-2">
                        <label>Rete Cards</label>
                    </div>
                    <select name='roles' value={selectedRateCardId} className="border w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
                        onChange={(e) => {
                            setSelectedRateCard(rateCards.find(f => f.id == e.target.value));
                            setSelectedRateCardId(e.target.value);
                        }}>
                        <option value="" selected disabled>
                            Select Rete Card...
                        </option>
                        {rateCards?.map((p, i) => <option key={i} value={p.id}>{p.name}</option>)}
                    </select>
                </div>
                <div className="col-span-2">
                    <button type='button' className="mt-7 px-[16px] py-[10px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#6803EB]"
                        onClick={handleSubmit}>
                        Assign
                    </button>
                </div>
            </div>
            {tenantRateCards?.length > 0 && <><div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mt-2 mb-1">
                Domestic Rate
            </div>
                <div className='mt-2 max-h-[400px] overflow-y-scroll'>
                    <table className="text-sm text-left rtl:text-right text-midBlack">
                        <thead className="text-[14px] text-midBlack">
                            <tr className='h-9 bg-gray-100'>
                                <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                                    <div>Countries</div>
                                </th>
                                {messageTypes.map((m, i) => <th key={i} scope="col" className="pb-1 pr-1 font-[500]">
                                    <div>{m.messageType}</div>
                                </th>)}
                            </tr>
                            {tenantRateCards?.filter(f => f.currency === "NATIVE").map((f, r) =>
                                <tr key={r}>
                                    <td className="py-2 font-normal text-[13px] pr-2">
                                        {f?.countryName ?? "Domestic"} ({f?.currencyCode})
                                    </td>
                                    {messageTypes.map((m, i) => <td key={i} className="py-2 font-normal text-[13px] pr-1">
                                        <input data-maxlength={8} onInput={OnInput} type="number" className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                                            value={f?.rates?.[m.messageType]} disabled />
                                    </td>)}
                                </tr>)}
                            <tr>
                                <td colSpan={messageTypes.length + 1}>
                                    <div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mt-1 mb-1 flex justify-between">
                                        <label className='mt-1'>International (USD)</label>
                                        <input type="text" placeholder="Search Countries" className="border border-gray-300 rounded-md h-9 py-1"
                                            onChange={(e) => {
                                                const cn = e.target.value;
                                                setTenantRateCards(tenantRateCards.map(f => {
                                                    if (f.countryCode?.includes(cn) || f.countryName?.includes(cn)) {
                                                        return { ...f, isHide: false };
                                                    }
                                                    else return { ...f, isHide: true };
                                                }));
                                            }} />
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {tenantRateCards?.map((d, c) =>
                                <tr className={"border " + (d.isHide || d.currency === "NATIVE" ? "hidden" : "")} key={c}>
                                    <td className="py-1 font-normal text-[13px] pl-2 pr-2">
                                        {d?.countryName}
                                    </td>
                                    {messageTypes.map((m, i) => <td key={i} className="py-1 font-normal text-[13px] pr-1">
                                        <input data-maxlength={8} onInput={OnInput} type="number" className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                                            value={d?.rates?.[m.messageType]} disabled />
                                    </td>)}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div></>}
            <div className="flex justify-end gap-2 mt-4">
                <button type='button' className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                    onClick={() => setIsShow(false)}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default MapTenantRateCard