import { HttpUtil } from "../../utils/http-utils";
import {
    SHOW_SNACKBAR,
    IS_LOADING,
    getToken,
    getRefreshToken, PARTNER_DETAILS
} from "../../utils/types";

const getApiLoaderDispatch = (res, dispatch) => {
    dispatch({ type: IS_LOADING, payload: { type: IS_LOADING, payload: res } });
};

function postAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makePOST(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function postParamsAction(dispatch, url, isApiLoader, request, Params, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makePOSTwithParams(url, request, Params, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function getAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makeGET(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

export const searchCountry = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/country/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const upsertCountry = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/country/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeCountryStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/country/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const searchMessageType = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/master-data/message-type/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const upsertMessageType = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/master-data/message-type/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeMessageTypeStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/master-data/message-type/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const bspQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/master-data/bsp/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const upsertBsp = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/master-data/bsp/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeBspStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/master-data/bsp/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getPartners = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const addPartner = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/onboard`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const updatePartner = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/update`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getPartnerDetails = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/details`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getUsers = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner-user/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const userUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner-user/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeUserStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner-user/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

