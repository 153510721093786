import axios from "axios";
import {jwtDecode} from "jwt-decode";
import { getRefreshToken, getToken } from "./types";

const isTokenExpired = (token) => {
  if (!token) return false;
  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000;
};

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to add access token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getToken();
    if (accessToken) {
      config.headers['Authorization'] = `bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = getRefreshToken();
    const accessToken = getToken();

    if (error.response.status === 401 && refreshToken && isTokenExpired(accessToken)) {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_PRODUCT_APIURL}/oauth2/token/refresh`, {
          params: { client_id: "zealthix" },
          headers: {
            Authorization: `bearer ${refreshToken}`,
            "x-tenant-id": localStorage.getItem("xid")
          }
        });

        // Update tokens in local storage
        localStorage.setItem('sessionToken', data.access_token);
        localStorage.setItem('refreshToken', data.refresh_token);

        // Update the original request with the new token
        originalRequest.headers['Authorization'] = `bearer ${data.access_token}`;

        // Retry the original request
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Handle the case where refreshing the token fails
        console.error("Refresh token error", refreshError);
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('refreshToken');
        window.location.href = `/`;
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

const makePOST = async (url, data, headers, parsedError = true) => {
  try {
    let result = await axiosInstance.post(url, data, { headers });
    return defaultSuccessHandler(result);
  } catch (e) {
    return parsedError ? parseNetworkError(e) : e;
  }
};

const makePOSTwithParams = async (url, data, params, headers, parsedError = true) => {
  try {
    let result = await axiosInstance.post(url, data, { params, headers });
    return defaultSuccessHandler(result);
  } catch (e) {
    return parsedError ? parseNetworkError(e) : e;
  }
};

const makeGET = async (url, params, headers, parsedError = true) => {
  try {
    let result = await axiosInstance.get(url, { params, headers });
    return defaultSuccessHandler(result);
  } catch (e) {
    return parsedError ? parseNetworkError(e) : e;
  }
};

const makeDELETEWITHBODY = async (url, data, headers, parsedError = true) => {
  try {
    let result = await axiosInstance.delete(url, {
      headers: headers,
      data: data
    });
    return defaultSuccessHandler(result);
  } catch (e) {
    return parsedError ? parseNetworkError(e) : e;
  }
};

const makeDELETE = async (url, params, headers, parsedError = true) => {
  try {
    let result = await axiosInstance.delete(url, { params, headers });
    return defaultSuccessHandler(result);
  } catch (e) {
    return parsedError ? parseNetworkError(e) : e;
  }
};

const makePUT = async (url, data, headers, parsedError = true) => {
  try {
    let result = await axiosInstance.put(url, data, { headers });
    return defaultSuccessHandler(result);
  } catch (e) {
    return parsedError ? parseNetworkError(e) : e;
  }
};

const makePUTWithoutAuth = async (url, data, headers, parsedError = true) => {
  try {
    let result = await axios.put(url, data, { headers });
    return defaultSuccessHandler(result);
  } catch (e) {
    return parsedError ? parseNetworkError(e) : e;
  }
};

const makePATCH = async (url, data, headers, parsedError = true) => {
  try {
    let result = await axiosInstance.patch(url, data, { headers });
    return defaultSuccessHandler(result);
  } catch (e) {
    return parsedError ? parseNetworkError(e) : e;
  }
};

const defaultSuccessHandler = function (response) {
  return {
    success: true,
    status: response.status,
    data: response.data,
    headers: response.headers,
  };
};

const parseNetworkError = (error) => {
  let errorResponse = {};
  if (error.response) {
    errorResponse = {
      error: true,
      result: "error",
      status: error.response.status,
      data: error.response.data,
      headers: error.response.headers,
    };
  } else if (axios.isCancel(error)) {
    errorResponse = {
      error: false,
      result: "canceled",
      status: null,
      data: null,
      message: error.message,
      isCancel: true,
    };
  } else {
    errorResponse = {
      error: true,
      result: "error",
      status: null,
      data: null,
      message: error.message,
    };
  }

  return errorResponse;
};

const typeOf = function (prop, type) {
  const dataset = {
    object: "[object Object]",
    array: "[object Array]",
    string: "[object String]",
    boolean: "[object Boolean]",
    number: "[object Number]",
    date: "[object Date]",
    undefined: "[object Undefined]",
    null: "[object Null]",
    promise: "[object Promise]",
    asyncFunction: "[object AsyncFunction]",
    function: "[object Function]",
  };

  return Object.prototype.toString.call(prop) === dataset[type];
};

export const HttpUtil = {
  makeGET,
  makeDELETE,
  makePOST,
  makePUT,
  makePATCH,
  typeOf,
  makePOSTwithParams,
  makeDELETEWITHBODY,
  makePUTWithoutAuth
};
