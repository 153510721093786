import { PARTNER_DETAILS } from "../../utils/types";

const initialState = {
  partnerDetails: {}
};

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARTNER_DETAILS: {
      return {
        ...state,
        partnerDetails: action.payload,
      };
    }
    default:
      return state;
  }
};
export default partnerReducer;