import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { fbUploader, getSessionId, uploadFile } from '../store/actions/globalAction';
import { v4 as uuidv4 } from 'uuid';

function FileUploader({ type, onUploaded, filePrefix, selectedFileUrl, isFbUploder = false }) {
    let dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);
    const [fileName, setFileName] = useState("");
    const [error, setError] = useState(null);
    const IMAGE_MAX_FILE_SIZE = 5;
    const VIDEO_MAX_FILE_SIZE = 16;
    const DOCUMENT_MAX_FILE_SIZE = 100;

    const handleUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            let fileSize = 0;
            switch (type?.toUpperCase()) {
                case "IMAGE":
                    fileSize = IMAGE_MAX_FILE_SIZE;
                    break;
                case "VIDEO":
                    fileSize = VIDEO_MAX_FILE_SIZE;
                    break;
                case "DOCUMENT":
                    fileSize = DOCUMENT_MAX_FILE_SIZE;
                    break;
            }
            if (files[0].size > (fileSize * 1024 * 1024)) {
                setError(`File size exceeds the limit of ${fileSize} MB.`);
            } else {
                setError(null);
                const formData = new FormData();
                files.forEach((file) => {
                    formData.append('file', file);
                });
                setFileName(files[0].name);
                setUploading(true);
                if (isFbUploder) {
                    dispatch(getSessionId({ name: files[0].name, length: files[0].size, contentType: files[0].type }, a => {
                        if (a.id) {
                            dispatch(fbUploader(formData, a.id, f => {
                                if (f.h) {
                                    setUploading(false);
                                    onUploaded(f.h);
                                } else setUploading(false);
                            }))
                        } else setUploading(false);
                    }));
                } else {
                    const newUuid = uuidv4();
                    const key = `${(filePrefix ? (filePrefix + "/") : "")}${newUuid}.${files[0].name.split('.').pop()}`;
                    dispatch(uploadFile(formData, key, a => {
                        setUploading(false);
                        if (a) {
                            onUploaded(key);
                        }
                    }));
                }
            }
        }
    };

    const getUi = () => {
        switch (type?.toUpperCase()) {
            case "IMAGE":
                return <label tabIndex={-1} htmlFor="UploadImage" className="flex flex-col items-center justify-center p-2 text-[#8d99ae] max-h-[138px]">
                    <input id="UploadImage" onChange={handleUpload} accept="image/png,image/jpeg" type="file" multiple={false} tabIndex={-1} style={{ display: "none" }} />
                    <div className='flex gap-2'>
                        <i className="fa-solid fa-image text-[24px] hover:text-theme"></i>
                        {uploading && <div className="relative w-6 h-6 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />}
                    </div>
                    <p className="w-[80%] text-center text-[14px]">
                        Upload or drag &amp; drop your image here. Maximum size 5 MB allowed &amp;
                        image type must be PNG or JPEG.
                    </p>
                    <p className="text-center text-[14px] text-theme"> {error}</p>
                    <p className="text-center text-[14px] text-[#1E40AF]"> {fileName ?? selectedFileUrl}</p>
                </label>;
            case "VIDEO":
                return <label tabIndex={-1} htmlFor="UploadVideo" className="flex flex-col items-center justify-center p-2 text-[#8d99ae] max-h-[138px]">
                    <input id='UploadVideo' onChange={handleUpload} accept="video/mp4" type="file" multiple={false} tabIndex={-1} style={{ display: "none" }} />
                    <div className='flex gap-2'>
                        <i className="fa-solid fa-video text-[24px] hover:text-theme"></i>
                        {uploading && <div className="relative w-6 h-6 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />}
                    </div>
                    <p className="w-[80%] text-center text-[14px]">
                        Upload or drag &amp; drop your video here. Maximum size 16 MB allowed &amp;
                        video type must be MP4.
                    </p>
                    <p className="text-center text-[14px] text-theme"> {error}</p>
                    <p className="text-center text-[14px] text-[#1E40AF]"> {fileName}</p>
                </label>;
            case "DOCUMENT":
                return <label tabIndex={-1} htmlFor="UploadDocument" className="flex flex-col items-center justify-center p-2 text-[#8d99ae] max-h-[138px]">
                    <input id="UploadDocument" onChange={handleUpload} accept=".pdf, .doc, .docx" type="file" multiple={false} tabIndex={-1} style={{ display: "none" }} />
                    <div className='flex gap-2'>
                        <i className="fa-solid fa-file text-[24px] hover:text-theme"></i>
                        {uploading && <div className="relative w-6 h-6 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />}
                    </div>
                    <p className="w-[80%] text-center text-[14px] text-gray-500">
                        Upload or drag &amp; drop your document here. Maximum size 100 MB allowed &amp; document type must be PDF.
                    </p>
                    <p className="text-center text-[14px] text-theme"> {error}</p>
                    <p className="text-center text-[14px] text-[#1E40AF]"> {fileName}</p>
                </label>;
        }
    }

    return (
        type && <div className="border border-gray-400 rounded-md border-dashed">
            {getUi()}
        </div>
    )
}

export default FileUploader