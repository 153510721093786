import React from 'react'
import moment from 'moment';

function WhatsAppTemplatePreview({ chatPhone = "+968 24 151025", template, carouselTemplate }) {
    const buttonsLength = template?.buttons?.length;

    const getHeader = () => {
        if (template?.header?.format) {
            switch (template?.header?.format) {
                case "TEXT":
                    return <div className="h-auto w-full mx-auto">
                        <div className="font-semibold text-xs px-2">{<div dangerouslySetInnerHTML={{ __html: replaceVariables(template?.header?.text, template?.header?.headerVariables) }} />}</div>
                    </div>;
                case "DOCUMENT":
                    return <div className="h-auto w-full mx-auto p-2 pt-0.5">
                        <div className="w-full flex flex-col items-center rounded-md px-4 py-6">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-9 h-9 text-gray-400">
                                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
                                <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                            </svg>
                            <div className="mt-2 w-full">
                                <p className="text-xs text-center font-medium leading-5 text-gray-600 truncate">
                                    Prescription (9)_80d.pdf
                                </p>
                            </div>
                        </div>
                    </div>;
                case "IMAGE":
                    return <div className="h-auto w-full mx-auto p-2 pt-0.5">
                        <img className="h-full w-full object-cover rounded-md" alt="header_img"
                            src="https://s3-ap-southeast-1.amazonaws.com/myalice-live-public-bucket/misc/29be1cd8902411efb38d7e624114bac5.jpeg" />
                    </div>;
                case "VIDEO":
                    return <div className="h-auto w-full mx-auto p-2 pt-0.5">
                        <div className="relative cursor-pointer  w-full mx-auto">
                            <div className="absolute bg-gray-500 bg-opacity-40 rounded-md h-[120px] w-full flex justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="text-white w-9 h-9">
                                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <video className="object-fill rounded-md h-[120px] w-full">
                                <source src="https://s3-ap-southeast-1.amazonaws.com/myalice-live-public-bucket/misc/7a694932902411ef95de1215540a55b3.mp4" />
                            </video>
                        </div>
                    </div>;
            }
        }
    }

    const replaceVariables = (text, variables) => {
        if (Array.isArray(variables)) {
            let newText = text;
            variables?.map((mp, j) => {
                const srch = `{{${j + 1}}}`;
                if (mp.text) {
                    newText = newText?.replace(srch, (`<span class='text-indigo-500'>${mp.text}</span>`));
                }
                else {
                    newText = newText?.replace(srch, (`<span class='text-indigo-500'>${srch}</span>`));
                }
            })
            return newText;
        } else {
            return text;
        }
    }

    return (
        <div className="flex-grow w-[95%] max-h-[70vh] max-w-xs">
            <div className="relative w-full rounded-lg overflow-y-auto pb-2"
                style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/whatsappBg.png")`, backgroundSize: "cover", height: "100%" }}>
                <img src={`${process.env.PUBLIC_URL}/images/DeviceTopbar.svg`} alt="whatsapp" className="w-full rounded-t-lg border-none" />
                <div className="bg-[#008268] p-2 flex gap-2 items-center justify-between mt-[-5px]">
                    <div className="flex gap-2 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24" fill="currentColor" aria-hidden="true"
                            className="text-white rtl:-rotate-180 h-4 w-4 font-bold" >
                            <path fillRule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" />
                        </svg>
                        <div className="h-6 w-6 p-1 bg-white flex justify-center rounded-full">
                            <div className="w-full h-full">
                                <img src="/favicon.png" className="w-28" />
                            </div>
                        </div>
                        <h1 className="text-white flex text-xs font-medium text-truncate">
                            {chatPhone}
                        </h1>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-4 w-4 text-white font-bold">
                        <path fillRule="evenodd" d="M10.5 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" clipRule="evenodd" />
                    </svg>
                </div>
                <div className="bg-white pt-1 text-xs text-md flex flex-col gap-2 w-[80%] rounded-md ml-2 mr-2 my-2">
                    {getHeader()}
                    {template?.body && <div className="whitespace-pre-line px-2 text-xs break-words" id="wa-preview-template-body">
                        <div dangerouslySetInnerHTML={{ __html: replaceVariables(template?.body, template.bodyVariables) }} />
                    </div>}
                    {template?.footer && <div id="wa-preview-template-footer" className="text-[#788082] text-xs px-2">
                        {template.footer}
                    </div>}
                    <div className="flex justify-end text-[#788082] text-xs px-2">{moment().format("hh:mm A")}</div>
                    <div className="flex flex-col">
                        {buttonsLength > 0 && template?.buttons?.slice(0, 2)?.map((b, i) => {
                            switch (b.type) {
                                case "PHONE_NUMBER":
                                    return <div className="flex justify-center items-center gap-2 border-1 border-t py-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-4 h-4 text-[#0EA5E9]">
                                            <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                                        </svg>
                                        <span className="text-[#0EA5E9] text-xs font-semibold">
                                            {b.text}
                                        </span>
                                    </div>;
                                case "QUICK_REPLY":
                                    return <div className="flex justify-center items-center gap-2 border-1 border-t py-2">
                                        <img src={`${process.env.PUBLIC_URL}/images/WhatsappSequenceButtonIcon.svg`} alt="whatsapp" className="w-4 h-4" />
                                        <span className="text-[#0EA5E9] text-xs font-semibold">{b.text}</span>
                                    </div>;
                                case "URL":
                                    return <div className="flex justify-center items-center gap-2 py-2 border-1 border-t">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-4 h-4 font-bold text-[#0EA5E9]">
                                            <path fillRule="evenodd" d="M15.75 2.25H21a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V4.81L8.03 17.03a.75.75 0 01-1.06-1.06L19.19 3.75h-3.44a.75.75 0 010-1.5zm-10.5 4.5a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V10.5a.75.75 0 011.5 0v8.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V8.25a3 3 0 013-3h8.25a.75.75 0 010 1.5H5.25z" clipRule="evenodd" />
                                        </svg>
                                        <span className="text-[#0EA5E9] text-xs font-semibold">{b.text}</span>
                                    </div>;
                                case "COPY_CODE":
                                    return <div className="flex justify-center items-center gap-2 py-2 border-1 border-t">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-4 h-4 text-[#0EA5E9]">
                                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                                            <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                                        </svg>
                                        <span className="text-[#0EA5E9] text-xs font-semibold">{b.text}</span>
                                    </div>;
                            }
                        })}
                        {buttonsLength > 2 && <div className="flex justify-center items-center gap-2 py-2 border-1 border-t">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-4 h-4 font-bold text-[#0EA5E9]">
                                <path fillRule="evenodd" d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                            </svg>
                            <span className="text-[#0EA5E9] text-xs font-semibold">
                                See all options
                            </span>
                        </div>}
                    </div>
                </div>
                {carouselTemplate?.length > 0 && carouselTemplate?.some(s => s.body?.length > 0) && <div className="relative ltr:pl-4 rtl:pr-4 ltr:pr-2 rtl:pl-2 text-xs text-md flex flex-col gap-2 w-full rounded-md ">
                    <div className="relative w-full" role="region" aria-roledescription="carousel">
                        <div className="overflow-hidden">
                            <div className="flex m-0 gap-2" style={{ transform: "translate3d(0px, 0px, 0px)" }}>
                                {carouselTemplate.map((t, i) =>
                                    <div role="group" key={i} aria-roledescription="slide" className="shrink-0 grow-0 w-[75%] min-h-full ml-2 mr-[-6px]">
                                        <div className="rounded-xl border text-card-foreground shadow bg-white h-full">
                                            <div className="flex flex-col h-full w-full p-0">
                                                <div className="w-full flex flex-col justify-between h-full gap-2">
                                                    <div className="flex flex-col">
                                                        <div className="h-[120px] w-full mx-auto p-2" />
                                                        <div className="text-xs whitespace-pre-line px-2 text-[#1F2C34] font-normal leading-4" />
                                                    </div>
                                                    <div className="flex flex-col" />
                                                </div>
                                                {t?.body && <div className="whitespace-pre-line px-2 pb-1.5 text-xs break-words">
                                                    {t.body}
                                                </div>}
                                                <div className="flex flex-col">
                                                    {t.buttons?.length > 0 && t?.buttons?.slice(0, 2)?.map((b, i) => {
                                                        switch (b.type) {
                                                            case "PHONE_NUMBER":
                                                                return <div className="flex justify-center items-center gap-2 border-1 border-t py-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-4 h-4 text-[#0EA5E9]">
                                                                        <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                                                                    </svg>
                                                                    <span className="text-[#0EA5E9] text-xs font-semibold">
                                                                        {b.text}
                                                                    </span>
                                                                </div>;
                                                            case "QUICK_REPLY":
                                                                return <div className="flex justify-center items-center gap-2 border-1 border-t py-2">
                                                                    <img src={`${process.env.PUBLIC_URL}/images/WhatsappSequenceButtonIcon.svg`} alt="whatsapp" className="w-4 h-4" />
                                                                    <span className="text-[#0EA5E9] text-xs font-semibold">{b.text}</span>
                                                                </div>;
                                                            case "URL":
                                                                return <div className="flex justify-center items-center gap-2 py-2 border-1 border-t">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-4 h-4 font-bold text-[#0EA5E9]">
                                                                        <path fillRule="evenodd" d="M15.75 2.25H21a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V4.81L8.03 17.03a.75.75 0 01-1.06-1.06L19.19 3.75h-3.44a.75.75 0 010-1.5zm-10.5 4.5a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V10.5a.75.75 0 011.5 0v8.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V8.25a3 3 0 013-3h8.25a.75.75 0 010 1.5H5.25z" clipRule="evenodd" />
                                                                    </svg>
                                                                    <span className="text-[#0EA5E9] text-xs font-semibold">{b.text}</span>
                                                                </div>;
                                                            case "COPY_CODE":
                                                                return <div className="flex justify-center items-center gap-2 py-2 border-1 border-t">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-4 h-4 text-[#0EA5E9]">
                                                                        <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                                                                        <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                                                                    </svg>
                                                                    <span className="text-[#0EA5E9] text-xs font-semibold">{b.text}</span>
                                                                </div>;
                                                        }
                                                    })}
                                                    {t.buttons?.length > 2 && <div className="flex justify-center items-center gap-2 py-2 border-1 border-t">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-4 h-4 font-bold text-[#0EA5E9]">
                                                            <path fillRule="evenodd" d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                                        </svg>
                                                        <span className="text-[#0EA5E9] text-xs font-semibold">
                                                            See all options
                                                        </span>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

const fillPlaceholders = (text, variables) => {
    return text.replace(/{{(\d+)}}/g, (match, index) => variables[index - 1] || match);
};

const TemplatePreview = ({ template, variables = [] }) => {
    const { header, body, footer, buttons } = template;

    return (
        <div className="template-preview border p-4 rounded-lg shadow-md">
            {header && (
                <div className="template-header font-bold text-lg mb-2">
                    {fillPlaceholders(header, variables)}
                </div>
            )}
            {body && (
                <div className="template-body text-gray-700 mb-4">
                    {fillPlaceholders(body, variables)}
                </div>
            )}
            {footer && (
                <div className="template-footer text-gray-500 italic mb-4">
                    {fillPlaceholders(footer, variables)}
                </div>
            )}
            {buttons && (
                <div className="template-buttons space-x-2">
                    {buttons.map((button, index) => (
                        <button
                            key={index}
                            className="btn text-white bg-blue-500 px-4 py-2 rounded-lg"
                        >
                            {fillPlaceholders(button.text, variables)}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};
export default WhatsAppTemplatePreview